@import "../../styles/constants.scss";
@import "../../styles/theming.scss";

.panel-wrapper {
	position: sticky;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	//max-height: 88vh;
}

.scrollbars-tickets {
	background: $color18;
}

.ticket-limit {
	display: flex;
	flex-direction: column;

	&__img {
		display: flex;
		justify-content: center;
		margin-top: 6.66rem;
		img {
			width: 32.84rem;
		}
	}

	&__msg {
		text-align: center;
		margin-top: 6.66rem;
		margin-left: 13.3333rem;
		margin-right: 13.3333rem;
		font-size: 3rem;
		color: $color14;
		text-align: center;
	}

	&__btn-container {
		display: flex;
		justify-content: center;
		margin-top: 6.66rem;
	}
}

.ticket-image-modal {
	z-index: 100;
	background: #707070;
	box-shadow: 0 0 3rem rgba(25, 54, 127, 0.5);
	border-radius: 20px;
	width: 40%;
	height: 35%;
	transform: translateY(-10%);

	&::after {
		content: "";
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000, 0.6);
		z-index: -1;
		border-radius: 20px;
	}

	&-show-true {
		display: block;
	}

	&-show-false {
		display: none;
	}

	&__header {
		display: flex;
		height: 28px;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
		background: #232733;
		border-radius: 20px;
		overflow: hidden;

		img {
			width: 10px;
			cursor: pointer;
		}
	}

	&__wrap {
		width: 100%;
		height: calc(100% - 28px);
		border-radius: 0 0 20px 20px;
		overflow: hidden;
	}

	&__title {
		font-size: 2.2rem;
		color: #e0d9cb;
	}

	&__image {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover;
	}
}

.ticket-chat-categoty {
	margin-top: 10px;
	color: #999999;
}

.tickets {
	justify-content: space-between;
	height: calc(100% - 14.16rem);

	.wrapper {
		position: relative;
		padding: 0 !important;
	}

	.content {
		padding: 2rem;
		.content {
			padding: 0;
		}
	}

	&__scroll {
		position: absolute;
		display: flex;
		top: 15rem;
		bottom: 1rem;
		left: 0;
		right: 0;
	}

	.ticket-create__wrap {
		display: flex;
		min-height: 100%;

		.panel {
			.panel-body .panel-body-content {
				position: initial;
				overflow: visible;
			}
		}

		.authorisation-error {
			margin-left: 0;
		}
	}
	.column {
		display: flex;
		justify-content: center;
		max-width: 60rem;
		min-width: 60rem;
		//min-height: 88vh;
		position: relative;
		z-index: 20;

		&_tabs {
			display: flex;
			justify-content: center;
			min-width: calc(100% - 65rem);
			//background: #020D1D;
			.ticket-tabs {
				margin: 1.5rem 2rem 3.3333rem;
			}
		}

		.ticket-bg {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			transform: translate(-50%, -50%);

			img {
				display: block;
				width: 80%;
				margin: 0 auto;
			}
		}
		.create {
			@extend .main-button;
			margin: 4rem auto;
			bottom: 0;
			position: absolute;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			white-space: nowrap;
			z-index: 1;
		}

		.create-tickets {
			position: absolute;
			right: 0;
			left: 0;
			bottom: 0;
			top: 0;
			display: flex;
			flex-direction: column;
			//background-color: $color37;
			//border-radius: 0;
			z-index: 1;
			@include theme-var(
				(
					background-color: bg-color-item-ticket
				)
			);
			.icon-close:hover {
				cursor: pointer;
					color: $color62;
			}
			.color-pending,
			.color-wait {
				color: $pending-color;
			}

			.color-rejected {
				color: $rejected-color;
			}

			.color-processed,
			.color-confirmed {
				color: $processed-color;
			}

			.color-find {
				color: #c8ddf5;
			}

			.tittle {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 5rem;
				z-index: 5;
				margin: 2.6rem 0;

				@include theme-var(
					(
						background-color: bg-color-status-ticket
					)
				);

				.tooltip__wrapper {
					width: 2.666666667rem;
					height: 2.666666667rem;
					i {
						&:hover {
							transform: scale(1.25);
						}
					}
				}

				&__label {
					font-size: 2rem;
					margin-left: 1.83rem;
					color: $tittle-color-text;
				}

				i {
					font-size: 2.666666667rem;
					transition: transform .3s;
					transform-origin: center center;
					display: block;
				}

				&__icon-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 4.8rem;
					height: 4.8rem;

					& i {cursor: pointer;}

					&.selected .tooltip__wrapper i {
						transform: scale(1.25);
					}
				}

				&__filters-wrapper {
					display: flex;
					width: 51%;
					position: relative;
				}

				&__clear-type-filter {
					position: absolute;
					display: flex;
					align-items: center;
					right: 2.8rem;
					top: 50%;
					transform: translateY(-50%);
					color: $filter-input-placeholder-color;
					cursor: pointer;
				}

				&__filters {
					display: flex;
					justify-content: space-between;
					width: 80%;
					padding-right: 7rem;
				}

				&__input-wrapper {
					display: flex;
					justify-content: center;
					width: 97%;
					background: $color38;
					margin: 1rem 0.83rem 0;
					border: 1px solid transparent;
					border-radius: 20rem;
					box-sizing: border-box;

					&:hover {
						border-color: $color1;
					}

					&:focus-within {
						border-color: $color1;
					}

					i {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 2rem;
						color: $color14;
						width: 6%;
					}
					.icon-close:hover {
						color: $color2;
						cursor: pointer;
					}
				}

				&__find-input {
					box-sizing: border-box;
					width: 90%;
					height: 4.16rem;
					border: none;
					outline: none;
					color: $color20;
					padding: 0 0.5rem 0 0.7rem;
					background: $filter-input-background;
					border-radius: 2rem;
					font-size: 2.33rem;
					//font-family: $font-family;

					&::placeholder {
						color: $color14;
					}

				}

				&__date-filter {
					height: 4.16rem;
					display: flex;
					justify-content: center;
					width: 97%;
					background: $color38;
					margin: 1rem 0.83rem 0px;
					border-radius: 20rem;
				}

				&__category-filter {
					display: flex;
					justify-content: center;
					width: 97%;
					margin-left: 0.83rem;

					.select-wrapper {
						width: 100%;
						margin-bottom: 0;
					}

					.set-category-select {
						height: 4.16rem;

						&:focus {
							outline: none;
						}
					}

					.filter-select {
						&__control--menu-is-open &__single-value {
							color: $color-withdraw;
						}
						&--is-disabled {
							.filter-select {
								&__placeholder {
									color: $filter-input-placeholder-color-disabled;
								}
							}
						}

						&__single-value {
							color: $filter-input-color;
							font-size: 2.33rem;
							//font-family: $font-family;

							&--is-disabled {
								color: $filter-input-placeholder-color-disabled;
							}
						}

						&__control {
							width: 100%;
							min-height: 4.16rem;
							background: $filter-input-background;
							border: none;
							outline: none;
							padding: 0 0.5rem 0 0.5rem;
							box-sizing: border-box;
							font-size: 2.33rem;
							//font-family: $font-family;
							font-weight: normal;
							box-shadow: none;
							border-radius: 0;
						}

						&__placeholder {
							color: $filter-input-placeholder-color;
						}

						&__menu {
							position: absolute;
							top: -1.33rem;
							z-index: 5;
							background: $bg-color-modal;
							color: $filter-input-placeholder-color;
							border: .1rem solid #0C3DFB;
							box-shadow: 0 0 20px 5px $box-shadow-color;
							border-radius: 0;
						}

						&__menu-list {
							padding: 4.17rem 0 1.67rem 0;

							.dropdown {
								max-height: 30rem;
							}

							.scrollbar-container.vertical {
								margin-right: 0.5rem;
							}
						}

						&__option {
							padding: 14px 12px;
							font-size: 2.33rem;
							//font-family: $font-family;
							font-weight: 400;

							&:hover {
								box-shadow: 0 5px 20px $box-shadow-color;
								color: #00124A;
							}

							&--is-selected {
								background: none;
								color: $orange;
							}

							&--is-focused {
								background: none;
							}
						}

						&__indicator-separator {
							display: none;
						}

						&__control--menu-is-open {
							border: 0.5px solid $orange;
							box-shadow: none;
							z-index: 6;
						}

						&__control--menu-is-open .filter-select__single-value {
							color: #0C3DFB;
						}

						&__dropdown-indicator {
							font-family: "iconfont";
							font-style: normal;
							color: $filter-input-placeholder-color;
							padding-top: 35%;

							svg {
								display: none;
							}

							&::before {
								font-family: "iconfont";
								content: "\e959";
								font-size: 2rem;
							}
						}

						&__value-container {
							height: 4.16rem;
						}

						&__indicators {
							height: 4.16rem;
							cursor: pointer;
						}
					}
				}

				&__select-sub-category {
					margin-left: 1rem;
				}

				&__select-category,
				&__select-sub-category {
					display: flex;
					align-items: center;
					width: 49%;
					font-size: 2rem;
				}

				&__select-range-dates {
					position: relative;
					display: flex;
					align-items: center;
					cursor: pointer;
					width: 100%;
					border-radius: 20rem;

					.calendar-opened {
						box-shadow: none;
						transition: 0.5s;

						.ant-calendar-range-picker-input {
							color: $color14 !important;

							&::placeholder {
								color: $color14 !important;
							}
						}

						.ant-input {
							z-index: 10;
						}
					}

					.calendar-closed {
						border: 0.5px solid transparent;
						transition: 0.3s;
					}

					.range-picker-wrapper {
						position: relative;
						display: flex;
						align-items: center;
						font-size: 2rem;
						width: 100%;
						height: 100%;
						border: 1px solid transparent;
						border-radius: 20rem;

						&:hover {
							border-color: $color1;

							.ant-calendar-range-picker-input {
								color: $color15 !important;

								&::placeholder {
									color: $color14 !important;
								}
							}
						}

						.ant-calendar-picker {
							margin-left: 3%;
							width: 100%;

							&:focus {
								outline: none;
							}

							.ant-calendar-picker-input {
								.ant-calendar-range-picker-input:nth-child(3) {
									width: 69%;
								}

								.anticon {
									.anticon-close-circle {
										font-family: "juicy_orange";
										font-style: normal;
										color: $filter-input-placeholder-color;

										& svg {
											display: none;
										}

										&::before {
											font-size: 2rem;
											content: "\e911";
											fill: red;
										}
									}
								}
							}

							.ant-input {
								span,
								i {
									&:focus {
										outline: none;
									}
								}

								.ant-calendar-picker-icon {
									color: $filter-input-placeholder-color;
									font-size: 2rem;
									position: absolute;
									right: 2rem;
									top: 50%;
									transform: translateY(-45%);
								}

								.ant-calendar-picker-clear {
									position: absolute;
									right: 1.9rem;
									top: 1.1rem;
									width: 2rem;
									height: 2rem;
								}

								.ant-calendar-range-picker-input {
									width: 23%;
									background: $filter-input-background;
									border: none;
									outline: none;
									color: $color20;
									box-sizing: border-box;
									font-size: 2.33rem;
									//font-family: $font-family;
									cursor: pointer;

									&::placeholder {
										color: $color14;
									}
								}
							}
						}

						&.calendar-empty {
							.ant-calendar-picker {
								.ant-input {
									.ant-calendar-range-picker-input {
										width: auto;
										width: 100%;
										&:nth-child(3) {
											display: none;
										}
									}
									.ant-calendar-range-picker-separator {
										display: none;
									}
								}
							}
						}
					}
				}
			}
			.ticket {
				position: relative;
				margin: 1rem 0;
				padding: 0.5rem 2rem 0 3rem;
				font-weight: 400;
				line-height: 1.8;
				height: 13.3rem;
				background-color: $color47;
				box-shadow: 0 0 1rem rgba(0, 0, 0, 0.16);
				z-index: 0;
				cursor: pointer;
				&:first-child {
					margin-top: 0;
				}
				&.active {
					background-color: $hover-list-bgcolor;
					@include theme-var(
						(
							background-color: hover-list-bgcolor
						)
					);
					li:nth-of-type(1) {
						color: $orange;
						@include theme-var(
							(
								color: orange-title
							)
						);
					}
					&::after {
						content: " ";
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						right: 0;
						width: 0;
						height: 0;
						border-top: 1.8rem solid transparent;
						border-bottom: 1.8rem solid transparent;
						border-right: 2.3rem solid $color18;
						border-right-color: $color18;
					}
				}

				&.active {
					.ticket-title {
						&__text {
							color: $color15;
						}

						&_new {
							color: $color1;
						}

						&_processed {
							color: $color1;
						}

						&_confirmed {
							color: $color1;
						}

						&_rejected {
							color: $color1;
						}

						&_pending {
							color: $color1;
						}
					}

					.ticket-meta {
						.info-block {
							&-content {
								color: $selected-ticket-text-color;
							}

							&-title {
								color: $color14;
							}
						}
					}
				}

				i {
					position: absolute;
					font-size: 2rem;
					top: 2rem;
					right: 3.3rem;
				}

				&-title {
					color: $color15;
					margin-bottom: 1rem;
					font-size: 2rem;

					&__text {
						display: block;
						width: 90%;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 2.3rem;
					}
					@include theme-var(
						(
							color: color-title-ticket
						)
					);
				}
				&-meta {
					display: flex;

					.info-block {
						font-size: 2.3rem;

						&-title {
							color: #999999;
							font-size: 2rem;
							width: 95%;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							@include theme-var(
								(
									color: color-subtitle-items
								)
							);
						}
						&-content {
							color: $color15;
							width: 95%;
							font-size: 2.3rem;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							@include theme-var(
								(
									color: color-info-ticket
								)
							);
						}
					}

					.time {
						width: 40%;

						.info-block {
							&-content {
								white-space: pre;
							}
						}

						.subcategory {
							width: 30%;
						}

						.category {
							width: 30%;
						}
					}

					.subcategory {
						width: 30%;
					}

					.category {
						width: 30%;
					}
				}
				&-notification {
					position: absolute;
					top: 2rem;
					left: 1.2rem;
					width: 1rem;
					height: 1rem;
					background-color: $color1;
					border-radius: 50%;
				}
				&:hover {
					background-color: $color5;

					.ticket-title,
					.info-block-content {
						color: $color20;
					}
				}

				&.active {
					background: $color26;

					.ticket-title,
					.category-content,
					.time-content,
					.info-block-title,
					.info-block-content{
						color: $color15;
					}
					li:nth-of-type(1) {
						color: #0c3dfb;
						@include theme-var(
							(
								color: orange-title
							)
						);
					}
					&::after {
						content: " ";
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						right: 0;
						width: 0;
						height: 0;
						border-top: 1.8rem solid transparent;
						border-bottom: 1.8rem solid transparent;
						border-right: 2.3rem solid $color18;
					}
				}
			}
			.ticket-creation-method {
				height: 15rem;
				display: flex;
				flex-direction: column;
				background-color: $bg-color-item-ticket-list;
				color: $color-title-ticket;
				margin-bottom: 1rem;
				padding: 1rem 2rem;
			}

			.scrollarea {
				width: 100%;
				touch-action: none;

				&.scrollbar-container.vertical {
					opacity: 0.7 !important;
					width: 8px;
					height: 100%;
					background-color: transparent !important;
					right: 0;
					top: 0;
				}
			}
			.tooltip__text {
				font-size: 2.333rem;
			}
		}
	}
}
/* Template styles for show scroll tabs, must be fix */
.tickets .panel-header {
	padding-left: 2rem;
	overflow: hidden;
	height: 6.6rem;
}
.tickets .panel-button {
	display: none;
}
.tickets .ScrollbarsCustom-holder {
	height: 100%;
}
/* *************** */
.active-content {
	height: 100%;
}

.wrapper-form-create-ticket {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;

	&__verified {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;

		.svg-icon {
			margin-top: 5rem;
			width: 35.333rem;
		}

		.verified-text {
			margin-top: 6.66rem;
			text-align: center;
			font-size: 3rem;
			color: $color14;
		}

		.main-button {
			margin-top: 7.5rem;
		}
	}
	/* Template styles for tickets */
	.form-question {
		background: none;
		width: 93%;
		padding: 0 0 5.5rem;
		line-height: 1.3;
		flex-wrap: wrap;
		flex-direction: row;
	}

	&__title {
		font-size: 2.3rem;
		color: $color14;
		margin-bottom: 2rem;
		font-weight: 400;
	}

	&__item {
		font-size: 2.3rem;
		font-weight: 400;
	}

	&__item-title {
		color: $color1;
	}

	&__item-text {
		color: $color14;

		span {
			padding-left: 3rem;
			display: block;
		}
	}
	form {
		.floatingLabelInput {
			width: 100%;

			label {
				display: block;
				font-size: 2.3rem;
				color: $color14;
				padding-left: 2rem;
				padding-bottom: 0.5rem;
				padding-top: 1rem;
				z-index: 10;
			}

			input {
				font-size: 2.3rem;
				color: $color15!important;
				background-color: $color7!important;
				border-radius: 0;
			}

			textarea {
				font-family: "Lato", Roboto, Arial, sans-serif;
				font-size: 2.3rem;
				background-color: $color7;
				color: $color15;
				border-radius: 3rem;
			}

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active,
			textarea:-webkit-autofill,
			textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
			select:-webkit-autofill,
			select:-webkit-autofill:hover,
			select:-webkit-autofill:focus {
				border: 1px solid $color7 !important;
				-webkit-text-fill-color: $color15;
				-webkit-box-shadow: 0 0 0px 1000px $color7 inset;
				transition: background-color 5000s ease-in-out 0s;
				caret-color: $color15;
				&:hover, &:active {
					border: 1px solid $color1 !important;
				}
			}
		}
	}



	.authorisation-error {
		display: block;
		margin-bottom: 2rem;
		font-size: 2.3rem;
		color: $color11;
		text-transform: none;
		margin-left: 2rem;
	}

	.scroll {
		border-radius: 3rem;
		background-color: $color7;
	}

	.error {
		input,
		.scroll {
			border: 1px solid $color11 !important;
			border-radius: 0;
			background-color: $color7;
		}
	}

	.files {
		padding-top: 15px;

		&__dropzone {
			display: flex;
			flex-wrap: wrap;
			font-size: 2.3rem;

			&.error {
				flex-wrap: nowrap;
			}
		}
		&__item {
			width: 23.2%;
			margin-right: 1.1rem;
			margin-bottom: 1.5rem;

			&:nth-child(4n + 4) {
				margin-right: 0;
			}
		}

		&__error-msg {
			display: none;
			align-items: center;
			margin-bottom: 1.5rem;
			padding-left: 1rem;
			color: $color11;

			&.active {
				display: flex;
			}
		}

		&__plus {
			position: relative;
			display: block;
			height: 12.5rem;
			border: 1px solid $color5;
			cursor: pointer;
			border-radius: 0;

			&:hover {
				border-color: $color20;

				&::before,
				&::after {
					background-color: $color20;
				}
			}

			&.error {
				border-color: $color11;

				&::before,
				&::after {
					background-color: $color11;
				}
			}

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 30%;
				height: 1px;
				background-color: $color5;
			}
			&::after {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 1px;
				height: 42%;
				background-color: $color5;
			}
		}
		&__preview {
			position: relative;
			height: 12.5rem;
			border: 1px solid transparent;
			overflow: hidden;
			border-radius: 0;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__control-btn {
			opacity: 0;
			position: absolute;
			top: 25px;
			transition: opacity 0.3s;

			i {
				position: initial;
				font-size: 4rem;

				&:hover {
					color: $color20;
				}
			}

			&_preview {
				left: 15px;

				i {
					color: $color14;
					background: 0;
				}
			}

			&_remove {
				right: 15px;

				i {
					color: $color14;
				}
			}
		}
	}

	.files__item:nth-child(4n):nth-last-child(-n + 5) ~ .files__item {
		margin-bottom: 0;
	}

	.files__item:not(:first-child):hover {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(44, 49, 61, 0.8);
		}

		.files__control-btn {
			opacity: 1;
		}
	}
	&__wrap-btn {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		padding-top: 2.5rem;

		button {
			@extend .main-button;
			display: block;
			min-width: 20rem;
			height: 8.3rem;
			margin-left: 3rem;
			text-transform: capitalize;
			font-size: 3rem;
		}
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 8.3rem;
			min-width: 20rem;
			font-size: 3rem;
			background-color: $color5;
			color: $color20;
			line-height: 3rem;
			text-transform: capitalize;
			box-sizing: border-box;
			border-radius: 0;

			&:hover {
				background-color: $color6;
			}
		}
	}
	/* ------------------------------- */
	h2 {
		color: $color-subtitle-items;
	}
	form {
		display: flex;
		flex-direction: column;
		width: 70rem;
		padding: 5rem 10rem;
		background-color: $bg-color-item-ticket-list;
		color: #fff;
		position: relative;

		input[type="text"] {
			width: 100%;
			height: 5rem;
			border: 0.01rem solid transparent;
			outline: none;
			color: #001225;
			padding-left: 1.5rem;
			box-sizing: border-box;
		}
		.contents {
			padding-right: 2rem;
			background: #232733;
		}
		textarea {
			width: 100%;
			height: 17rem !important;
			background: #f5f5f5;
			border: 0.01rem solid transparent;
			outline: none;
			color: #001225;
			padding: 1.5rem 2rem 1.5rem 1.5rem;
			resize: none;
			box-sizing: border-box;
			overflow: auto;
			-ms-overflow-style: none; /* IE 11 */
			scrollbar-width: none; /* Firefox 64 */
			font-family: "Lato", Roboto, Arial, sans-serif;
			&::-webkit-scrollbar {
				appearance: none;
				display: none;
				width: 0px;
				background: transparent;
			}
		}
	}
	.messages {
		form {
			width: 90rem;
			background-color: transparent;
			padding: 4rem 10rem;
			textarea {
				border-radius: 2rem;
				background: #2c313d;
				border: 0.01rem solid transparent;
				outline: none;
				color: #e0d9cb;
				padding: 1.5rem;
				height: 10rem;
				font-family: "Lato", Roboto, Arial, sans-serif;
			}
			.wrap-button {
				display: flex;
				justify-content: flex-end;
				margin-top: 2rem;
				span {
					color: $color-subtitle-items;
					font-size: 1.8rem;
				}
				button {
					text-transform: capitalize;
					@extend .main-button;
				}
			}
		}
	}
}
.preview {
	height: 70rem !important;
	.modal-wrap {
		height: calc(100% - 4rem);
		border-radius: 0 0 2rem 2rem;
		overflow: hidden;
		justify-content: center;
		align-items: center;
		display: flex;
		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
		}
	}

	.modal-header {
		img {
			width: 10px;
			height: 10px;
		}
	}
}

.failed {
	width: 100%;
}

.tickets {
	.tabs {
		a {
			width: 45%;
			height: 4.1rem;
			font-size: 2.3rem;
			border-radius: 0;
			border: none;

			&.active {
				border: 0.1rem solid transparent;
				border-bottom: 1px solid #0c3dfb;
			}
		}
	}
}

.ticket-chat {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 113.3rem;
	height: 12.5rem;
	background-color: $color37;
	font-size: 3rem;
	border-radius: 0;
	font-weight: 400;

	&__title {
		color: $color14;
	}

	&__content {
		display: flex;
		justify-content: center;
		margin-top: 1rem;
		width: 95%;
		color: $color15;

		.icon-tickets-size {
			font-size: 2.666rem;
		}
	}

	&__updated-at {
		white-space: pre;
	}

	&__status {
		display: flex;
		justify-content: start;
		white-space: pre;
		color: $color20;

		.icon-new {
			font-size: 2.666666667rem;
		}

		i {
			margin: 0.6rem 0.5rem 0 1rem;
		}
	}
}

.ant-calendar-picker-container {
	left: 13.7rem !important;
	top: 38.8rem !important;
	z-index: 30;
	width: 58rem;

	.ant-calendar-cell {
		cursor: pointer;
		background-color: $color31;
	}

	.ant-calendar-range {
		border: 1px solid $color1;
		border-radius: 2rem;
		box-shadow: 0 0 20px 5px $color46;
		padding: 4.4rem 1px 1px 1px;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}

	.ant-calendar-in-range-cell {
		.ant-calendar-date {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $color24;
			width: 100%;
			height: 7.5rem;
		}
	}

	.ant-calendar-input-wrap {
		display: none;
	}

	.ant-calendar-column-header-inner {
		color: $color1;
	}

	.ant-calendar-header {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 6.7rem;
		background-color: $color18;
		outline: none;

		.ant-calendar-month-select,
		.ant-calendar-year-select {
			color: $color14;
			font-size: 2.33rem;
			//font-family: $font-family;
			font-weight: 500;
			cursor: default;
		}

		div {
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;

			span {
				position: absolute;
				left: 24rem;
				display: flex;
				justify-content: center;

				.ant-calendar-year-select {
					margin-left: 5px;
				}
			}
		}

		.ant-calendar-prev-year-btn {
			width: 1.5rem;
			height: 1.5rem;
			margin-left: 3rem;
			border: solid $color14;
			border-width: 0 0.1rem 0.1rem 0;
			transform: rotate(135deg);
		}

		.ant-calendar-prev-month-btn {
			width: 1.5rem;
			height: 1.5rem;
			margin-left: 3rem;
			border: solid $color14;
			border-width: 0 0.1rem 0.1rem 0;
			transform: rotate(135deg);
		}

		.ant-calendar-next-month-btn {
			position: absolute;
			width: 1.5rem;
			height: 1.5rem;
			right: 8rem;
			border: solid $color14;
			border-width: 0 0.1rem 0.1rem 0;
			transform: rotate(-45deg);
		}

		.ant-calendar-next-year-btn {
			position: absolute;
			right: 3rem;
			width: 1.5rem;
			height: 1.5rem;
			border: solid $color14;
			border-width: 0 0.1rem 0.1rem 0;
			transform: rotate(-45deg);
		}

		.ant-calendar-year-panel,
		.ant-calendar-month-panel {
			display: none;
		}
	}

	.ant-calendar-month-panel,
	.ant-calendar-year-panel {
		position: absolute;
		height: 50%;
		top: 1px;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		outline: none;
	}

	.ant-calendar-range-right {
		.ant-calendar-month-panel,
		.ant-calendar-year-panel {
			top: 50%;
		}
	}

	.ant-calendar-table {
		background-color: $color31;
	}

	.ant-calendar-date-panel {
		background-color: $color31;
		border-radius: 0 0 3% 3%;
		cursor: default;
	}

	.ant-calendar-column-header {
		background-color: $color31;
		font-size: 2.33rem;
		//font-family: $font-family;
		color: $filter-selected-item;
	}

	.ant-calendar-range-middle {
		display: none;
	}

	.ant-calendar-body {
		padding: 0 3rem;
		background: $color31;
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;
	}

	.ant-calendar-tbody {
		tr {
			height: 7.3rem;
			&:hover {
				background: 0;
			}
		}
		tr:nth-child(odd) {
			background-color: $color31;
		}

		.ant-calendar-cell {
			height: 7rem;
			font-size: 2.33rem;
			//font-family: $font-family;
			color: $date-picker-cell-value;
		}

		.ant-calendar-active-week {
			.ant-calendar-selected-start-date,
			.ant-calendar-selected-end-date {
				width: 7rem;
				height: 7rem;
				border-radius: 50%;
				padding: 0 0 0 1.07rem;
				background-color: $color24;

				.ant-calendar-date {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					width: 5rem;
					height: 5rem;
					background-color: $color1;
					border-radius: 50%;
					color: $color20;
					border: 0;
					line-height: 5rem;
				}
			}

			.ant-calendar-selected-end-date {
				padding: 0 1.2rem;
				border-radius: 0 5rem 5rem 0;
			}

			.ant-calendar-selected-start-date {
				padding: 0;
				border-radius: 5rem 0 0 5rem;
			}
		}

		.ant-calendar-today {
			padding: 0 1rem;
			height: 7rem;

			&.ant-calendar-in-range-cell {
				background-color: $date-picker-in-range-cell-background;
			}

			.ant-calendar-date {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 5rem;
				height: 5rem;
				border: 0.1rem solid $color1;
				border-radius: 50%;
				color: $color1;
			}

			&.ant-calendar-selected-date {
				&.ant-calendar-next-month-btn-day,
				&.ant-calendar-last-month-cell {
					& > .ant-calendar-date {
						border: none;
						color: $filter-input-placeholder-color-disabled;
					}
				}
			}
		}

		.ant-calendar-next-month-btn-day,
		.ant-calendar-last-month-cell,
		.ant-calendar-disabled-cell {
			color: $filter-input-placeholder-color-disabled;
			cursor: auto;
		}

		.ant-calendar-selected-day {
			&.ant-calendar-selected-start-date.ant-calendar-selected-end-date {
				border-radius: 50%;
			}
		}
	}
}
.ticket-wrap {
	margin-top: 1rem;
}

.none-error {
	display: none;
}

.visible-error {
	display: block;
}

.content {
	.no-logo {
		z-index: 19;
		background: #020D1D;
	}
}

.files__dropzone{
	&__limit, &__size {
		color: $color11;
	}
}






