@import "../../../../../styles/constants.scss";

.header {
	.control {
		.currency-pair--tools {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 2.3rem;
			margin: 1rem 0;
			height: 14.16rem;
			width: 30rem;
			padding: 0 0.5rem !important;
			background-color: #fff;
			color: #020d1d;
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: #fff;
				color: #020d1d;
			}

			.icon-currency {
				font-size: 3rem !important;
				color: #999999;
			}

			.name-currency {
				font-size: 3rem;
				padding: 0 1rem;
				line-height: 2;
			}

			.icon-single-down-pointing-angle {
				font-size: 2rem;
				color: #999999;
			}
		}
	}
}
