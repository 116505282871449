.dashboard {
	height: calc(100% - 14.16rem);
	overflow: hidden;

	// TODO: Maybe will move .col class to layout section
	& .col {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		margin: 0 1rem;
		width: 68.33333rem;

		&.wide {
			flex-grow: 2;
		}

		.col-item {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			margin: 1rem 0;

			&:first-child {
				margin-top: 0;
				height: 70%;
			}

			&:last-child {
				margin-bottom: 0;
				height: 28%;
			}
			&.col-order-height {
				min-height: 34rem;
				height: 24%;

				.order-center {
					height: 100%;
					box-sizing: border-box;
					justify-content: space-around;
				}

				.order-button {
					margin-top: 2rem;
				}
			}

		}

	}
}

.modal-2FA {
	box-shadow: 0 0 2rem rgba($color1, 0.5);
	border: 0.16rem solid $color1;
	min-width: 133.3rem;
	font-size: 3rem;
	min-height: 23.4rem;

	.title {
		color: $color15;
		font-size: 3rem;
		text-transform: uppercase;
	}

	.header-2FA {
		background: $color7;
		i {
			font-size: 3rem;
		}
	}
}

.modal-deposit {
	width: 133.3rem;

	.text-modal-deposit {
		padding: 8.333rem 11.666rem 6.666rem 11.666rem;

		h2 {
			color: $color14;
			padding-left: 1.5rem;
		}

		span {
			font-size: 3rem;
			color: $color14;
		}
	}

	.deposit_button {
		display: block;
		width: 30rem;
		height: 8.333rem;
		margin: 0 auto 4rem auto;
		background-color: $color1;
		text-transform: capitalize;
		font-size: 3rem;

		&:hover {
			background-color: $color2;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			height: 100%;
			font-family: Lato, sans-serif;
		}
	}
}

.currency-pair-modal3 {
	width: 23.3333rem;
	margin-right: 1rem;
}

