@import "../../styles/common-colors/common-colors";
@import "../../styles/variables.scss";

.wrapper.login {
	border-radius: 0;
	overflow: hidden;
}
.form-input.form-input.form-input.form-input.form-input.form-input.form-input.form-input {
	&.filled,
	&:-webkit-autofill {
		& + label {
			top: -3.6rem !important;
			font-size: 2.333rem !important;
		}
	}
}

.captcha-input {
	-moz-appearance: textfield;
	margin-bottom: 2rem;
	width: 100%;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
.captcha-img {
	min-height: 80px;
}
.login {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
	z-index: 1000;
	background: linear-gradient(to bottom, $color14, #001225);

	.login-form {
		a.link-item {
			font-size: 2.7rem;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	.authenticator-form {
		a.link-item {
			opacity: 0;
			pointer-events: none;
		}

		.hidden-field + .failed {
			display: none;
			& + .failed {
				display: none;
			}
		}
	}

	.log-bg {
		position: fixed;
		transform: translate(0, -48%);
		top: 50%;

		&_left {
			left: 0;
			width: 106.666rem;
		}

		&_right {
			right: 0;
			width: 106.666rem;
		}
	}

	.column-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		transform: translateY(-4.5%);
		height: 120rem;
		justify-content: center;
		.logo-link {
			display: flex;
			justify-content: center;
			position: relative;
			margin-bottom: 15.5rem;
			width: 27.4rem;
			img {
				width: 100%;
			}
		}

		form {
			display: block;
			position: relative;
			margin: 0 auto 4rem;
			width: 70rem;
			background: transparent;

			h3 {
				margin-bottom: 11rem;
				font-size: 3rem;
				text-align: center;
				font-weight: 500;
				color: $color20;
			}

			.authorisation-error {
				left: 0;
				text-align: left;
				font-size: 2.3rem;
				margin-top: 0.5rem;
				color: #FF4D4D;
				text-transform: none;
			}

			.floatingLabelInput {
				display: flex;
				position: relative;
				flex-direction: column;

				label {
					position: absolute;
					top: 0.4rem;
					left: 0;
					transition: all 0.3s;
					color: $color65;
					font-size: 2.666rem;
					line-height: 1;
					font-weight: 400 !important;

					&.on {
						top: -3.6rem;
						font-size: 2.333rem;
					}
				}

				input {
					font-size: 2.666rem;
					background-color: transparent;
					color: $color20;
					height: 4.8rem;
					padding-left: 0;
					outline: none;
					border: 0;
					border-bottom: 1px solid $color5;

					::-webkit-input-placeholder {
						/* Chrome/Opera/Safari */
						color: $color65;
					}
					::-moz-placeholder {
						/* Firefox 19+ */
						color: $color65;
					}
					:-moz-placeholder {
						/* Firefox 18- */
						color: $color65;
					}
					&:focus, &:hover {
						box-shadow: none !important;
					}
					&:focus {
						border-bottom-color: $color20;
						& +.on {
							color: $color20;
						}
					}
				}


				input,
				input:matches([type="password"], [type="search"]) {
					-webkit-appearance: textfield;
				}

				input:invalid {
					box-shadow: none;
				}

				input:-moz-submit-invalid {
					box-shadow: none;
				}

				input:-moz-ui-invalid {
					box-shadow: none;
				}

				input:-webkit-autofill,
				textarea:-webkit-autofill,
				select:-webkit-autofill {
					-webkit-box-shadow: 0 0 0 9999px transparent inset !important;
					-webkit-text-fill-color: $color20 !important;
					box-shadow: transparent 0 0 0 30px inset;
					caret-color: $color20;
					transition: background-color 5000s ease-in-out 0s;
				}
			}

			.failed {
				position: relative;
				font-weight: bold;
				display: inline-block;
				margin-bottom: 6.5rem;

				&:last-of-type {
					margin-bottom: 0;
				}

				div {
					width: 100%;
					display: flex;
					justify-content: flex-start;
				}

				&.error {
					span {
						color: $color11;
					}

					input {
						border-bottom-color: $color11;
					}
				}
			}



			//button {
			//	display: block;
			//	border: none;
			//	cursor: pointer;
			//	background-color: $color1;
			//	color: $color20;
			//	width: 23.3rem;
			//	height: 8.3rem;
			//	border-radius: 8.3rem;
			//	margin: 6rem auto 0;
			//	font-size: 3rem;
			//	transition: background 0.3s;
			//
			//	&:hover {
			//		background-color: $color2;
			//	}
			//}
		}

		.social-buttons {
			display: flex;
			justify-content: center;

			.social-icons {
				width: 6rem;
				height: 6rem;
				margin: 0;
				padding: 0;
				background-color: transparent;
				box-shadow: 0 0.5rem 0.83rem rgba(0, 0, 0, 0.3);

				&:first-of-type {
					margin-right: 2rem;
				}
			}
		}
	}

	.technologies {
		position: absolute;
		bottom: 3.6rem;
		font-size: 2.3rem;
		color: $color14;
		text-align: center;
		padding: 0;
		margin: 0;
		max-width: 135.833rem;
	}
}

.hidden-field {
	-webkit-box-shadow: 0 0 0 9999px transparent inset !important;
	-webkit-text-fill-color: $color15 !important;
	box-shadow: transparent 0 0 0 30px inset;
	caret-color: $color15;
	transition: background-color 5000s ease-in-out 0s;

	position: absolute;
	opacity: 0;
	overflow: visible;
}

.translate-form {
	position: absolute;
	width: 23rem !important;
	left: -27rem;
	top: -6rem !important;

	.rc-dropdown-menu-item {
		display: flex;
		align-items: center;
		width: 100% !important;
		padding: 1rem 0;
		text-transform: uppercase;
		height: 3rem;
		font-size: 2rem;

		i {
			top: 0;
			left: 1rem;
			width: 5rem;
			height: 5rem;
		}

		span {
			position: absolute;
			left: 7rem;
		}
	}
}

.login-img {
	&::after,
	&::before {
		content: "";
		position: fixed;
		left: 0;
		top: 0;
		width: 25%;
		bottom: 0;
		background: url('') no-repeat left top;
		background-size: contain;
	}
	&::after {
		left: auto;
		right: 0;
		background: url('../../../../img/svg/logo-right.svg') no-repeat right bottom;
		background-size: contain;
	}
}

.link-item {
	text-decoration: none;
	color: $color67;
	transition: color 0.3s;
	margin-bottom: 3rem;

	&:hover {
		color: #56ebff;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}

.lng-recovery {
	position: fixed;
	top: 2.4rem;
	right: 2rem;
}

.button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.secondary-button {
	margin-right: 4rem;
}
