@import "../../../../styles/constants.scss";
@import "../../../../styles/theming.scss";
@import "../../../../styles/common-colors/common-colors";

.panel_orders_expanded,
.panel_orders {
	.force-hide-tr {
		display: none;
	}
}

.tabs {
	border-bottom: none;


	button {
		display: flex;
		align-items: center;
		width: fit-content;
		text-transform: uppercase;
		justify-content: center;
		border: 1px solid $color65;
		padding: 1rem 2rem;
		color: $color65;
		font-size: 2.33rem;
		line-height: 0.5;

		&:hover {
			cursor: pointer;
			color: $color1;
			border: 1px solid $color1;
		}

		&.active {
			color: $color1;
			background: $color23;
			border: 1px solid $color1;
		}
	}
}



.currency-pair-wrapper {
	.currency-pair {
		padding: 0 1rem;

		&-orders {
			&-expanded {
				font-size: 2.3rem;
			}
		}
	}

	i {
		color: #999999;
	}
}


.currency-pair-orders-expanded {
	color:  $color14;
}
.billing-header {
	thead tr {
		height: 5.5rem;
	}
}
.expanded-history,
.general-body {
	thead {
		tr {
			color:  $color65;
			background: none !important;
		}
	}
	tr {
		color:  $color14;

		.order-type {
			width: 8.33333333333333333rem;
			&.cl-w {
				width: auto;
			}
			.buy {
				color: $color-buy;
				.Buy:before {
					content: "\e95a";
					font-family: 'iconfont';
					font-size: 1.6rem;
					font-weight: 800;
					position: relative;
					left: -0.5rem;
					font-style: normal;
				}
			}

			.sell {
				color: $color-sell;
				.Sell:before {
					content: "\e959";
					font-family: 'iconfont';
					font-size: 1.6rem;
					font-weight: 800;
					position: relative;
					left: -0.5rem;
					font-style: normal;
				}
			}
		}

		&:hover {
			background-color: $color33 !important;

			i.icon-currency {
				color: $color1;
			}

			&:nth-child(2n+1) {
				background-color: $color33 !important;
			}

			td {
				//color: $color20;

				.icon-currency {
					//color: $color20;
				}



				.currency-pair {
					&.currency-pair-orders-expanded {
						//color: $color20 !important;
					}
				}
			}
		}

		&:nth-child(2n+1) {
			background: none !important;
		}
	}
}


.react-tooltip-absolute-container {
	top: 20px;
}

.expanded-order {
	position: relative;
	top: 1rem;
	text-align: center;
}

.expanded-history {
	position: relative;
	top: 1rem;
}

.order-table-wrap {
	min-height: 100%;
	background: repeating-linear-gradient($color30, $color30 7.5rem, transparent 7.5rem, transparent 15rem);
}

.w50 {
	width: 8.33333333333333333rem;
}
