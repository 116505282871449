.container {
	&.chat {
		height: calc(100% - 14.16rem);

		.content {
			position: relative;
		}
	}
}
html.theme-dark .chart-page {
	background: #fff !important;
}
