@import '../../../../styles/constants.scss';
@import '../../../../styles/theming.scss';
@import '../../../../styles/common-colors/common-colors';

.symbols,
.symbols-extended {
	.wrapper-filter {
		width: 100%;
		margin: 1rem 0 0;
		padding-left: 1.9rem;
		padding-right: 1.9rem;
		box-sizing: border-box;
		i {
			top: 50%;
			transform: translateY(-45%);
		}
		.icon-close:hover {
			color: $color1;
			cursor: pointer;
		}
		.filter {
			width: 100%;
			margin: 0;
			padding-right: 5rem;
			display: block;
		}
	}
}

//Popular
.column-growth {
	color: $color-buy;
}

thead {
	cursor: default;
	i.icon-star-empty {
		cursor: default;
		&:hover {
			color: $color14;
		}
	}
	tr {
		color: $color65;
		font-size: 2.3rem;
		line-height: 5rem;
	}
}

tbody {
	color: $color14;
}
tr.supa-pupa-zebra {
	background: repeating-linear-gradient($color30, $color30 7.5rem, transparent 7.5rem, transparent 15rem);
}

.supa-pupa-zebra {
	background: repeating-linear-gradient($color30, $color30 7.5rem, transparent 7.5rem, transparent 15rem);
}
.quadrat {
	height: 7.5rem;
	box-sizing: border-box;
	padding: 1px;
	color: $color14;
	cursor: pointer;

	.icon-star-empty {
		color: $color34;
	}

	td {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:nth-child(2n+1) {
		background-color: $color30;
	}

	&:hover {
		background: $color33;
		//color: $color20;
		cursor: pointer;
		.icon-currency-left {
			color: $color1;
		}

		.icon-currency-right {
			color: $color1;
		}
	}
}

.currency-pair-symbols {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 17rem;
	font-size: 2.5rem;

	.icon-currency {
		font-size: 2.5rem;
		color: $color65;
	}

	&-expanded {
		font-size: 2rem;
	}
}

.icon-star-empty {
	font-size: 1.6rem;
	cursor: pointer;

	&.active {
		color: $color1;
	}
}



.table-filter-table {
	margin-top: .5rem;
}

table {
	width: 100%;
	color: #00124a;
	font-size: 2.3rem;
	text-align: center;
	outline: none;
	border-collapse: collapse;
	table-layout: fixed;
	position: relative;
	z-index: 3;

	.column-one {
		width: 30%;
	}

	.column-two {
		width: 15%;
	}

	.column-three {
		width: 24%;
	}

	.column-four {
		width: 24%;
	}

	.column-five {
		width: 7%;
	}

	.center-row {
		width: 100%;
	}

	.table-header {
		font-weight: 500;



		tr {
			height: 4.17rem;
		}

		th.sticky-header {
			position: sticky;
			top: -0.2rem;
			text-align: center;
			background-color: $thead-bg-color;
			font-weight: 400;
			z-index: 5;

			@include theme-var(
					(
						background-color: thead-bg-color,
					)
			);
		}
	}

	.table-body {
		tr {
			height: 4.17rem !important;
			padding-right: 0 !important;

			td {
				text-align: center;

				&.pair-symbols-expand {
					&:first-of-type {
						width: 100%;
						text-align: center !important;
					}

					.currency-pair-symbols {
						display: flex;
						justify-content: center;

						i:last-child {
							margin-left: 1rem;
						}
					}
				}

				&:first-of-type {
					width: 100%;
					text-align: start;
				}

				&:nth-of-type(4) {
					color: $color-buy;
				}
			}

			.icon-star-empty,
			.icon-star-fill {
				cursor: pointer;
				font-size: 1.6rem;
			}
			.icon-star-fill {
				color: $color1;
			}
		}
	}
	.icon-star-empty,
	.icon-star-fill {
		cursor: pointer;
		font-size: 1.6rem;
	}

	.icon-star-empty:hover {
		color: $color1;
	}
	.icon-star-fill {
		color: $color1;
	}
}

//Animation Popular table
.quadrat-green {
	animation: NAME-YOUR-ANIMATION 0.7s 1;
}

@keyframes NAME-YOUR-ANIMATION {
	0% {
		background-color: $color25;
	}
	49% {
		background-color: rgba($color: $color56, $alpha: 0.3);
	}
	100% {
		background-color: $color25;
	}
}

.quadrat-red {
	animation: NAME-YOUR-ANIMATION-RED 0.7s 1;
}

@keyframes NAME-YOUR-ANIMATION-RED {
	0% {
		background-color: $color25;
	}
	50% {
		background-color: rgba($color: $color58, $alpha: 0.3);
	}
	100% {
		background-color: $color25;
	}
}

.currency-pair-orders-expanded {
	color: $color14;
}

.currency-pair-orders-expanded i {
	font-size: 2.5rem;
}

.symbols-extended {
	.tabs-component {
		margin: 2rem;
	}
}

.symbols-header .icon-star-empty:hover {
	color: inherit;
}
