@import "constants.scss";
@import "theming.scss";

//Plus Panel-header
button,
a {
	position: relative;
	outline: none;
	padding: 0;
	border: none;
	background-color: transparent;
	color: $button-color;
	cursor: pointer;

	&:after {
		left: 0;
	}

	&.bubble {
		overflow: hidden;
		//font-family: $font-family;
	}
}

//Tabs Panel
.tabs {
	display: flex;
	justify-content: space-around;
	padding-top: 2rem;
	border-bottom: 0.16rem solid #ebebeb;
	margin: 0 3rem;

	.active {
		color: #0c3dfb;
		font-weight: 100;
		border: 1px solid transparent;
		border-bottom: 1px solid #0c3dfb;

		&:hover {
			border-bottom: 1px solid #0c3dfb !important;
		}

		@include theme-var(
			(
				color: control-btn-color,
				background: control-btn-bg-color-active,
				box-shadow: control-btn-shadow
			)
		);
	}

	button {
		display: block;
		width: fit-content;
		padding: 0 1rem;
		height: 4.1rem;
		font-size: 2.3rem;
		text-transform: uppercase;
		font-weight: 400;
		color: #00124a;
		border: 0.16rem solid transparent;
	}

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30rem;
		height: 3rem;
		font-size: $control-btn-font-size;
		text-transform: uppercase;
		font-weight: 400;
		border-radius: $control-btn-border-radius;
		border: 0.1rem solid rgba($color: $control-btn-border-color, $alpha: 0.5);

		background-color: $control-btn-bg-color;
	}

	button:nth-child(3n) {
		width: fit-content;
		padding: 0 2rem;
	}

	&-order {
		justify-content: flex-start;
		padding-left: 1rem;
	}

	&-trading {
		justify-content: flex-start;
		margin-left: 2.5rem;

		button {
			margin-right: 5rem;
		}

		&-expanded {
			justify-content: flex-start;
			margin-left: 8.5rem;

			button {
				width: 21rem;
				margin-right: 5rem;
			}
		}
	}

	&-billing {
		button {
			width: 19rem;
		}

		button:nth-child(3n) {
			width: 19rem;
			height: auto;
		}
	}

	&-purse {
		justify-content: flex-start;

		button {
			width: 19rem;
			margin-left: 1.5rem;
		}

		.active {
			background: $control-btn-bg-color-active;
			box-shadow: $control-btn-shadow;
			color: #e0d9cb;
			font-weight: 100;
			border: 0.1rem solid transparent;

			@include theme-var(
				(
					color: control-btn-color,
					background: control-btn-bg-color-active,
					box-shadow: control-btn-shadow
				)
			);
		}
	}

	&-settings {
		button {
			width: 25rem;
		}

		button:nth-child(2n) {
			height: auto;
		}
	}

	&-expanded {
		justify-content: flex-start;
		margin-left: 10rem;

		button {
			width: 21rem;
			margin-right: 4rem;
		}

		button:nth-child(3n) {
			width: 18rem;
		}
	}
}

.floatingLabelInput {
	position: relative;
	.input-wrapper {
		position: relative;
		&:focus-within {
			& + .on {
				color: $color1 !important;
			}
		}
	}
	input {
		width: 100%;
		box-sizing: border-box;
	}
	&.dynamic-input {
		margin-bottom: 2rem;
		div + div {
			margin-top: 2rem;
		}
		input {
			margin-bottom: 0 !important;
		}
	}
}

i.icon.show-password {
	position: absolute;
	height: 3rem;
	bottom: 0;
	top: 0;
	margin: auto;
	right: 1.5rem;
	font-size: 3rem;
	color: #999999;
	cursor: pointer;
	z-index: 100;

	&:hover {
		color: #fff !important;
	}
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: $color15 !important;
	color: $color15 !important;
	//border-color: #3a4772;
}
