@import "../../../../../styles/constants.scss";
@import "../../../../../styles/common-colors/common-colors";

.header {
	.control {
		.date {
			padding-left: 6.833rem;
			padding-top: 1rem;

			.day {
				display: block;
				font-size: 4rem;
				white-space: nowrap;
				color: #ffffff;
				margin: 0;
				text-align: center;
			}

			.time {
				display: block;
				margin: 0;
				color: $color2;
				font-size: 3.5rem;
				text-align: center;
			}
		}
	}
}
