@import "../../styles/constants.scss";
@import "../../styles/theming.scss";
@import "../../styles/common-colors/common-colors.scss";

.tabs-component {
	font-weight: normal;
	&__tabs {
		position: relative;
		display: flex;
		align-items: center;
		.settings &, .billing &, .symbols &, .panel_orders & {
			margin: 1.5rem 2.2rem 0;
		}
	}

	&__tab {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		padding: 0 1rem;
		text-align: center;
		color: $color65;
		height: 4.16rem;
		line-height: 3rem;
		font-size: 2.3333rem;
		text-transform: uppercase;
		box-sizing: border-box;
		background: 0;
		transition: .3s;
		box-shadow: inset 0 0 0 1px $color65;
		white-space: nowrap;
		.without-width & {
			width: auto;
		}
		& + & {
			margin-left: 2.2rem;
		}
		&:hover {
			border-color: $color1;
			box-shadow: inset 0 0 0 1px $color1;
			color: $color1;
		}

		&.active {
			background: 0;
			background: $color23;
			color: $color1;
			cursor: default;
			box-shadow: inset 0 0 0 1px $color1;
		}
	}

	&__slider {
		display: none;
		position: absolute;
		z-index: 10;
		bottom: -.16rem;
		height: .5rem;
		background: #0C3DFB;
		transition: left .3s ease;
	}

	&__tab:nth-child(1).active ~ &__slider {
		left: 0;
		width: 50%;
	}

	&__tab:nth-child(2).active ~ &__slider {
		left: 50%;
		width: 50%;
	}
}



.settings {
	justify-content: center;
	height: calc(100% - 14.16rem);
	background: $color7;

	&.security {
		.form-group {
			padding-top: 2rem !important;
			min-height: 65rem !important;
			padding-bottom: 0 !important;
		}

		.panel-body {
			padding-bottom: 0 !important;
		}
	}

	.scrollbars-container {
		background-color: $color7 !important;
	}

	.wrapper {
		min-height: 100%;
	}

	.panel {
		background-color: transparent;
		box-shadow: none;
	}

	.panel-body {
		flex-grow: unset !important;
		padding-bottom: 6.833rem;
		border-bottom-right-radius: 4rem;
		border-bottom-left-radius: 4rem;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: 1px solid $color7;
		-webkit-text-fill-color: #E0D9CB;
		-webkit-box-shadow: 0 0 0px 1000px $color7 inset;
		transition: background-color 5000s ease-in-out 0s;
		caret-color: $color15;
	}

	.scrollbars-container {
		background-color: $color18 !important;
	}

	.area {
		background-color: $color18;
	}

	.column {
		display: flex;
		width: 85.8rem;
		margin: 5rem auto;
		.panel {
			.panel-body-content {
				position: static;

				@include theme-var(
					(
						background-color: bg-panel-body-content,
					)
				);
			}
		}

		.active-content {
			height: auto;
			border-bottom-left-radius: 2rem;
			border-bottom-right-radius: 2rem;
			@include theme-var(
				(
					background-color: bg-active-content,
				)
			);
		}
		.user {
			padding-top: 5.8rem;
			padding-right: 27rem;
			display: flex;
			flex-direction: row;
			justify-content: center;

			.initials {
				width: 10rem;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 10rem;
				border-radius: 50%;
				font-size: 2.5rem;
				margin-left: -15rem;
				background-color: #232733;
				overflow: hidden;
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			input#avatar {
				display: none;
				& + label {
					cursor: pointer;
				}
			}
			span {
				font-size: 2rem;
				display: block;
				color: $color-date-registration;
			}
			.name,
			.date {
				color: $color15;
				@include theme-var(
					(
						color: color-name-date-settings,
					)
				);
			}
		}
		.form-group {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 14rem 2rem;
			&.security-settings {
				min-height: 77rem;
				display: flex;
				flex-direction: column;
				margin-top: 6rem;
				padding: 0 2rem 0 !important;

				.hidden-field{
					display: none;
				}

				.flex-wrapper {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;


					input::-webkit-credentials-auto-fill-button {
						visibility: hidden;
						pointer-events: none;
						position: absolute;
						right: 0;
					}
				}


				form {
					display: flex;
					flex-direction: column;
					flex: 1;

				}
				.show-password:hover {
					transition: color 0.3s;

					&:hover {
						color: $color20;
					}
				}
			}

			form {
				display: block;
				width: 100%;
			}

			.description.none {
				display: none;
				margin-bottom: 10px;
			}


			input {
				width: 100%;
				height: 3rem;
				margin-top: 1rem;
				margin-bottom: 1rem;
				padding: 0 2rem;
				background-color: $color7;
				border: none;
				outline: none;
				color: $color15 !important;
				font-size: 2.3rem;
				border-radius: 2rem;

				@include theme-var(
					(
						background-color: bg-color-input-settings,
					)
				);

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus {
					caret-color: $color15;
					-webkit-box-shadow: 0 0 0 1000px $color7 inset;
					-webkit-text-fill-color: #00124a;
					border: 0;
					transition: background-color 5000s ease-in-out 0s;
				}
			}
			select {
				font-size: 2.3rem;
			}
			label {
				width: 40rem;
				font-size: 2.3rem;
				padding-left: 3rem;
				color: $color-label-settings;
			}
			&_row {
				flex-direction: row;
				flex-wrap: wrap;
			}
			.failed {
				margin: 0 auto;
				width: 100%;
				font-size: 2.3rem;
				padding-bottom: 1rem;
				i.icon-single-down-pointing-angle {
					color: #3d4252;
					top: 3.8rem;
					font-size: 2rem;
				}
				input {
					width: 100%;
					height: 4.16rem;
					box-sizing: border-box;
					color: #e0d9cb;
					border-radius: 2rem;
					filter: none;
				}
				select:not([class='date-user']) {
					width: 100%;
				}
				select[class='date-user'] {
					height: 4.16rem;
					margin-right: 2rem;
				}
				label {
					padding-left: 2rem;
				}
				select[disabled] {
					-moz-appearance: none;
					-webkit-appearance: none;
					padding: 0 2rem;
					height: 4.16rem;
					background-color: #f5f5f5;
					color: #00124a;
					border: 0;
				}
				.authorisation-error {
					color: #FF4D4D;
					font-size: 2.3rem;
					text-transform: none;
					margin-left: 2rem;
				}
				.select-input__dropdown-indicator {
					display: none;
				}
			}
		}
		.changes {
			margin: 2rem auto 0;
			@extend .main-button;
			width: fit-content;
		}
	}

	.profile-wrapper {
		width: 88.8rem;
		margin-top: 5.333rem;

		.panel .panel-header .panel-title.panel-title--center-align {
			margin: 0;
		}
	}

	.checkbox-2FA {
		width: 100%;
		display: block;
		margin-bottom: 3.3rem;
		input {
			display: none;
		}
		label.checkbox-2FA__label {
			width: auto;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			position: relative;
			padding-left: 0;
			font-size: 2.3rem;
			cursor: pointer;
			color: $color1 !important;
			justify-content: space-between;
		}
		.checkbox-custom {
			position: relative;
			height: 3.3rem;
			background-color: $color7;
			border-radius: 0;
			text-align: right;
			padding-right: 1.5rem;
			padding-left: 4rem;
			transition: all .4s;
		}
		.checkbox-custom {
			&:hover {
				&:after {
					background: $color1;
				}
			}
		}
		.checkbox-custom::before,
		.checkbox-custom::after {
			content: '';
			display: block;
			position: absolute;
			transition: all .4s;
		}
		.checkbox-custom::after {
			top: 0;
			width: 3.3rem;
			height: 3.3rem;
			background-color: #999999;
			left: 0;
			border-radius: 50%;
			transition: right linear 0.3s;
		}

		.checked-text {
			right: 2rem;
			color: #fff;
			cursor: pointer;
			z-index: 2;
			transition: all .4s;
		}
		input:checked + .checkbox-2FA__label .checkbox-custom {
			padding-right: 4rem;
			padding-left: 1.5rem;
			text-align: left;
		}
		input:checked + .checkbox-2FA__label .checkbox-custom::after {
			right: 0;
			left: auto;
			background-color: $color1;
		}
		input:checked + .checkbox-2FA__label .checkbox-custom:hover::after {
			background-color: #999999;
		}

	}

}
.modal.modal2FA {
	height: 115rem;
	width: 90%;
	max-width: 200rem;
	font-size: 3rem;
	display: flex;
	flex-direction: column;
	.modal-header {
		min-height: 6.6rem;
		& +div {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
	}
	.line-content div {
		font-size: 2.333rem !important;
		span {
			margin-bottom: 2.5rem;
		}
		.modal-checkbox {
			margin-top: 2.5rem;
			display: inline-block;
		}
	}
	.modal-wrapper {
		display: flex;
		flex-direction: column;
		flex: 1;

        .modal-content {
          &__title {
            font-size: 6px;
          }
        }

		.modal-content {
			flex: 1;
		}
		button {
			@extend .main-button;
			&.button--prev {
				@extend .secondary-button;
			}
		}
	}
}

.modal.disable-2FA {
	width: 83.3rem;
}

.progress-bar {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 3.3rem;
	background-color: #3f4151;
	border-radius: 0;
	position: relative;
	overflow: hidden;
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 30%;
		height: 3.3rem;
		top: 0;
		left: 0;
		background-color: #0c3dfb;
		border-radius: 0;
		z-index: 0;
	}
	div {
		font-size: 0.8em;
		z-index: 1;
	}
}
.modal-wrapper {
	padding: 3.3rem;
	padding-top: 0;
}
.modal-content {
	display: flex;
	align-items: center;
	flex-direction: column;
	color: #999999;
	&_2fa {
		text-align: center;
		font-size: 3rem;
		div {
			font-size: 3rem !important;
		}
		.modal-content {
			&__title {
				display: flex;
				justify-content: center;
				margin-top: 12rem;
			}
		}
		.app-links {
			margin-top: 12.83rem !important;
			flex: 1;
		}
		& + .modal-button {
			button {
				top: -2rem;
				background: $color1;
				border-radius: 5rem;
				&:hover {
					background: $color2;
				}
			}
		}
	}

	span.authorisation-error {
		display: inline-block;
		max-width: 45rem;
		color: #FF4D4D;
		font-size: 2.3rem;
		text-transform: none;
		margin-left: 2rem;
	}
	div {
		font-size: 2.3rem;
	}
	p {
		color: $color1;
		display: flex;
		align-items: center;
		margin: 3.3rem;
		font-size: 6rem !important;
		font-weight: 400;

		span {
			border: 0.16rem solid $color1;
			border-radius: 50%;
			width: 11.684rem;
            height: 11.684rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 1.6rem;
		}
	}
	.app-links {
		margin: 10rem;
		a {
			margin: 1.6rem;
			display: inline-block;
			width: 34.166rem;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}
.modal-button {
	display: flex;
	align-items: center;
	justify-content: center;
}
.button {
	display: block;
	text-transform: capitalize;
	font-size: 3rem;
	background-color: #0c3dfb;
	color: $color-button-settings;
	height: 8.3rem;
	min-width: 20rem;
	margin: 1.6rem;
	padding: 1rem 3rem 1rem 3rem;
	&:hover {
		background-color: #2261ff;
	}
}
.button--prev {
	background-color: #999999;
	&:hover {
		background-color: #8d98b9;
	}
}
.modal-checkbox {
	position: relative;
	display: flex;
	justify-content: center;

	.error {
		display: none;
	}
	input {
		position: absolute;
		clip: rect(0, 0, 0, 0);
	}
	label {
		position: relative;
		display: block;
		cursor: pointer;
		padding-left: 4.6rem;
		margin-bottom: 2.5rem;
		font-size: 2.3rem;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: -0.1rem;
			display: block;
			width: 2.6rem;
			height: 2.6rem;
			border: 0.16rem solid $color5;
			border-radius: 0.33rem;
		}

		&::after {
			font-family: 'iconfont' !important;
			content: '\e94b';
			display: none;
			position: absolute;
			font-size: 2.1rem;
			left: 0.4rem;
			top: 0.2rem;
			color: $color1;
		}
	}
	input:checked + label::after {
		display: flex;
	}
	input:checked + label::before {
		border-color: $color1;
	}
}

.qr-code__step2 {
	span {
		font-size: 3rem;
	}

	.code {
		font-size: 4rem !important;
	}

	.modal-checkbox {
		margin: 0;
	}
}

.modal-checkbox_2 {
	display: block;

	.error {
		top: 1.6rem !important;
		font-size: 2.3rem !important;
	}

	label {
		margin-bottom: 0;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0.1rem;
			display: block;
			width: 2.6rem;
			height: 2.6rem;
			border: 0.16rem solid $color5;
			border-radius: 0.33rem;
		}

		&::after {
			font-family: 'iconfont' !important;
			content: '\e94b';
			display: none;
			position: absolute;
			font-size: 2.1rem;
			left: 0.4rem;
			top: 0.4rem;
			color: $color1;
		}

	}

}
.step3-title {
	margin-top: 16rem !important;
	margin-bottom: 3.33rem !important;
}
.screen3form {
	flex: 1;
	display: flex;
	flex-direction: column;

	.form-wrapper {
		font-size: 6px;
		flex: 1;
		width: 60rem;
	}
}

.modal-checkbox.invalid {
	.error {
		white-space: nowrap;
		position: absolute;
		color: #FF4D4D;
		left: 100%;
		display: block;
		top: 50%;
		margin-left: 3rem;
		transform: translateY(-50%);
		font-size: 2.3rem;
	}

	label::before {
		border-color: #FF4D4D;
	}
}
.line-content {
	display: flex;
	span {
		margin-bottom: 1.6rem;
		display: block;
	}
	.code {
		padding: 3.3rem;
		color: $color1;
		font-size: 1.4em;
		background-color: $color7;
		width: fit-content;
		border-radius: 0;
	}
}
.qr-code {
	margin-left: -3rem;
	padding: 0 3.3rem;
	width: 45.83333rem;
	height: 45.83333rem;

	img {
		width: 100%;
	}
}
.input-wrapper-hide {
	display: block !important;
	input {
		width: 100%;
	}
}
.screen3form {
	.input-wrapper-hide {
		position: relative;
		border-radius: 5rem;
		.fake-hide {
			&:before {
				content: '';
				position: absolute;
				left: 1.5rem;
				right: 1.5rem;
				top: -1px;
				z-index: 1;
				height: 2px;
				background: $color7;
			}
			&:after {
				content: '';
				position: absolute;
				left: 1.5rem;
				right: 1.5rem;
				bottom: -1px;
				z-index: 1;
				height: 2px;
				background: $color7;
			}
		}
		input {
			margin: 0;
			height: calc(4.1rem - 2px);
		}
		&:before {
			content: '';
			position: absolute;
			width: 1.9rem;
			top: -1px;
			bottom: -1px;
			left: -1px;
			border-radius: 4.9rem 0 0 4.9rem;
			background: $color7;
		}
		&:after {
			content: '';
			position: absolute;
			width: 1.9rem;
			top: -1px;
			bottom: -1px;
			right: -1px;
			border-radius:  0 4.9rem 4.9rem 0;
			background: $color7;
		}
	}
	button {
		margin-top: 16.6rem;
	}
	div {
		margin-top: 1rem;
	}
	label {
		display: block;
		margin-left: 2.3rem;
	}
	input {
		width: 100%;
		padding: 0 1.6rem;
		border-radius: 3rem;
		height: 4.1rem;
		border: none;
		outline: none;
		box-sizing: border-box;
		font-size: 2.3rem;
		background: $color7 !important;
		color: $color15;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: 1px solid $color7;
		-webkit-text-fill-color: #E0D9CB;
		-webkit-box-shadow: 0 0 0px 1000px $color7 inset;
		transition: background-color 5000s ease-in-out 0s;
		caret-color: $color15;
	}

	i.icon.show-password {
		transition: color 0.3s;
		z-index: 1;
		&:hover {
			color: $color1;
		}
	}
}
.screenDisableForm {

	margin-top: 13.3rem;
	button {
		margin-top: 8.3rem;
		border-radius: 5rem;
		@extend .main-button;
	}
}

.avatar {
	width: 13.3rem;
	height: 13.3rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 5rem;
	background-color: $color5;
	color: $color20;
	border-radius: 50%;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.setting .column .user {
	justify-content: flex-start;
	padding: 7rem 18rem 0 18rem;
}

.user__avatar {
	display: flex;
	flex-direction: column;
	margin-right: 3.3rem;
}

.ava-buttons {
	display: flex;
	padding-top: 1.6rem;
	justify-content: space-around;

	&__input {
		display: none;
	}
}

.ava-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	cursor: pointer;
	color: $color14;
	transition: 0.3s all ease;
	&:hover {
		color: $color20;
		background: $color1;
	}
	i {
		font-size: 3rem;
	}
}

.user__info {
	padding: 1.6rem 0;

	span:nth-of-type(1) {
		margin-bottom: 2.3rem;
	}

	span {
		margin-bottom: 0.83rem;
	}
}

.from-profile {
	.select-input {
		&--is-disabled {
			.select-input__indicators {
				display: none;
			}
			.select-input__single-value--is-disabled {
				color: $color15 !important;
				opacity: 1 !important;
			}
		}
	}
}

.modal.remove-modal {
	min-width: 133.3rem !important;
	min-height: 38rem;
	.modal-header {
		text-transform: uppercase;
	}
}

.modal.crop-modal {
	width: fit-content;
	z-index: 1000;
}

.crop-modal__body {
	display: flex;
	flex-direction: column;
	margin: 8rem;

	canvas {
		width: 83.33rem !important;
		height: 83.33rem !important;
	}
}

.modal-buttons {
	display: flex;
	justify-content: center;
	margin-top: 8rem;

	.button {
		margin: 0 1.6rem;
		@extend .main-button;
	}

	.button.gray {
		@extend .secondary-button;
	}
}

.modal__range {
	margin-top: 4rem;

}

.slider {
	-webkit-appearance: none; /* Override default CSS styles */
	appearance: none;
	width: 100%; /* Full-width */
	height: 0.83rem; /* Specified height */
	background: $color5; /* Grey background */
	outline: none; /* Remove outline */
	-webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
	transition: opacity 0.2s;
	border-radius: 0.83rem;

}

/* Mouse-over effects */
.slider:hover {
	background: $color6;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
	-webkit-appearance: none; /* Override default look */
	appearance: none;
	width: 3.3rem; /* Set a specific slider handle width */
	height: 3.3rem; /* Slider handle height */
	background: $color1; /* Green background */
	cursor: pointer; /* Cursor on hover */
	border-radius: 50%;
	position: relative;
	z-index: 2;

	&:hover {
		background: $color2;
	}
}

.slider::-webkit-slider-runnable-track {
	-webkit-appearance: none;

}

.slider::-moz-range-progress {
	background: $color46;
}

.slider::-moz-range-thumb {
	width: 4.17rem; /* Set a specific slider handle width */
	height: 4.17rem; /* Slider handle height */
	background: $color1; /* Green background */
	cursor: pointer; /* Cursor on hover */
	border-radius: 50%;
}

.delete-modal__title {
	margin-bottom: 4rem;
	font-size: 3rem;
	color: #00124a;
}

.delete-modal__desc {
	margin: 0 0 7rem 0;
	color: $color14;
	font-size: 3rem;
	& + .modal-buttons {
		margin-top: 0;
	}
}

.form-profile {
	input[type='file'] {
		display: none;
	}
}

.form-profile {
	.field .icon-single-down-pointing-angle {
		top: 4rem;
	}
}

.wrapper-checkbox-2FA {
	border: 1px solid #99999980;
	border-radius: 3rem;
	margin-bottom: 2rem;
	padding: 3rem;

	.details-text-2FA {
		color: #999999;
		font-size: 2.3rem;
		text-indent: 2rem;
	}
}

.wrapper-form-profile-password {
	border: 1px solid #99999980;
	border-radius: 3rem;
	margin-bottom: 2rem;
	padding: 8rem 4rem 4rem 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	.title {
		color: #008BE8;
		font-size: 2.3rem;
		position: absolute;
		top: 2.5rem;
		left: 3.5rem;
	}

	.failed {
		width: 50rem !important;

		.floatingLabelInput {
			width: 50rem;
			margin: 0 auto;
		}
	}
}
