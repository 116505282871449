@import "../../../../../styles/constants.scss";

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 14.16rem;

	& > div {
		height: 100%;
		width: 16.6rem;
	}

	.logo {
		display: flex;
		align-items: center;
		align-self: flex-start;
		width: 40rem;
		font-size: 6rem;
		margin-left: 2rem;

		a {
			&:visited,
			&:hover,
			&:focus,
			&:active {
				border: 0 !important;
				outline: 0 !important;
			}

			img {
				width: 27.5rem;
				height: 50%;
				padding-top: 2rem;
				border: 0 !important;
				outline: 0 !important;
			}
		}
	}

	.header-col {
		display: flex;
		align-items: center;
	}

	.header-col:nth-child(1n) {
		margin-left: 0.9rem;
		margin-right: 0.9rem;
	}

	.control {
		display: flex;
		align-self: center;
		flex-grow: 2;
		justify-content: space-between;
	}
}

.menu-item__content {
	width: 100%;
}

.rc-dropdown-menu > .rc-dropdown-menu-item.menu-item__links {
	box-sizing: border-box;
	padding: 8px 20px;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
}

.rc-dropdown-menu > .rc-dropdown-menu-item.menu-item__links.links-u {
	padding: 0;
}

.rc-dropdown-menu > .rc-dropdown-menu-item.menu-item__links a {
	width: 100%;
	height: 100%;
	padding: 8px 20px;
}

.settings-link:hover {
	color: #e0d9cb;
}

.faq-dropdown {
	position: absolute;
	background-image: linear-gradient(to right, #00124a, rgba(0, 18, 74, 0.8));
	border: 0.16rem solid #0c3dfb;
	box-shadow: 0 0 3rem rgba(25, 54, 127, 0.5);
	bottom: 0;
	left: 53.6rem;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 10000000;

	&::after {
		content: " ";
		position: absolute;
		bottom: 3rem;
		left: -1rem;
		transform: rotate(-45deg);
		display: block;
		width: 2rem;
		height: 2rem;
		border: 0.1rem solid #0c3dfb;
		border-bottom: none;
		border-right: none;
		background-color: #01134a;
	}

	&::before {
		content: "";
		display: block;
		width: 10rem;
		height: 10rem;
		position: absolute;
		top: -6rem;
		left: 50%;
		transform: translateX(-50%);
	}

	a {
		display: block;
		padding: 2rem 4rem;
		font-size: 3rem;
		z-index: 10000;
		color: #999999;

		&:hover {
			color: #fff;
			background-color: #0c3dfba1;
		}
	}
}

.faq-button {
	position: relative;
	width: 100%;
	display: flex;
	padding-left: 6rem;
	font-size: 3rem;
	color: #fff;
	padding-top: 2rem;
	padding-bottom: 2rem;
	margin-bottom: 2rem;

	i {
		font-size: 4rem;
		margin-right: 5rem;
	}

	&:hover .faq-dropdown,
	.faq-dropdown:hover {
		display: block;
	}
}

.user-block {
	position: relative;

	&:hover .profile-drop-down,
	.profile-drop-down {
		display: block;
	}

	.profile-drop-down {
		position: absolute;
		background-color: #fff;
		right: 1rem;
		top: 14.16rem;
		border: 1px solid #0c3dfb;
		box-shadow: 0 0 3rem rgba(12, 61, 251, 0.47);
		width: 77rem;
		z-index: 1000;

		&::after {
			content: " ";
			position: absolute;
			top: -1.3rem;
			left: 94%;
			-webkit-transform: translateX(-50%) rotate(45deg);
			transform: translateX(-50%) rotate(45deg);
			display: block;
			width: 2rem;
			height: 2rem;
			border: 0.1rem solid #0c3dfb;
			border-bottom: none;
			border-right: none;
			background-color: #fff;
		}

		&::before {
			content: "";
			display: block;
			width: 14rem;
			height: 10rem;
			position: absolute;
			top: -6rem;
			left: 90%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
		}
	}

	.profile-authorization {
		display: flex;
		font-size: 2.1rem;

		.reg-info {
			width: 26rem;
			text-overflow: ellipsis;
			overflow: hidden;
			color: #2b303b;
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: center;
		}
	}

	.registration-date {
		display: flex;
		flex-direction: column;
		color: #999999;
		font-size: 2.1rem;
		margin-top: 1.5rem;
	}

	.initials {
		width: 19.5rem;
		height: 19.5rem;
		background-color: #999999;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 5rem;
		margin-right: 2rem;
		color: #fff;

		img {
			width: 100%;
			height: 100%;
			border-radius: 0;
		}
	}

	.reg-buttons {
		width: 31rem;
		margin-left: 2rem;

		.nav-item {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 100%;

			a,
			button {
				display: flex;
				align-items: center;
				font-size: 2rem;
				flex-basis: 50%;
				padding: 0 0 0 2rem;
				white-space: nowrap;
				transition: font-size .4s ease-in-out;

				i {
					font-size: 2.666666667rem;
					transition: font-size .4s ease-in-out;
				}

				&:focus {
					background-color: transparent;
					color: #999999;
				}

				&:hover {
					font-size: 2.333333333rem;
					color: #0C3DFB;

					i {
						font-size: 3.333333333rem;
						color: #0c3dfb;
					}
				}
			}

			hr {
				width: 100%;
			}

			i {
				font-size: 2.5rem;
				color: #999999;
				margin-right: 1rem;
			}

			.settings-logout {
				height: auto;
				width: 100%;
				color: #999999;

				&:focus {
					background-color: transparent;
				}
			}
		}
	}
}
