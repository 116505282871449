@import '../../styles/constants.scss';
.listing {
	height: calc(100% - 14.16rem);
	position: relative;

	#angle {
		position: absolute;
		top: 0;
		left: 10.8333rem;
		z-index: 100;
		width: 4.7rem;
		fill: $color7;
	}
	.listing-container {
		width: 100%;
		position: relative;
		background-color: $color7;
		border-radius: 4.7rem 4.7rem 0 0;
		overflow: hidden;
		&::before, &::after  {
			content: "";
			position: absolute;
			height: 8.7rem;
			width: 8.7rem;
			border-radius: 5rem;
			bottom: 2px;
			left: 0;
			box-shadow: -3rem 3rem 0 $color7;
			z-index: 1;
		}
		&:after {
			left: auto;
			right: 0;
			box-shadow: 3rem 3rem 0 $color7;
		}
	}

	.wrapper {
		min-height: 100%;
	}

	.content {
		&.listing {
			display: flex;
			justify-content: center;

			.error {
				.scroll {
					border: 1px solid $color11 !important;
					border-radius: 0;
				}
			}

			.floatingLabelInput {
				.scroll {
					border-radius: 0;
				}
				.textarea-scroll {
					background-color: $color7;
				}
			}

			.listing-textarea {
				width: 100%;
				min-height: 15rem !important;
				padding: 1rem 2.33334rem;
				border-color: transparent;
				font-size: 2.3rem;
				background-color: $color7;
				color: $color15;
				outline: none;
				resize: none;
				overflow: hidden;
				box-sizing: border-box;
				//font-family: 'Lato', sans-serif;
				border-radius: 0;
				&::placeholder {
					color: $color14;
				}
			}

			.listing-project-overview.active,
			.listing-marketing.active {
				.form-wrapper {
					//padding-bottom: 0 !important;
				}
			}

			.column {
				max-width: 216.666rem;
				width: 100%;
				margin: 0 auto 10rem;

				.listing-form {
					display: block;
					width: 100%;

					.select-wrapper {
						margin-bottom: 0;
						font-size: 2.3rem;

						.select-input__multi-value__label {
							font-size: 2.33334rem;
						}

						.select-input__option {
							font-size: 2.3rem;
						}
					}

					&__head {
						height: 6.666rem;
						font-size: 3rem;
						color: $color15;
						line-height: 6.666rem;
						text-align: center;
						background-color: $color31;
					}

					&__body {
						padding: 9rem 5.666rem 0;
					}
				}

				.tittle {
					text-align: center;
					color: $color14;
					font-size: 3rem;
					margin-bottom: 3rem;
					padding: 0 33%;
					border-radius: 0;

					p {
						margin: 0;
						line-height: 1.46;
					}
				}

				.title-details {
					font-weight: 100;
					font-size: 2.33334rem;
					margin: 0 0 0.6rem;
					color: $color14;
				}

				.listing-details,
				.listing-project-overview,
				.listing-token,
				.listing-exchanges,
				.listing-community,
				.listing-marketing {
					position: relative;
					display: flex;
					flex-direction: column;
					width: 100%;
					margin-bottom: 3rem;
					border: 1px solid $color41;
					border-radius: 6rem;
					background: $color23;
					transition: all 0.3s ease;
					padding-left: 3rem;
					padding-right: 3rem;

					.description {
						width: 100%;
						position: relative;
						top: 3rem;
						line-height: 3rem;
						color: $color14;

						&.none {
							display: none;
						}
					}

					&.listing-details {
						&.active {
							.form-wrapper {
								height: auto;
								max-height: 16rem;
								.wrpField {
									padding: 0 0.2rem;
									.failed {
										margin-bottom: 0;
									}
								}
							}
						}
					}

					&.listing-marketing {
						.form-wrapper {
							.wrpField {
								padding: 3rem 0.2rem 0;

								.failed {
									width: 100%;
									margin-bottom: 0;

									.hidden-label {
										display: none !important;
									}
								}
							}
						}
					}

					&.listing-community {
						.form-wrapper {
							.wrpField {
								padding: 0 0.2rem;
								.failed {
									width: 100%;

									input {
										width: 100%;
										box-sizing: border-box;
										margin-bottom: 3rem;
									}
								}
							}
						}
					}

					&.textarea-token {
						.form-wrapper {
							.wrpField {
								padding: 0 0.2rem;
								.failed:nth-child(7) {
									width: 100%;
									box-sizing: border-box;
									.floatingLabelInput {
										position: relative;
									}
								}
							}
						}
					}

					&.textarea-overview {
						.form-wrapper {
							.wrpField {
								padding-top: 0.2rem;

								.failed:nth-child(4) {
									width: 100%;
									box-sizing: border-box;
									padding-bottom: 0 !important;

									.floatingLabelInput {
										position: relative;
									}
								}
							}
						}
					}

					.form-wrapper {
						overflow: hidden;
						transition: max-height 0.3s ease-out; //end animate
						height: auto;
						max-height: 0;
						animation-name: slideIn;

						.title-form {
							color: $color14;
							font-size: 2.3rem;
							margin: 0;
						}

						.wrpField {
							padding: 0 0.2rem;
							display: flex;
							flex-wrap: wrap;
							align-items: start;
							justify-content: space-between;
							.failed {
								&:last-child {
									margin-bottom: 0;
								}
							}

							&.checked-menu {
								justify-content: flex-start !important;
								padding: 2rem 0 0;
								.scroll {
									margin-top: 3rem;
									box-sizing: border-box;
								}
							}

							ul {
								width: 100%;
								margin: 0;
								padding: 0;

								li {
									list-style: none;

									.failed {
										padding-bottom: 0;

										&.error {

											select {
												border: 1px solid $color11 !important;
											}

											input {
												border: 1px solid $color11 !important;
											}

											.floatingLabelInput {
												input {
													border: 1px solid $color11 !important;
												}
												.select-input__control {
													border-color: red !important;
													border-bottom-color: red !important;
												}
											}

											.authorisation-error {
												color: $color11;
												font-size: 2.3rem;

											}
										}
									}

									input {
										width: 2rem;
										height: 2rem;
										outline: none;
										cursor: pointer;
									}

									.checkbox {
										//display: flex;
										//justify-content: flex-start !important;

										ul {
											li {
												display: flex;
												align-items: center;
												margin-bottom: 1rem;
												&:last-child {
													margin-bottom: 0;
												}
												label {
													font-size: 2.3rem;
													top: auto;
													left: auto;
												}

												.check-listing {
													display: flex;
													color: $color1;
													padding: 0;
													margin: 0.3rem 4.5rem 0 0;

													input[type='checkbox'] {
														display: flex;
														justify-content: center;
														align-items: center;
														width: 2.666rem;
														height: 2.666rem;
														-webkit-appearance: none;
														border: 1px solid
															$color14;
														border-radius: 3px;
														outline: none;
														line-height: 5rem;
														font-weight: 100;
														padding: 0;
														margin: 0;
														background: none;

														&:before {
															opacity: 0;
														}

														&:checked {
															border-color: $color1;
															color: $color1;

															&:before {
																color: $color1;
																font-size: 2rem;
																opacity: 1;
															}
														}
													}
												}
											}
										}
									}
								}
							}

							.failed {
								width: auto;
								margin-bottom: 5rem;

								&.error {
									.filter-select__control {
										border: 1px solid $color11 !important;;
									}

									input {
										border: 1px solid $color11 !important;
									}

									.floatingLabelInput {
										input {
											border: 1px solid $color11;
										}
									}

									.authorisation-error {
										color: $color11;
										font-size: 2.3rem;
										left: 1rem;
										margin-top: 0.3rem;
										white-space: nowrap;
										display: block;
									}
								}

								.double {
									margin-top: -3rem;
								}
							}

							label {
								position: relative;
								top: -0.7rem;
								left: 1rem;
								color: $color14;
								display: flex;
								font-size: 2.3rem;
								font-weight: 400;
								flex-direction: column;

								&.top {
									top: -3.1rem;
								}

								.description {
									position: absolute;
									margin: 0;
									top: 3rem;
									font-size: 2.3rem;
									font-weight: 400;
								}
							}

							input {
								background-color: $color7;
								color: $color15;
								font-size: 2.3rem;
								line-height: 3.5rem;
								//font-family: 'Lato', sans-serif;
								font-weight: 400;
								width: 59rem;
								height: 5.5rem;
								border: none;
								border-radius: 0;
								padding-left: 2.33334rem;
								outline: none;
								display: block;

								&::-webkit-input-placeholder {
									color: $color20;
									font-size: 2.3rem;
								}

								&::-moz-placeholder {
									color: $color20;
									font-size: 2.3rem;
								}

								&:-ms-input-placeholder {
									color: $color20;
									font-size: 2.3rem;
								}

								&:-moz-placeholder {
									color: $color20;
									font-size: 2.3rem;
								}

								&:-webkit-autofill,
								&:-webkit-autofill:hover,
								&:-webkit-autofill:focus {
									caret-color: $color20;
									//-webkit-box-shadow: 0 0 0 1000px $color7 inset;
									-webkit-text-fill-color: $color15;
									border: 0;
									transition: background-color 5000s
										ease-in-out 0s;
								}
							}
						}
					}

					&.active {
						width: 100%;
						display: flex;
						border: 1px solid $color41;
						transition: all 0.4s ease; //start animate
						animation-name: slideOn;
						border-radius: 6rem;
						padding-left: 3rem;
						padding-right: 3rem;

						.form-wrapper {
							display: block;
							justify-content: space-around;
							width: 100%;
							height: auto;
							max-height: 150rem;
							padding-bottom: 3rem;
							padding-top: 3rem;
						}

						.icon-wrap {
							display: flex;
							justify-content: center;
							align-items: center;
							margin: 0 auto;

							i {
								display: block;
								padding: 10px;
								transition: all 0.4s ease;
								transform: rotateZ(-180deg);
							}
						}
					}

					.title {
						display: flex;
						align-items: center;
						width: 100%;
						height: 9.8rem;
						cursor: pointer;
						font-weight: bold;
						border-radius: 6rem;

						span {
							font-size: 3rem;
							color: $color1;
						}

						.icon-wrap {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 5rem;
							height: 5rem;
							position: absolute;
							top: 0.2rem;
							right: 0;
							padding: 1.66rem;
							transition: all 0.3s ease;
							color: $color14;

							i {
								font-size: 3rem;
								position: absolute;
								top: 1.7rem;
								right: 2.2rem;
								padding: 1.66rem;
								display: block;
								transition: all 0.3s ease;
								color: $color14;
							}
						}
					}
				}

				.listing-exchanges {
					.form-wrapper {
						overflow: visible;

						.wrpField {
							padding: 0 0.2rem;
							margin-bottom: 0;

							.failed {
								margin-bottom: 5rem;
							}

							.failed:nth-child(1) {
								width: 30%;

								label {
									position: relative;
									left: 1rem;
								}

								.icon-single-down-pointing-angle {
									position: absolute;
									top: 5.7rem;
									cursor: pointer;
									z-index: 1;
								}

								&.error {
									.authorisation-error {
										color: $color11;
										font-size: 2.3rem;
										margin-top: 0.3rem;
										left: 1rem;
										position: absolute;
										font-weight: 400;
									}
								}
							}

							.failed:nth-child(2) {
								width: 65%;

								input {
									width: 100%;
									box-sizing: border-box;
								}
							}

							.failed:nth-child(3) {
								width: 100%;
								margin-top: -1.5rem;

								input {
									width: 100%;
									margin-bottom: 3rem;
									box-sizing: border-box;
								}
							}
						}
					}

					&.active {
						.form-wrapper {
							overflow: visible;

							.wrpField {
								padding: 0 0.2rem;
								.failed:nth-child(1) {
									label {
										position: relative;
										left: 1rem;
									}

									.css-d8oujb {
										display: none;
									}

									svg {
										display: none;
									}

									.css-1g6gooi {
										display: none;
										margin: 2px;
										padding-bottom: 2px;
										padding-top: 2px;
										visibility: visible;
										color: hsl(0, 0%, 20%);
										box-sizing: border-box;
									}

									.icon-single-down-pointing-angle {
										position: absolute;
										top: 5.7rem;
										cursor: pointer;
										z-index: 1;
									}

									.css-15k3avv {
										overflow-y: hidden;
									}

									.css-v73v8k {
										display: flex !important;
									}

									.css-wqgs6e {
										display: flex !important;
									}

									&.error {
										.authorisation-error {
											color: $color11;
											font-size: 2.3rem;
											margin-top: 0.3rem;
											left: 1rem;
											position: absolute;
										}
									}
								}
							}
						}
					}

					.form-wrapper {
						overflow: hidden;
					}
				}
				.submit-listing-btn {
					margin: 7rem auto 7rem;
					@extend .main-button;
				}
			}
		}

		.add-input-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 5.833rem;
			height: 5.833rem;
			background-color: $color5;
			color: $color20;
			cursor: pointer;
			border-radius: 3rem;

			&:hover {
				background: $color6;
			}

			&.btn-disable {
				pointer-events: none;
				i {
					color: $color39;
					font-size: 2.66rem;
				}
			}

			i {
				font-size: 3rem;
				color: $color15;
			}
		}

		@keyframes slideIn {
			from {
				height: 0;
			}

			to {
				height: auto;
			}
		}
	}
}

.listing-community {
	.failed {
		margin-bottom: 3rem;
	}
}

.listing .failed.error .icon-check-mark {
	border-color: $color11;
}

.failed.error .select-input__control {
	border-bottom-color: $color11 !important;
	.listing & {
		border-color: $color11 !important;
	}
}

.listing-exchanges .wrpField .failed:nth-child(3) {
	z-index: 1;
}

.listing .content.listing .column .listing-form .select-wrapper .select-input__option {
	position: relative;
	&::after {
		content: '';
		display: block;
		position: absolute;
		height: 1px;
		width: 93%;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		background-color: #3F4151;
	}
	&:last-child {
		&::after {
			display: none;
		}
	}
}
.screen3form,
.logged-in {
	height: 100%;
	.failed {
		input:not([type='checkbox']):not([disabled]), .scroll {
			transition: box-shadow .5s;
			border: none !important;
			&:-webkit-autofill {
				border: 1px solid $color7 !important;
				&:hover, &:focus {
					border: 1px solid #008BE8 !important;
				}
			}
			&:hover, &:focus {
				border: 1px solid #008BE8 !important;
			}
		}

		.scroll:focus-within {
			border: 1px solid #008BE8 !important;
		}
	}
}
.textarea-scroll {
	background-color: $color7;
	border-radius: 0;
}
.listing-textarea {
	width: 100%;
	min-height: 15rem !important;
	padding: 1rem 2.33334rem;
	border-color: transparent;
	font-size: 2.3rem;
	background-color: $color7;
	color: $color15;
	outline: none;
	resize: none;
	overflow: hidden;
	box-sizing: border-box;
	//font-family: 'Lato', sans-serif;
	border-radius: 0;
	&::placeholder {
		color: $color14;
	}
}

