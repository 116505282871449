@import '../../../../styles/constants.scss';

.DOM {
	height: 8rem;
	background-color: transparent;
}

.table-row {
	text-align: center;
	height: 4.17rem !important;
	padding-right: 0 !important;

	&:nth-child(odd) {
		background-color: $color7;
	}
}

.container-sell-expanded,
.container-buy-expanded {
	position: relative;
	min-height: 100%;
}

.button-component-dom {
	display: flex;
	width: 9rem;
	justify-content: space-between;
	position: absolute;
	top: 2rem;
	left: 25rem;
}

.column-count {
	display: flex;
	align-items: center;
	height: 7.5rem;
	margin-left: 2rem;
	i {
		margin: 0 20px 0 15px;
	}

	.icon-single-up-pointing-angle {
		color: $color-buy;
	}

	.icon-single-down-pointing-angle {
		color: $color-sell;
	}
}

.tabs-dom {
	margin-right: 2rem;
	line-height: 2.3;
	float: right;
	display: flex;
	align-items: center;
	svg {
		width: 16px;
		height: 11px;
		cursor: pointer;
	}

	.icon-history {
		margin-right: 1.3rem;
	}
	.tooltip-buy-one {
		display: none;
	}

	button {
		position: relative;
		margin-left: 4rem;
		justify-content: center;
		width: 3rem;
		display: flex;
		flex-direction: column;
		background-size: 100%;
		background-repeat: no-repeat;
		top: 0.2rem;
		align-items: center;
		transition: 0.3s all;
		height: 3rem;
		//&:nth-child(3) {
			.tooltip-buy-one {
				right: 0;
				left: auto;
				transform: none;
				margin-right: -2rem;
				&:after {
					left: auto;
					right: 3rem;
					transform: translateX(0) rotate(-135deg);
				}
			}
		//}
		.tooltip-buy-one {
			display: none;
			align-items: center;
			justify-content: center;
			bottom: 100%;
			margin-bottom: 2.5rem;
			left: 1.5rem;
			transform: translateX(-50%);
			visibility: visible;
			line-height: 3rem;
			width: fit-content;
			white-space: nowrap;
			padding: 0 2rem;
			height: 6rem;
			background-color: $color23;
			border-radius: 0;
			color: $color14;
			font-size: 2.3rem;
			position: absolute;
			z-index: 100;
			border: 1px solid $color1;
			box-shadow: 0 0 3rem $color66;
			text-align: center;
			vertical-align: center;
		}

		.tooltip-buy-one::after {
			content: ' ';
			position: absolute;
			top: 5.1rem;
			left: 50%;
			bottom: 100%;
			transform: translateX(-50%) rotate(-135deg);
			display: block;
			width: 2rem;
			height: 2rem;
			border: 1px solid $color1;
			border-bottom: none;
			border-right: none;
			background-color: $color23;
		}
		.tooltip-buy-one {
			right: 0;
			left: auto;
			transform: none;
			margin-right: -2rem;
			&:after {
				left: auto;
				right: 2.5rem;
				transform: translateX(0) rotate(-135deg);
			}
		}
		&:hover {
			i {
				color: $color1;
			}
			.tooltip-buy-one {
				display: flex;
			}
		}
		i {
			font-size: 3rem;
		}
		.icon-buy-up,
		.icon-buy-sell-up {
			color: $color8;
		}
		.icon-buy-sell-up {
			margin-bottom: -1.4rem;
		}
		.icon-buy-sell-down {
			margin-top: -1.4rem;
		}
		.icon-buy-sell-down,
		.icon-sell-down {
			color: $color11;
		}
		.order-form-wrap {
			position: absolute;
			width: 3rem;
			//height: 4rem;
			cursor: pointer;

			& + .disable {
				display: none;
			}
			.sell-one {
				&:hover {

				}
			}

			.sell-two {
				&:hover {
					.tooltip-buy-one {
						display: flex;
						align-items: center;
						justify-content: center;
						top: -8.5rem;
						left: 0.5rem;
						transform: translateX(-50%);
						visibility: visible;
						line-height: 3rem;

						width: fit-content;
						min-width: 15rem;
						height: 6rem;

						background-color: $color23;
						border-radius: 0;
						color: $color14;
						font-size: 2.3rem;
						position: absolute;
						z-index: 1;
						border: 1px solid $color1;
						box-shadow: 0 0 3rem $color66;
						text-align: center;
						vertical-align: center;
					}

					.tooltip-buy-one::after {
						content: ' ';
						position: absolute;
						top: 5.1rem;
						left: 52%;
						bottom: 100%;
						transform: translateX(-50%) rotate(-135deg);
						display: block;
						width: 2rem;
						height: 2rem;
						border: 1px solid $color1;
						border-bottom: none;
						border-right: none;
						background-color: $color23;
					}
				}
			}

			.sell-three {
				&:hover {
					.tooltip-buy-one {
						display: flex;
						align-items: center;
						justify-content: center;
						top: -8.5rem;
						left: -2.8rem;
						transform: translateX(-50%);
						visibility: visible;
						line-height: 3rem;

						width: fit-content;
						min-width: 15rem;
						height: 6rem;

						background-color: $color23;
						border-radius: 0;
						color: $color14;
						font-size: 2.3rem;
						position: absolute;
						z-index: 1;
						border: 1px solid $color1;
						box-shadow: 0 0 3rem $color66;
						text-align: center;
						vertical-align: center;
					}

					.tooltip-buy-one::after {
						content: ' ';
						position: absolute;
						top: 5.1rem;
						left: 75%;
						bottom: 100%;
						transform: translateX(-50%) rotate(-135deg);
						display: block;
						width: 2rem;
						height: 2rem;
						border: 1px solid $color1;
						border-bottom: none;
						border-right: none;
						background-color: $color23;
					}
				}
			}

			.wrap-data {
				cursor: pointer;
				width: 3rem;
				//height: 100%;
			}

			.wrap-sell-buy {
				position: relative;
				width: 3rem;
				height: 2.5rem;
				border: none;
				cursor: pointer;

				.wrap-data-buy {
					position: relative;
					cursor: pointer;
					top: 0.5rem;
					width: 3rem;
					height: 100%;

					span {
						transition: width 0.3s;
						background-color: #00B456;
						width: 1.4rem;
						float: right;
						height: 1px;
						border: 0;
						display: block;

						.triangle-top {
							transition: all 0.3s;
							position: absolute;
							left: 0;
							top: -0.3rem;
							width: 0;
							height: 0;
							border-left: 0.5rem solid transparent;
							border-right: 0.5rem solid transparent;
							border-bottom: 0.5rem solid #00B456;
							border-radius: 1px;
						}
					}
				}

				.wrap-data-sell {
					position: relative;
					cursor: pointer;
					top: -0.6rem;
					width: 3rem;
					//height: 100%;

					span {
						//position: relative;
						transition: width 0.3s;
						background-color: #FF4D4D;
						width: 1.4rem;
						float: right;
						height: 1px;
						border: 0;
						display: block;

						.triangle-down {
							transition: all 0.3s;
							position: absolute;
							left: 0;
							top: 0;
							width: 0;
							height: 0;
							border-left: 0.5rem solid transparent;
							border-right: 0.5rem solid transparent;
							border-top: 0.5rem solid #FF4D4D;
							border-radius: 18%;
						}
					}
				}

				&:hover {
					span {
						width: 1rem;

						.triangle-down {
							transform: scale(1.6, 1.6);
							transform-origin: top;
							left: 0.4rem;
						}

						.triangle-top {
							transform: scale(1.6, 1.8);
							transform-origin: bottom;
							left: 0.4rem;
						}
					}
				}
			}

			.wrap-sell {
				position: relative;
				width: 3rem;
				height: 2.5rem;
				border: none;
				cursor: pointer;

				span {
					//position: relative;
					transition: width 0.3s;
					background-color: #FF4D4D;
					width: 1.4rem;
					float: right;
					height: 1px;
					border: 0;
					display: block;

					.triangle-down {
						transition: all 0.3s;
						position: absolute;
						left: 0;
						top: 0;
						width: 0;
						height: 0;
						border-left: 0.5rem solid transparent;
						border-right: 0.5rem solid transparent;
						border-top: 0.5rem solid #FF4D4D;
						border-radius: 18%;
					}

					&::before {
						transition: all 0.3s;
						content: '';
						width: 100%;
						height: 1px;
						background-color: #5c687c;
						position: absolute;
						top: 0.7rem;
						right: 0;
						border-radius: 1px;
						border: 0;
					}

					&::after {
						transition: all 0.3s;
						content: '';
						position: absolute;
						top: 1.4rem;
						right: 0;
						width: 100%;
						height: 1px;
						background-color: #5c687c;
						border-radius: 1px;
						border: 0;
					}
				}

				&:hover {
					span {
						width: 1rem;
						background-color: #FF4D4D;

						&::before {
							width: 1.3rem;
							background-color: $color20;
						}

						&::after {
							width: 85%;
							background-color: $color20;
						}

						.triangle-down {
							transform: scale(1.6, 1.6);
							transform-origin: top;
							left: 0.4rem;
						}
					}
				}
			}

			.wrap-buy {
				position: relative;
				width: 3rem;
				height: 2.5rem;
				border: none;
				cursor: pointer;

				span {
					transition: width 0.3s;
					background-color: #5c687c;
					width: 100%;
					float: right;
					height: 1px;
					border: 0;
					display: block;

					&::before {
						transition: all 0.2s;
						content: '';

						width: 100%;
						height: 1px;
						background-color: #5c687c;
						position: absolute;
						top: 0.7rem;
						right: 0;
						border-radius: 1px;
					}

					&::after {
						transition: all 0.3s;
						content: '';
						position: absolute;
						top: 1.4rem;
						right: 0;
						width: 1.4rem;
						height: 1px;
						background-color: #00B456;
						border-radius: 1px;
					}

					.triangle-top {
						transition: all 0.3s;
						position: absolute;
						left: 0;
						top: 1.1rem;
						width: 0;
						height: 0;
						border-left: 0.5rem solid transparent;
						border-right: 0.5rem solid transparent;
						border-bottom: 0.5rem solid #00B456;
						border-radius: 1px;
					}
				}

				&:hover {
					span {
						width: 2.5rem;
						background-color: $color20;

						&::before {
							width: 1.3rem;
							background-color: $color20;
						}

						&::after {
							width: 1rem;
						}

						.triangle-top {
							transform: scale(1.6, 1.8);
							transform-origin: bottom;
							left: 0.4rem;
						}
					}
				}
			}
		}

		&.buy-one {
			&:hover {
				.tooltip-buy-one {
					display: flex;
					align-items: center;
					justify-content: center;
					top: -10rem;
					left: 1.5rem;
					transform: translateX(-50%);
					visibility: visible;
					line-height: 3rem;
					width: fit-content;
					white-space: nowrap;
					padding: 0 3rem;
					height: 6rem;
					background-color: $color23;
					color: #00124a;
					font-size: 2.3rem;
					position: absolute;
					z-index: 1;
					border: 1px solid $color1;
					box-shadow: 0 0 3rem $color66;
					text-align: center;
					vertical-align: center;
				}

				.tooltip-buy-one::after {
					content: ' ';
					position: absolute;
					top: 5.2rem;
					left: 50%;
					bottom: 100%;
					transform: translateX(-50%) rotate(-135deg);
					display: block;
					width: 2rem;
					height: 2rem;
					border: 1px solid $color1;
					border-bottom: none;
					border-right: none;
					background-color: $color23;
				}
			}
		}
	}
}

.header-default-dom {
	background: #0f1828 !important;
}

.DOM-table-buy {
	flex: 1;
	flex-shrink: 0;
	overflow: auto;
}

.DOM-table-sell {
	flex: 1;
	flex-shrink: 0;
	overflow: auto;
}

.DOM-table-buy-expanded,
.DOM-table-sell-expanded {
	position: relative;
	width: 50%;
	height: 100%;
	float: left;
	//background-color: $color30;
}

.container-buy {
	position: absolute;
	top: 1rem;
	left: 0;
	bottom: 0;

	.area {
		width: 100%;
	}
}

.container-sell {
	position: absolute;
	top: 1rem;
	right: 0;
	bottom: 0;

	.area {
		width: 100%;
	}
}

.container-buy-default {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	.area {
		height: 100%;
	}
}

.container-sell-default {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	.area {
		height: 100%;
	}
}

.container-flex {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

td,
th {
	padding: 0;
}

.container-table {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 5px;
	border-spacing: 0 1px;
}
.container-table-sell,
.container-table-buy {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 5px;
	border-spacing: 0 1px;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		@extend .supa-pupa-zebra;
	}
	.table-buy-grad {
		z-index: 3;
	}
	.table-buy {
		z-index: 4
	}
}
.container-table-expanded,
.table-sell {
	tr:nth-child(2n + 1) {
		background-color: transparent;
	}
}
.container-table-bottom {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 5px;
	border-spacing: 0 1px;
}

.container-table-expanded {
	position: absolute;
	top: 0.17rem;
	left: 0;
	width: 100%;
	padding: 5px;
	border-spacing: 0 1px;

	table {
		tr {
			&:hover {
				background-color: $color32;
				color: $color20 !important;
			}

			&:nth-child(2n + 1) {
				background-color: $color32;

				&:hover {
					background-color: $color33;
					//color: $color20;
				}
			}
		}
	}

}

.table-grad-zebra {
	tr {
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			height: 7.5rem;
			background-color: $color30;
			z-index: -1;
		}
		&:nth-child(2n + 1) {
			&:before {
				background-color: $color23;
			}
		}
		td {
			width: 100%;
		}
	}
}

.container-table-buy {
	&:before {
		background: repeating-linear-gradient(to top, $color30, $color30 7.5rem, transparent 7.5rem, transparent 15rem) !important;
	}
}

thead tr {
	height: 4.16rem;

	&:hover {
		color: $color65;
	}
}

tr {
	height: 7.5rem;
	cursor: default;

	&:hover {
		background: fade-out($color33, 0.3);
		//color: $color20;
	}

	&:last-child {
		margin-bottom: 1rem;
	}

	th {
		font-weight: normal;
	}

	&:nth-child(2n + 1) {
		&:hover {
			background: fade-out($color33, 0.85);
		}
	}
}

.table-buy-grad,
.table-sell-grad {
	position: relative;
	pointer-events: none;
}

.table-sell-grad {
	top: 0;
	z-index: 0;
}

.table-buy,
.table-sell {
	tr {
		&:hover {
			background: $color33;
			//color: $color20;

			.icon-currency-left {
				color: $color20;
			}

			.icon-currency-right {
				color: $color20;
			}
		}
	}
}

.table-sell {
	position: absolute;
	top: 0;
	z-index: 1;
}

.table-buy-container {
	.contents {
		display: flex;
		flex-direction: column;
		min-height: 100%;
	}

	.contents-reversed {
		flex-direction: column-reverse;
	}
}

.area-expanded-dom {
	width: 100%;
}

.contents-expanded-dom {
	height: 100%;
}

.dom-item {
	height: 62% !important;
}

.market-depth {
	display: flex;
	justify-content: space-between;
}
.currency-pair-modal2 {
	margin: 1.2rem 4rem !important;
}

.buy-last-char {
	color: $color56;
}

.sell-last-char {
	color: $color58;
}
