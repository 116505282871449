@import "../../styles/constants.scss";
@import "../../styles/theming.scss";

.tooltip {
	&__wrapper {
		position: relative;
		width: 2.4rem;
		height: 2.4rem;
	}

	&__body {
		position: absolute;
		width: auto;
		left: 50%;
		top: 6rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1.83rem 2rem 1.83rem 2rem;
		background-color: $color7;
		border: 0.16rem solid $color2;
		border-radius: 0;
		font-size: 2.3rem;
		box-shadow: 0 0 3rem $color46;
		transform: translateX(-50%);
		z-index: 10;

		&::after {
			content: " ";
			position: absolute;
			top: -1.1rem;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			display: block;
			width: 2rem;
			height: 2rem;
			border: 0.1rem solid $color2;
			border-bottom: none;
			border-right: none;
			background-color: $color7;
		}
	}

	&__text {
		white-space: nowrap;
		color: $color14;
	}
}
