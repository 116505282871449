@import '../../../../../../styles/constants.scss';
@import '../../../../../../styles/theming.scss';

.modal {
	.search-currency {
		.scrollarea {
			height: 99.5%;
		}
	}

	&.symbols {
		width: 140rem;
		height: 125.666rem;
		overflow: hidden;
		border: 1px solid rgba($color1, 0.5);

		table {
			table-layout: auto;
		}

		.symbols-modal-content {
			position: relative;
			display: flex;
			height: calc(100% - 51.333rem);
			flex-grow: 1;
			border-bottom: 1px solid $color17;
			border-top: 1px solid $color17;

			.currency-crypto {
				flex-grow: 1;
				width: 50%;
				position: relative;
				border-right: 1px solid $color17;

				&::before {
					content: '';
					position: absolute;
					left: 0;
					height: 100%;
					width: 100%;
					background-size: 100% 15rem;
				}

				&__title {
					height: 7.5rem;
					padding-left: 4rem;
					font-size: 2.333rem;
					color: $color65;
					display: flex;
					align-items: center;
				}

				.scrollarea {
					width: 100%;
					touch-action: none;
					height: 99.5%;
				}

				.scrollarea .scrollbar-container.vertical {
					z-index: 22 !important;
					touch-action: none;
				}

				ul {
					margin: 0;
					padding: 0;

					&::before {
						top: 7.5rem;
						content: '';
						position: absolute;
						left: 0;
						height: 100%;
						width: 100%;
						background-size: 100% 15rem;
						@extend .supa-pupa-zebra;
						z-index: 0;
					}

					li {
						position: relative;
						list-style: none;
						height: 7.5rem;
						color: $color14;
						cursor: pointer;

						&:hover {
							background: $color68 !important;
							span {
								color: $color14 !important;
							}
							i {
								color: $color1;
							}
						}

						button {
							width: 100%;
							height: 100%;
							text-align: left;
							padding-left: 13.333rem;
							font-size: 3rem;
							font-weight: normal;
							display: block;
							font-family: "Lato", Roboto, Arial, sans-serif;
							color: $color14;
							span {
								font-size: 2.333rem;
							}
						}

						i {
							position: absolute;
							top: 50%;
							left: 4rem;
							font-size: 3.5rem;
							transform: translateY(-50%);
							color: $color65;
							&:nth-child(3) {
								position: absolute;
								right: 3.333rem;
								left: auto;
								font-size: 2.2rem;
								opacity: 0;
							}
						}

						//&:hover {
						//	background-color: rgba(12,61,251,0.1);
						//	color: #0C3DFB;
						//
						//	i {
						//		color: #0C3DFB;
						//		opacity: 1;
						//	}
						//
						//	span {
						//		color: #0C3DFB;
						//	}
						//}
						&.selected {
							background: $color26;
							color: $color23;

							i {
								color: $color23;
								opacity: 1;
							}

							span {
								color: $color23 !important;
							}

							&:hover {
								background: $color26 !important;

								span {
									color: $color23 !important;
								}
							}
						}
					}
				}
			}

			.search-currency {
				position: relative;
				width: 50%;
				height: 100%;
				overflow: hidden;

				&::before {
					top: 7.5rem;
					content: '';
					position: absolute;
					left: 0;
					height: 100%;
					width: 100%;
					background-size: 100% 15rem;
					@extend .supa-pupa-zebra;
					z-index: 0;

				}

				thead {
					tr {
						background-color: transparent;

						&:hover {
							background-color: transparent;
							border: none !important;
							cursor: inherit !important;
							color: $color-symbol-modal;
						}
					}

					th {
						&:last-of-type {
							padding-right: 4rem;
						}
					}
				}

				tbody {
					tr {
						i {
							color: #999999;
						}

						&:hover {
							background: $color33;
							color: $color14 !important;
							cursor: pointer;
							i {
								color: $color1;
							}
						}

						&.active {
							background: $color26 !important;
							color: $color20;

							i {
								color: $color20;
							}

							&:hover {
								background: $color26 !important;
								color: #fff !important;
								i {
									color: #fff;
								}
							}
						}
					}

					td:nth-of-type(2) {
						width: 21%;
					}

					td:last-of-type {
						padding-right: 4rem;
					}
				}

				.symbols-pair-right {
					position: relative;
					width: 36%;
					text-align: center;
					padding-left: 1.8rem;

					.icon-currency {
						&.icon-currency-eur {
							background-size: cover;
							width: 3.5rem;
						}
						&.icon-currency-usd {
							background-size: cover;
							width: 3.5rem;
						}
					}

					.currency {
						font-size: 2.3rem;
					}
				}
			}

			.symbols-pair {
				position: relative;
				padding-left: 6rem;
				padding-right: 6rem;
				display: inline-block;

				i {
					position: absolute;
					font-size: 4rem;
					top: 50%;
					transform: translateY(-50%);

					&:first-of-type {
						left: 0;
					}

					&:last-of-type {
						right: 0;
					}
				}
			}
		}
	}
}

.symbols {
	box-sizing: border-box;
	&__footer {
		button {
			&:first-child {
				margin-right: 2rem;
			}
		}
	}
	tr {
		height: 7.5rem;
		font-size: 2.333rem;
	}

	.modal-header {
		margin-bottom: 4rem;
	}

	&__search {
		width: 100%;
		padding: 0 4rem;
		box-sizing: border-box;
	}

	&__info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 4rem;
	}

	&__info-item {
		width: 45.764%;
		min-height: 7.4rem;
		padding: 1.4rem 0;
		background-color: $color7;
	}

	&__info-title {
		margin: 0 0 2.2rem;
		line-height: 1;
		font-size: 2.333rem;
		color: $color1;
		text-align: center;
	}

	&__info-currency {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 3rem;
		font-weight: 500;
		color: $color14;

		span {
			margin: 0 1rem;
		}

		i {
			font-size: 2.7rem;
			color: #999;
		}
	}

	.icon-arrow-symbols {
		font-size: 8.8rem;
		color: $color43;
	}

	.filter {
		position: relative;
		margin-bottom: 4rem;

		input {
			color: $color14;

			&:hover, &:focus {
				box-shadow: 0 0 0 1px $color1;
			}
		}

		i {
			position: absolute;
			top: 50%;
			right: 1rem;
			transform: translate(-50%, -50%);
			color: $color65;
			font-size: 2.666rem;
			width: 2.666rem;
			height: 2.666rem;
			&.icon-close {
				color: #7d829c;
				width: auto;
				height: auto;
				cursor: pointer;
				&:hover {
					color: $color1;
				}
			}
		}

		&__item {
			padding: 0 5.5rem 0 3rem;
			width: 100%;
			height: 5.833rem;
			background-color: $color7;
			border: 0;
			box-sizing: border-box;
			font-size: 2.333rem;
			color: #00124a;
			display: block;

			&:focus {
				outline: none;
			}

			&::-webkit-input-placeholder {
				color: $color65;
			}
			&::-moz-placeholder {
				color: $color65;
			}
			&:-ms-input-placeholder {
				color: $color65;
			}
			&:-moz-placeholder {
				color: $color65;
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 16.3rem;
		.new--button {
			min-width: 20rem;

			&:first-child {
				margin-right: 4rem;
			}
		}
	}

	&__footer-cancel {
		margin-right: 4rem;
		background-color: #999999;

		&:hover {
			background-color: #8d98b9;
		}
	}

	&__footer-apply {
		background-color: $color1;

		&:hover {
			background-color: #2261ff;
		}
	}
}

@media screen and (max-width: 900px) {
	.modal {

		&.symbols {
			width: 95%;
			max-width: 200rem;
		}
	}
}
