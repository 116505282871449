$theme-light: (
	bg-color-nav: #e0d9cb,
	bg-color-header: #e0d9cb,
	bg-content: #999999,
	bg-body: #e0d9cb,
	panel-header-bg-color: #ebebeb,
	panel-bg-color: #e0d9cb,
	panel-header-color: #2c313d,
	hover-sidebar-color-icon: #0c3dfb,
	panel-bg-layout-menu-item: #f3f4f7,
	panel-color-menu-icon: #999999,
	layout-modal-color: #000,
	control-btn-color: #e0d9cb,
	control-btn-bg-color-active: #a5a5ad,
	control-btn-shadow: none,
	bg-color-filter: #a5a5ad,
	color-filter: #e0d9cb,
	icon-search-filter: #e0d9cb,
	bg-color-currency-pair: #ebebeb,
	color-currency-pair: #020d1d,
	bg-color-select: #ebebeb,
	color-select: #020d1d,
	bg-color-input-order: #ebebeb,
	color-input-order: #020d1d,
	border-color-input-order: 1px solid #ebebeb,
	color-button-open-order: #ffffff,
	color-select-placeholder: #000000,
	bg-color-select-menu-outer: #ffffff,
	color-select-noresults: #000000,
	color-button-deposit-header: #ffffff,
	color-deposit-state: #020d1d,
	bg-color-deposit-field: #eaeaea,
	bg-color-select-header: #ebebeb,
	color-wrapper-currency-placeholder: #020d1d,
	color-currency-value: #999999,
	color-currency-name: #2c313d,
	bg-color-focus-menu-button: #0c3dfb,
	color-focus-menu-button: #e0d9cb,
	bg-color-tooltip: #999999,
	border-color-tooltip: #999999,
	color-tooltip: #ffffff,
	bg-color-user-settings: #ebebeb,
	color-user-button-settings: #ffffff,
	bg-color-balance-currency: #ebebeb,
	color-balance-currency: #999999,
	border-balance-currency: #ebebeb,
	color-select-billing: #020d1d,
	color-select-arrow: #020d1d,
	bg-color-input-billing: #ebebeb,
	color-input-billing: #020d1d,
	bg-color-select-billing: #ebebeb,
	bg-color-select-checked: #999999,
	color-main-info: #54575a,
	opacity-img: 0.4,
	chat-date-color: #999999,
	chat-time-color: #999999,
	chat-text-color: #020d1d !important,
	chat-text-color-2: #020d1d,
	chat-icon-color: #ffffff,
	chat-bg-item-support: #cccccc,
	chat-bg-item-user: #e0d9cb,
	chat-bg-textarea: #ffffff !important,
	chat-color-textarea: #999999,
	color-add-comment: #020d1d,
	color-smile-icon: #ffffff,
	bg-color-item-ticket: #efefef,
	bg-color-status-ticket: #ffffff,
	bg-color-item-ticket-list: #ccc,
	color-title-ticket: #0e1e2a,
	color-subtitle-items: #79839c,
	color-info-ticket: #1b2432,
	hover-list-bgcolor: #eaeaea,
	hover-triangle-color: #0c3dfb,
	orange-title: #0c3dfb,
	active-list-bgcolor: #eaeaea,
	color-name-date-settings: #54575a,
	bg-color-input-settings: #ebebeb,
	header-color-modal: #ebebeb,
	color-tittle-modal: #020d1d,
	bg-color-modal: #e0d9cb,
	bg-input-dom-modal: #ebebeb,
	border-color-input-dom-modal: #ebebeb,
	color-input-dom-modal: #020d1d,
	bg-button-close-dom-modal: #a5a5ad,
	color-button-close-dom-modal: #ffffff,
	bg-color-child-symbol-modal: #ebebeb,
	bg-panel-body-content: #e0d9cb !important,
	bg-active-content: #e0d9cb !important,
	bg-tabs-billing: #e0d9cb !important,
	color-text-transaction: #54575a,
	color-text-transaction-date: #020d1d,
	color-text-transaction-deposit: #020d1d,
	color-withdraw: #0c3dfb,
	bg-color-li-transaction: #ebebeb,
	bg-color-li-transaction-2: #ccc,
	bg-color-export-date: #a5a5ad,
	color-export-date: #ffffff,
	color-text-account-deposit: #020d1d,
	svg-copy-icon: #020d1d,
	bg-scrollbar-thumb: rgba(193, 193, 193, 0.7),
	bg-scroll-bar-chat: rgba(193, 193, 193, 0),
	bg-scrollbar-thumb-chat: rgba(193, 193, 193, 0.3),
	bg-scrollbar-corner: #ffffff,
	bg-custom-amount: #eaeaea,
	bg-custom-amount-color: #7e8188,
	bg-color-chart-container: #e0d9cb,
	bg-color-chart-button: #ebebeb,
	bg-rc-dropdown-menu-item: #ccc,
	bg-rc-dropdown-menu-item-selected: #999999,
	color-rc-dropdown-menu-item: #2c313d !important,
	bg-rc-dropdown-menu-item-hover: #ffffff !important,
	reg-date-color: #999999,
	thead-bg-color: #ffffff,
	news-title-color: #020d1d,
	bg-color-menu-notification: #ffffff,
	color-menu-notification-tittle: #000000,
	border-color-menu-notification: #ebebeb,
	color-menu-notification: #000000,
	color-icon-close-menu-notification: #000000,
	bg-color-select-lang: #ffffff,
	color-select-lang: #999999,
	bg-color-select-lang-hover: #999999,
	color-select-lang-hover: #ffffff,
	bg-color-profile: #ffffff,
	reg-info-hover-color: #2c313d,
	reg-button-hover-color: #2c313d,
	color-separation-span: #e0d9cb,
	bg-color-input-modal-faq: #ebebeb,
	color-input-modal-faq: #000000,
	bg-color-title-ticket: #ebebeb,
	color-react-stockcharts-tooltip: #999999,
	bg-color-chart-preloader: #bfc3ce,
	bg-button-order-sell: #FF4D4D,
	bg-button-order: green,
	color-sell: #e0d9cb,
	color-button-order: #e0d9cb,
	color-sell-down: #e0d9cb,
	color-buy-up: #e0d9cb,
	bg-color-scroll: #bfc3ce !important
);
