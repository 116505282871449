@import "../../styles/constants.scss";
.modal-deposit {
	position: relative;
	top: 50%;
	left: 50%;
	right: auto;
	transform: translate(-50%, -50%);
	width: 95rem;
	transition-delay: 1s;
	outline: none;
	z-index: 10;

	.modal-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 6.6rem;
		background-color: #E3E5EE;

		h2 {
			padding-left: 3rem;
			text-transform: uppercase;
			font-size: 2.6rem;
		}

		button {
			width: 6rem;
			box-shadow: none;
			font-size: 2.6rem;
		}
	}

	.column {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		height: 31.33rem;

		.text-modal-deposit {
			display: flex;
			justify-content: space-between;
			width: 90%;
			color: $color14;
			h2 {
				font-size: 3rem;
				color: #00124a;
				line-height: 2;
				margin: 0;
			}

			span {
				font-size: 3rem;
				color: #999999;
			}
			div {
				width: 100%;
			}
		}
		.deposit {
			width: -moz-fit-content;
			width: fit-content;
			margin-left: 5rem;
			margin-top: -5rem;
			padding: 2rem 3rem 2rem 3rem;
			height: 2.5rem;
			background-color: #0c3dfb;
			color: #fff;
			text-transform: capitalize;
			font-size: 2.5rem;
			font-weight: normal;
			text-decoration: none;
			float: right;

			&:hover {
				background-color: #2261ff;
			}

			.icon-purse {
				margin-right: 1rem;
			}

			&.none {
				display: none;
			}
		}
	}
}
