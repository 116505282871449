//@import "../../../../styles/constants.scss";
//@import "../../../../styles/theming.scss";

.chart-container {
	position: relative;
	height: 100% !important;
	margin: 0 !important;
	overflow: hidden;
	background: $color23;

	.preloader {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&__option {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 8.666rem;
		padding: 2rem 2rem 0 2rem;
		z-index: 5;
		box-sizing: border-box;
		background: $color27;

		.chart-container__wrapper {
			display: flex;
		}
	}

	&__wrap {
		height: calc(100% - 8.5rem);
	}
}

.drop-settings {
	box-shadow: 0 0 0 transparent !important;
	border-color: transparent !important;

	@include theme-var(
		(
			background-color: transparent,
			border-color: transparent,
		)
	);

	.drop-profile.rc-dropdown-menu-item {
		width: 100%;
		height: 15rem;
		box-shadow: 0 0 3rem rgba(25, 54, 127, 0.5);
		background: $bg-rc-dropdown-menu-item;
		cursor: default !important;

		&::before {
			content: ' ';
			position: absolute;
			bottom: 100%;
			left: 82%;
			margin-left: -0.83rem;
			border-width: 0.83rem;
			border-style: solid;
			border-color: transparent transparent #0f1827 transparent;
		}

		@include theme-var(
			(
				background: bg-rc-dropdown-menu-item,
			)
		);

		.rc-dropdown-menu-item-active {
			width: 100%;
			background-color: $bg-rc-dropdown-menu-item !important;

			@include theme-var(
				(
					background-color: bg-rc-dropdown-menu-item,
				)
			);
		}

		.rc-dropdown-menu-item-selected {
			width: 100%;
			background-color: $bg-rc-dropdown-menu-item !important;

			@include theme-var(
				(
					background-color: bg-rc-dropdown-menu-item,
				)
			);
		}

		.profile-authorization {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
			font-size: 2rem;
			background-color: transparent;
			z-index: 1000;

			@include theme-var(
				(
					background-color: transparent,
				)
			);

			.initials {
				min-width: 10rem;
				min-height: 10rem;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #232733;
				margin-right: 4rem;
				font-size: 2.5rem;
				color: $white-color;
			}

			.reg-info {
				align-items: flex-start;
				line-height: 3rem;
			}

			.reg-info {
				align-items: flex-start;
				line-height: 3rem;

				div {
					span {
						display: block;
						color: $reg-date-color;
					}
				}
			}

			.reg-buttons {
				height: 100%;
				font-size: 1.5rem;
				margin-left: 0.5rem;

				.nav-item {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					align-items: flex-start;
					height: 100%;

					.settings-login,
					.settings-logout {
						margin: 0 0 0 5rem;
						font-size: 2rem;
						display: flex;
						align-items: center;

						&:hover {
							color: #fff;

							@include theme-var(
								(
									color: reg-button-hover-color,
								)
							);
						}

						i {
							position: absolute;
							left: -5rem;
							font-size: 2.5rem;
							padding: 5px;
							transition: all 0.3s;
						}
					}

					hr {
						width: 100%;
						color: $hr-color-line;
					}
				}
			}
		}
	}
}

.chart-container {
	position: relative;

	.interval-settings {
		display: flex;
	}
	.date-chart {
		font-weight: 400;
		width: 30rem;
		font-size: 1.4rem;
		height: 2rem;
		position: absolute;
		top: 1.8rem;
		left: 46rem;
		color: #999999;
	}

	.volume-chart {
		display: flex;
		font-size: 3rem;
		justify-content: flex-end;
		flex: 1;

		.volume-chart-value {
			text-align: right;
		}
		.volume {
			position: relative;
			color: $color1;
			margin-right: 0;
			&:first-child {
				margin-right: 4rem;
			}
			span:not(.volume-chart-value) {
				display: block;
				color: $color14;
				font-size: 2rem;
				white-space: nowrap;
				text-transform: uppercase;
				text-align: right;
			}
		}

		.growth {
			color: $color1;
			text-align: right;
			span:not(.volume-chart-value) {
				display: block;
				color: $color14;
				font-size: 2rem;
				white-space: nowrap;
				text-transform: uppercase;
			}
		}
	}

	.currency-pair--tools {
		margin: 0 2rem 0 0;
		height: 5.833rem;
		color: $color15;
		border: none;
		background: $color7;



		.icon-single-down-pointing-angle {
			margin-left: 2rem;
		}

		.name-currency {
			color: $color15;
		}

		.icon {
			background-size: cover;
			color: #999999;
			font-size: 2.6rem;
			width: 2.6rem;
			height: 2.6rem;
		}
		&:hover {
			//color: $color20;
			background: $color7;

			.name-currency {
				//color: $color20 !important;
			}

			.icon {
				color: $color1 !important;
			}
		}
	}

	.interval {
		background-color: #f5f5f5;
		width: 6.666rem;
		height: 100%;
		margin: 0 0.6rem;
		z-index: 2;
		position: relative;
		font-size: 2.3rem;
		color: #00124a;

		&::before {
			content: '';
			position: absolute;
			bottom: 0.7rem;
			left: 50%;
			transform: translateX(-50%);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 3px 3px 0 3px;
			border-color: #999999 transparent transparent transparent;
		}

		@include theme-var(
			(
				background-color: bg-color-chart-button,
			)
		);

		svg {
			width: 20px;
			height: 24px;
		}
	}

	// TODO: refactor with flex...
	height: calc(50% - 2rem);
	margin: 1rem;

	@include theme-var(
		(
			background: bg-color-chart-container,
		)
	);

	.react-stockchart > div {
		z-index: 0 !important;
	}
}

.react-stockcharts-tooltip {
	fill: #020d1d;
	z-index: 0;
	font-size: 2rem;
	font-weight: 500;

	@include theme-var(
		(
			fill: color-react-stockcharts-tooltip,
		)
	);
}
.react-stockcharts-tooltip-label {
	fill: $color-react-stockcharts-tooltip-label;
	z-index: 0;
	font-size: 2rem;
	font-weight: 500;
}

.react-stockchart > svg {
	z-index: 0 !important;
}

.react-stockcharts-avoid-interaction {
	pointer-events: none;
	stroke: $color-react-stockcharts-avoid-interaction;
}

//Preload
.pre {
	width: 5rem;
	height: 3rem;
	border: none;
	background: transparent;
	position: relative;
}

// Elements
.react-preloader {
	position: absolute;
	z-index: 10;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 30px;
	height: $preloader-size;
	margin: auto;
	font-size: 0;
	line-height: 0;
	transition: opacity 0.2s, visibility 0.2s;

	&::before,
	&::after {
		content: '';
	}
}

.react-preloader::before,
.react-preloader__inner,
.react-preloader::after {
	display: inline-block;
	vertical-align: middle;
	width: $preloader-size;
	height: $preloader-size;
	background: #0c3dfb;
}

.react-preloader::before {
	animation: preloader 1.2s linear infinite 0s;
}

.react-preloader__inner {
	margin: 0 $preloader-gaps;
	animation: preloader 1.2s linear infinite 0.1s;
}

.react-preloader::after {
	animation: preloader 1.2s linear infinite 0.2s;
}

// Sizes
//
.react-preloader--md {
	width: $preloader-width--md;
	height: $preloader-size--md;

	&::before,
	&::after,
	.react-preloader__inner {
		width: $preloader-size--md;
		height: $preloader-size--md;
	}

	.react-preloader__inner {
		margin-right: $preloader-gaps--md;
		margin-left: $preloader-gaps--md;
	}
}

.react-preloader--lg {
	width: $preloader-width--lg;
	height: $preloader-size--lg;

	&::before,
	&::after,
	.react-preloader__inner {
		width: $preloader-size--lg;
		height: $preloader-size--lg;
	}

	.react-preloader__inner {
		margin-right: $preloader-gaps--lg;
		margin-left: $preloader-gaps--lg;
	}
}

// Types
//
.react-preloader--static {
	position: relative;
}

// Colors
//
.react-preloader--primary {
	&::before,
	&::after,
	.react-preloader__inner {
		background: var(--brand-primary);
	}
}

// Animation
//
.preloader--fast {
	&::before {
		animation: preloader 1.5s linear infinite;
	}

	.react-preloader__inner {
		margin: 0 $preloader-gaps;
		animation: loader 1.5s linear infinite 0.1s;
	}

	&::after {
		animation: loader 1.5s linear infinite 0.3s;
	}
}

@keyframes preloader {
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(0.5);
	}

	75% {
		transform: scale(0.5);
	}

	100% {
		transform: scale(1);
	}
}

.preloader {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 2.6rem;
	color: #0c3dfb;
	position: absolute;
	top: 0;

	@include theme-var(
		(
			background-color: bg-color-chart-preloader,
		)
	);
}

.interval-settings {
	height: 100%;

	.drop-down {
		top: 8rem;
		left: 50%;
		transform: translateX(-50%);
		width: 16rem;
		background: $color23;
		color: $color15;
		text-align: center;
		font-size: 2.3rem;
		position: absolute;
		z-index: 3;
		box-shadow: 0 0 3rem $color66;
		border: 1px solid $color1;

		.drop-down-list-wrap {
			overflow: hidden;
			width: 100%;
			margin-top: 0;
			margin-bottom: 0;
		}

		.drop-down-list-wrap div:hover {
			background-color: $color69;

			&::before {
				display: none;
			}
		}

		&::before {
			content: ' ';
			position: absolute;
			top: -1.1rem;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			display: block;
			width: 2rem;
			height: 2rem;
			border: 0.1rem solid $color1;
			border-bottom: none;
			border-right: none;
			background-color: $color23;
			z-index: -1;
		}
	}
}
.interval-settings {
	.tooltip {
		box-shadow: 0 0 3rem $color46;
	}
}
.interval-wrapper {
	position: relative;
	width: auto;
	margin-right: 1rem !important;

	.interval {
		color: #999999;
		transition: color 0.3s, background 0.3s;

		&::before {
			transition: border 0.3s;
		}

		&:hover {
			color: #0c3dfb;

			&::before {
				border-color: #0c3dfb transparent transparent transparent;
			}
		}
	}

	.active-interval {
		color: $color1;
	}

	.drop-down {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 19.166rem;

		.drop-down-list-wrap div {
			position: relative;
			padding: 2rem;
			text-align: left;
			cursor: pointer;

			&:hover {
				position: relative;
				color: $color15;
				background: $color69;
				z-index: 1;
			}

			&:last-child {
				&:before {
					display: none;
				}
			}

			&::before {
				content: '';
				position: absolute;
				right: 2rem;
				bottom: 0;
				left: 2rem;
				height: 1px;
				background-color: $color7;
			}
		}
	}

	.tooltip {
		position: absolute;
		top: calc(100% + 2.666rem);
		left: 50%;
		transform: translate(-50%);
		max-width: 50rem;
		min-width: 20rem;
		border: 1px solid $color1;
		text-align: center;
		background-color: $color23;
		box-shadow: 0 0 3rem $color66;
		color: $color14;
		font-size: 2.333rem;
		padding: 1rem;
		box-sizing: border-box;
		word-wrap: break-word;
		opacity: 0;
		transition: opacity 0.3s;
		transition-delay: 0;
		pointer-events: none;

		&:after {
			content: ' ';
			position: absolute;
			top: -1rem;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			display: block;
			width: 2rem;
			height: 2rem;
			border: 1px solid $color1;
			border-bottom: none;
			border-right: none;
			background-color: $color23;
			clip-path: polygon(0 0, 0% 100%, 100% 0);
			z-index: 10;
		}
	}

	&:hover {
		.tooltip {
			opacity: 1;
			transition-delay: 1s;
		}
	}
}

.types-chart-wrapper {
	position: relative;
	width: auto;
	margin-right: 1rem !important;

	.drop-down {
		display: flex;
		justify-content: center;
		width: 33.666rem;

		.chart-type-active {
			color: $color1;
		}

		.drop-down-list-wrap div {
			position: relative;
			display: flex;
			align-items: center;
			height: 8rem;
			padding-left: 2rem;
			text-align-last: left;
			cursor: pointer;

			&::before {
				content: '';
				position: absolute;
				right: 2rem;
				bottom: 0;
				left: 2rem;
				height: 1px;
				background-color: $color7;
			}

			&:last-child {
				&:before {
					display: none;
				}
			}

			&:hover {
				color: $color15;
				background: $color69;
			}

			svg {
				width: 20px;
				height: 24px;
			}

			span {
				padding-left: 2rem;
			}

			.icon-line {
				height: 14px;
			}
		}
	}

	.types-chart {
		color: #999999;
		&:hover {
			color: #0c3dfb;

			&::before {
				border-color: #0c3dfb transparent transparent transparent;
			}
		}
	}

	.tooltip {
		position: absolute;
		top: calc(100% + 2.666rem);
		left: 50%;
		transform: translate(-50%);
		max-width: 50rem;
		min-width: 20rem;
		border: 1px solid $color1;
		text-align: center;
		background-color: $color23;
		color: $color14;
		font-size: 2.333rem;
		padding: 1rem;
		box-sizing: border-box;
		word-wrap: break-word;
		opacity: 0;
		transition: opacity 0.3s;
		transition-delay: 0;
		pointer-events: none;

		&:after {
			content: ' ';
			position: absolute;
			top: -1rem;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			display: block;
			width: 2rem;
			height: 2rem;
			border: 1px solid $color1;
			border-bottom: none;
			border-right: none;
			background-color: $color23;
			clip-path: polygon(0 0, 0% 100%, 100% 0);
			z-index: 10;
		}
	}

	&:hover {
		.tooltip {
			opacity: 1;
			transition-delay: 1s;
		}
	}
}

.chart-no-data {
	color: rgba(123, 132, 159, 0.2);
	text-transform: uppercase;
	font-size: 8rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -100%);
}

.cross-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5.833rem;
	width: 5.833rem;
	margin-right: 1rem;
	color: $color65;
	background: $color7;

	i {
		font-size: 4rem;
		transition: color 0.3s;
	}

	.tooltip {
		position: absolute;
		top: calc(100% + 2.666rem);
		left: 50%;
		transform: translate(-50%);
		max-width: 50rem;
		min-width: 20rem;
		border: 1px solid $color1;
		text-align: center;
		background-color: $color23;
		color: $color14;
		font-size: 2.333rem;
		padding: 1rem;
		box-sizing: border-box;
		word-wrap: break-word;
		opacity: 0;
		transition: opacity 0.3s;
		transition-delay: 0;
		pointer-events: none;

		&:after {
			content: ' ';
			position: absolute;
			top: -1rem;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			display: block;
			width: 2rem;
			height: 2rem;
			border: 1px solid $color1;
			border-bottom: none;
			border-right: none;
			background-color: $color23;
			clip-path: polygon(0 0, 0% 100%, 100% 0);
			z-index: 10;
		}
	}

	&:hover {
		color: $color1;

		.tooltip {
			opacity: 1;
			transition-delay: 1s;
		}
	}
}
