@import "../../../../../styles/constants.scss";
@import "../../../../../styles/theming.scss";

.header {
	.control {
		.header-col {
			margin: 0;
		}
		.deposit {
			width: 20rem;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			text-transform: capitalize;
			font-size: 3rem;
			line-height: 8rem;
			cursor: pointer;
			text-decoration: none;
			background-color: #33ccff;
			color: #fff;
			height: 14.16rem;
			padding: 0 2rem 0 5rem;
			font-weight: 400;
			//font-family: Arial;

			&:hover {
				background-color: #30dbf2;
			}

			@include theme-var(
				(
					color: color-button-deposit-header
				)
			);

			.icon-purse {
				margin-right: 2rem;
			}
		}
	}
}
