@import "../../styles/constants.scss";
@import "../../styles/theming.scss";
@import "../../styles/common-colors/common-colors.scss";

.currency-pair {
	position: relative;
	&--tools {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		text-transform: uppercase;
		background-color: $color7;
		border-radius: 0;
		color: $color14;
		font-size: 2.3rem;
		margin: 1.5rem;
		width: 100%;
		height: 4.5rem;
		padding: 0 0.5rem !important;

		@include theme-var(
		(
		background-color: bg-color-currency-pair,
		color: color-currency-pair
	)
	);

		&:hover {
			background-color: $color33;
			.icon, .icon-currency, .name-currency {
				color: $color20;
				transition: color .3s;
			}
			.icon {
				color: $color1;
			}
		}

		.name-currency {
			padding: 0 0.6rem;
			color: $color15;
		}

		.icon-single-down-pointing-angle {
			top: 1.4rem;
			font-size: 2rem;
			right: 0.5rem;
			color: $color-icon-arrow-right-currency-pair;
		}

		.icon {
			background-size: cover;
			color: $color-icon-arrow-right-currency-pair;
			font-size: 2.1rem;
			width: 2.1rem;
			height: 2.1rem;
		}

		.icon:nth-child(2n) {
			width: 3rem;
			height: 3rem;
		}

		&::after {
			background-color: $white-color;
		}
	}
}
