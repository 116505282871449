//BODY
$main-font-size: 6px;

//BG
$bg-body: #232733;
//$bg-color-header: #2c313d;
$bg-color-header: #232733;
//$bg-color-nav: #2c313d;
$bg-color-nav: #232733;
$bg-content: #020d1d;

$bg-color-chart-preloader: rgba(20, 22, 27, 0.65);

////BG WIDGET
//$bg-color-header-widget: #2c313d;
//$bg-color-symbol: rgba(44, 49, 61, 0.3);
//$bg-color-news: rgba(44, 49, 61, 0.3);
//$bg-color-chart: rgba(44, 49, 61, 0.3);
//$bg-color-chart-table: rgba(44, 49, 61, 0.3);
//$bg-color-market: rgba(44, 49, 61, 0.3);
//$bg-color-order-trades: rgba(44, 49, 61, 0.3);

//HEADER
$header-height: 8.5rem;

//HEADER-CURRENCY-PAIR
$bg-color-currency-pair: #3f4151;
$control-color-currency-pair: #999999;
$color-icon-arrow-right-currency-pair: #999999;
$color-currency-pair: #e0d9cb;
$font-size-span-currency-pair: 1.2rem;
$font-weight-span-currency-pair: 800;

//HEADER-OPEN-ORDER
$bg-color-button-open-order: #0c3dfb;
$color-button-open-order: #e0d9cb;

//HEADER-DATE
$color-date-header: #999999;
$color-time-header: #0c3dfb;
$font-size-date-header: 1.8rem;
$font-weight-date-header: 800;

//HEADER-DEPOSIT
$font-size-deposit-button-header: 1.9rem;
$line-height-deposit: 2;
$border-radius-deposit-header: 4rem;
$bg-color-button-deposit-header: #0c3dfb;
$color-button-deposit-header: #fff;
$height-button-deposit-header: 3.5rem;

//HEADER-MENU
$bg-color-focus-menu-button: #0c3dfb;
$color-focus-menu-button: #cccccc;
$bg-color-tooltip: #3f4151;
$color-tooltip: #cccccc;
$border-color-tooltip: #3f4151;

$bg-color-user-settings: #3f4151;
$color-user-button-settings: #999999;

//HEADER-SELECT
$bg-color-select-header: #3f4151;
$border-top-left-radius-select-header: 4rem;
$border-bottom-left-radius-select-header: 4rem;
$border-bottom-right-radius-select-header: 0;
$border-top-right-radius-select-header: 0;

$border-top-left-radius-demo-select-header: 0;
$border-bottom-left-radius-demo-select-header: 0;
$border-bottom-right-radius-demo-select-header: 4rem;
$border-top-right-radius-demo-select-header: 4rem;

$color-wrapper-currency-placeholder: #cccccc;
$color-currency-value: #999999;
$color-currency-name: #2c313d;
$color-select-placeholder: #aaa;

$bg-color-select-menu-outer: #020d1d;
$color-select-noresults: #999999;

//MODAL-OPEN-ORDER
$active-bg-tab-exchange-01: #55423e;
$active-bg-tab-exchange-02: #333742;
$border-color-chart: #2c313d;

//MODAL-FAQ
$bg-color-input-modal-faq: #020d1d;
$color-input-modal-faq: #cccccc;
$border-radius-input-modal: 2rem;
$border-height-input-modal: 0.5rem;
$color-active-tab: #cccccc;
$color-collapsible-modal-header: #e0d9cb;
$color-collapsible-trigger-modal-header: #999999;
$color-collapsible-content-inner-modal-header: #999999;
$color-border-collapsible-tuition: #cccccc;

//MODAL-NOTIFICATION
$bg-color-menu-notification: #020d1d;
$color-menu-notification-tittle: #cccccc;
$border-color-menu-notification: #2c313d;
$color-menu-notification: #cccccc;
$color-icon-close-menu-notification: #cccccc;
$color-tittle-li-menu-notification: #0c3dfb;

$bg-button-menu-notification: #2c313d;
$color-button-menu-notification: #cccccc;
$border-radius-button-menu-notification: 2rem;
$hover-border-radius-button-menu-notification: 2rem;
$bg-hover-button-menu-notification: #2c313d;
$color-hover-button-menu-notification: #cccccc;

$bg-color-profile: #0f1827;
$reg-info-hover-color: #e0d9cb;
$reg-button-hover-color: #ffffff;
$color-separation-span: #363945;

//MODAL-SYMBOL
$border-right-color-symbol-modal: #3a404d;
$color-symbol-modal: #7a8298;
$bg-color-child-symbol-modal: #F5F6F8;

//CHART
$bg-color-chart-container: rgba(45, 49, 61, 0.4);
$bg-color-chart-container-candle: rgba(45, 49, 61, 0.4);
$color-react-stockcharts-tooltip: #e0d9cb;
$color-react-stockcharts-tooltip-label: #767989;
$color-react-stockcharts-avoid-interaction: rgba(255, 255, 255, 0.3) !important;
$bg-color-chart-button: #3f4151;

//DOM
$color-icon-dom: #999999;

//DOM-MODAL-NOTIFICATION
$bg-button-close-dom-modal: #2c313d;
$color-button-close-dom-modal: #cccccc;
$bg-button-submit-dom-modal: orangered;
$color-text-price: #999999;
$color-input-dom-modal: #e0d9cb;
$bg-input-dom-modal: #3f4151;
$border-color-input-dom-modal: #3f4151;
$border-radius-input-dom-modal: 4rem;
$color-icon-dom-modal: #999999;
$border-shadow-notification: 0 0 10px 1px rgba(2, 13, 39, 0.5);

//SYMBOLS
$active-color-icon-star-symbols: orangered;

//ORDER-FORM
$bg-color-select: #3f4151;
$color-select: #cccccc;
$bg-color-input-order: #3f4151;
$color-input-order: #e0d9cb;
$border-color-input-order: 1px solid #3f4151;

$bg-button-order: transparent;
$color-button-order: #00B456;

$bg-button-order-sell: transparent;

//LOGIN-FORM
$color-icon-login: #999999;
$color-span-login: #999999;
$color-link-login: #999999;

//REGISTRATION-FORM
$color-span-registration: #999999;

//FONTS
$font-size-main: 1.6rem;
$font-family: "Lato", sans-serif;

//COLOR NEGATIV POSITIV
$color-buy: #00B456;
$color-sell: #FF4D4D;

$color-buy-up: #00B456;
$color-sell-down: #FF4D4D;

//SIDEBAR
$color-icon-sidebar: #999999;
$hover-sidebar-color-text: #e0d9cb;
$hover-sidebar-color-icon: #e0d9cb;
$focus-sidebar-item: #0c3dfb;
$active-sidebar-color-icon: #e0d9cb;

$bg-color-select-lang: #020d1d;
$color-select-lang: #999999;
$bg-color-select-lang-hover: #2c313d;
$color-select-lang-hover: #e0d9cb;

//PANEL
$panel-bg-color: rgba(44, 49, 61, 0.3);
//$panel-header-bg-color: #2c313d;
$panel-header-bg-color: #232733;
$panel-header-color: #e0d9cb;
$panel-bg-layout-menu-item: #020d1d;
$panel-color-menu-icon: #e0d9cb;

//LAYOUT MODAL
$layout-modal-color: #e0d9cb;

//BUTTON
$button-color: #999999;
$duration: 0.5s;
$transition-style: ease-in-out;

//TABLE
$table-color: #999999;
$table-row-bg-color: rgba(133, 150, 174, 0.1);
$thead-bg-color: #0f1828;

// TABLE OLD
$color-icon-arrow-down: #00B456;
$color-icon-arrow-top: #FF4D4D;
$color-icon-close: #999999;

// NEWS
$news-item-border-color: #333333;
$news-time-color: #ff6633;
$news-title-color: #dadada;
$news-description-color: #999999;

// CONTROLS
$control-btn-bg-color: transparent;
$control-btn-bg-color-active: linear-gradient(#55423e, #333742);
$control-btn-shadow: 0 0 10px 1px rgba(2, 13, 39, 0.5);
$control-btn-color: #ccc;
$control-btn-border-color: #999999;
$control-btn-border-color-active: transparent;
$control-btn-border-radius: 0;
$control-btn-font-size: 1.5rem;

//COLOR
$white-color: #e0d9cb;
$color-text-widget-header: #e0d9cb;
$orange: #0c3dfb;

//TicketsChat
$chat-time-color: #6d768f;
$chat-date-color: #6d768f;
$chat-user-color: #0c3dfb;
$chat-text-color: #e0d9cb;
$chat-text-color-2: #e0d9cb;
$chat-icon-color: #999999;
$title-chat-color: #e0d9cb;
$chat-bg-item-support: #232733;
$chat-bg-item-user: #0f1827;
$chat-bg-textarea: #2c313d;
$chat-color-textarea: #e0d9cb;
$color-add-comment: #999999;
$color-smile-icon: #999999;

//TICKETS
$bg-light: white;
$bg-color-item-ticket: #041522;
$bg-color-status-ticket: #151e2b;
$bg-color-item-ticket-list: #1b2432;
$color-title-ticket: #e0d9cb;
$color-subtitle-items: #00124A;
$color-info-ticket: #e0d9cb;
$tittle-color-text: #6d768f;
$pending-color: #ffe52c;
$rejected-color: #FF4D4D;
$processed-color: #00B456;
$find-color: #c8ddf5;
$hover-list-bgcolor: rgba(234, 100, 51, 0.2);
$active-list-bgcolor: rgba(234, 100, 51, 0.1);
$hover-triangle-color: #041522;
$hover-icon-look: #f2643f;
$hover-icon-delete: #041522;
$filter-selected-item: #0C3DFB;
$filter-input-placeholder-color: #8E9EBE;
$filter-input-placeholder-color-disabled: rgba(123, 132, 159, 0.5);
$filter-input-color: #00124A;
$filter-input-background: rgba(63, 65, 81, 0);
$filter-container-background: #F5F5F5;
$box-shadow-color: rgba(25, 54, 127, 0.5);
$active-ticket-title: #0C3DFB;
$selected-ticket-text-color: #fff;
$ticket-text-color: #FFFFFF;
$ticket-title-background: rgba(35, 39, 51, 0.3);
$ticket-chat-user-message-background: #F5F5F5;
$ticket-chat-user-no-avatar-background: #0D1D50;
$new-status-color: #33CCFF;
$date-picker-header: #fff;
$date-picker-table: #fff;
$date-picker-cell-value: #999999;
$date-picker-in-range-cell-background: rgba(51, 204, 255, 0.2);

//BILLING
$color-balance-currency: #e0d9cb;
$bg-color-balance-currency: #3f4151;
$border-balance-currency: #2c313d;
$color-select-billing: #e0d9cb;
$color-select-arrow: #e0d9cb;
$bg-color-input-billing: #2c313d;
$color-input-billing: #cccccc;
$bg-color-select-billing: #2c313d;
$bg-color-select-checked: #020d1d;
$color-purse-info: #999999;

$bg-panel-body-content: #0d1727 !important;
$bg-active-content: #0d1727 !important;
$bg-tabs-billing: #0d1727 !important;
$bg-color-li-transaction: transparent;
$bg-color-li-transaction-2: #2c313d;
$color-text-transaction: #999999;
$color-text-transaction-date: #e0d9cb;
$color-text-transaction-deposit: #999999;
$color-withdraw: #0c3dfb;
$color-history-export: #999999;
$color-deposit-state: #dddddd;
$bg-color-deposit-field: #2c313d;
$bg-color-export-date: #020d1d;
$color-export-date: #999999;

$opacity-img: 1;

$color-text-account-deposit: #999999;
$color-amount-money: #FF4D4D;
$color-personal-address: #999999;
$svg-copy-icon: #999999;

//SETTINGS
$color-date-registration: #999999;
$color-name-date-settings: #e0d9cb;
$bg-color-input-settings: #2c313d;
$color-label-settings: #999999;
$bg-color-button-settings: #0c3dfb;
$color-button-settings: #fff;

////FILTER
$color-filter: #cccccc;
$bg-color-filter: #020d1d;

//MODAL
$header-color-modal: #2c313d;
$bg-color-modal: #FFFFFF;
$color-tittle-modal: #e0d9cb;
$font-size-tittle-modal: 2.2rem;

$pretty--color-warning: orangered !important;

//PRELOAD
$preloader-size: 6px;
$preloader-gaps: 6px;
$preloader-width: ($preloader-size * 3) + ($preloader-gaps * 2);

$preloader-size--md: 8px;
$preloader-gaps--md: 8px;
$preloader-width--md: ($preloader-size--md * 3) + ($preloader-gaps--md * 2);

$preloader-size--lg: 16px;
$preloader-gaps--lg: 16px;
$preloader-width--lg: ($preloader-size--lg * 3) + ($preloader-gaps--lg * 2);

// TEMPORARY FILTER COLOR FOR DARK THEME
$bg-color-filter: #020d1d;
$color-filter: #cccccc;
$icon-search-filter: #cccccc;

// SCROll BAR COLOR
$bg-scrollbar-thumb: #2c313d;
$bg-scroll-bar-chat: rgba(0, 0, 0, 0);
$bg-scrollbar-thumb-chat: rgba(0, 0, 0, 0.3);
$bg-scrollbar-corner: #0f1828;

// DEPOSIT CUSTOM AMOUNT
$bg-custom-amount: #010d1e;
$bg-custom-amount-color: #e0d9cb;

// DEPOSIT ADDITIONAL INFORMATION
$color-main-info: #e0d9cb;
$color-additional-info: #999999;

$bg-color-title-ticket: #1b2432;

// DROPDOWN MENU ITEM
$bg-rc-dropdown-menu-item: #020d1d;
$bg-rc-dropdown-menu-item-selected: #020d1d;
$color-rc-dropdown-menu-item: #cccccc;
$bg-rc-dropdown-menu-item-hover: #2c313d;
$hr-color-line: #2c313d;
$reg-date-color: #999999;

//SCROLLBAR
$bg-color-scroll: #2b313e;

// Mixins
@function rem($size) {
	$remSize: $size / $main-font-size;
	@return #{$remSize}rem;
}
