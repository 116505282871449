.notifications-container {
	overflow: hidden;
	position: absolute;
	top: 14.16rem;
	right: 0;
	padding-bottom: 3.3rem;
	padding-left: 3.3rem;
	z-index: 9999;

	.notification-item {
		&:first-child {
			margin-top: 0.8rem;
		}

		&.hideAnimation {
			opacity: 1;
			animation:
				get-away .4s linear;
		}
	}

	.notification-item {
		position: relative;
		max-width: 45rem;
		margin-bottom: 0.8rem;
		margin-right: 0.8rem;
		padding: 2.5rem 3.3rem;
		background-color: $color7;
		border: 0.16rem solid $color1;
		opacity: 0;
		overflow: hidden;
		animation:
			fade-in .3s ease-out forwards,
			nudge .3s ease-out forwards;
		transition: .3s;

		.notification_title {
			margin-bottom: 1.6rem;
			padding-right: 3.3rem;
			font-size: 2.3rem;
			color: $color1;
			word-break: break-word;
		}
		.notification_body {
			font-size: 2.3rem;
			color: $color15;
			word-break: break-word;
		}

		.notification_close-btn {
			position: absolute;
			top: 2.3rem;
			right: 2rem;
			color: #999999;

			.icon-close {
				font-size: 2.3rem;
			}
		}

		@keyframes nudge {
			0% {
				transform: translate(0, 8.3rem);
			}

			100% {
				transform: translate(0, 0);
			}
		}

		@keyframes get-away {
			0% {
				transform: translate(0, 0);
			}

			100% {
				transform: translate(83rem, 0);
			}
		}

		@keyframes fade-in {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}
	}
}
