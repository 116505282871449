@import '../../../../../../styles/constants.scss';
@import '../../../../../../styles/theming.scss';

.layout {
	width: 119rem;
	height: 91rem;

	.layout-window {
		height: calc(100% - 6.5rem);
		overflow: hidden;

		.col-layout {
			display: inline-flex;
			flex-direction: column;
			margin-top: 4rem;

			&:nth-child(1) {
				padding-left: 3.5rem;
			}

			&:nth-child(2) {
				padding-left: 4rem;
				padding-right: 4rem;
			}

			.layout-default {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				width: 34.5rem;
				height: 30.3rem;
				border: 2px solid transparent;
				cursor: pointer;
				z-index: 1;
				transition: 0.3s;
				border-radius: 0;
				margin-top: 4rem;
				&:first-child {
					margin-top: 0;
				}

				&.active {
					width: 34.4rem;
					background: $color70;
					border: 2px solid $color1;
					.tittle {
						opacity: 1;
						color: $color1;
					}
				}

				&:hover {
					background: $color70;
					transition: 0.3s;

					.tittle {
						color: $color1;
						transition: 0.3s;
						opacity: 1;
					}
				}

				img {
					width: 26rem;
					height: 16.6rem;
					margin: 2rem 4rem 4rem;
					transition: 0.3s;
				}

				.col {
					width: 10rem;
					margin-right: 1rem;
				}

				.tittle {
					padding-top: 4rem;
					padding-left: 4rem;
					font-size: 3rem;
					color: $color14;
					transition: 0.3s;
					//opacity: 0.5;

					@include theme-var(
						(
							color: layout-modal-color,
						)
					);
				}
			}
		}

		.wrapper-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 4rem;
			.secondary-button {
				margin-right: 4rem;
			}
			.bubble {
				@extend .secondary-button;
				margin-right: 2rem;
			}

			.bubble:nth-child(2n) {
				@extend .main-button;
			}
		}
	}
}
