.session-modal,
.absolute-session-modal{
  &.modal {
    position: relative;
    top: 50%;
    left: 50%;
    right: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 133rem;
    height: 46rem;
    background-color: $color52;
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
    outline: none;
    z-index: 100;
    border: 1px solid $color1;
    box-shadow: 0 0 20px 5px $color66;
  }
}

