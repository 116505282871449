@import "../../../../../../styles/constants.scss";

.ReactModal__Overlay--after-open {
	background-color: rgba(0, 0, 0, 0.75)!important;
	z-index: 100 !important;
}

.ReactModal__Body--open .dashboard {
	filter: blur(2px);
	-webkit-filter: blur(2px);
}

.modal {
	&.order {
		max-width: 70rem !important;
		min-width: 33rem !important;

		.column {
			margin-top: 1.5rem;

			.chart-container {
				width: 47%;
				height: 100%;
				margin-top: -4rem;
				background-color: transparent;
				border: 0.4rem solid $color1;
				padding: 1rem;

				.react-stockchart {
					height: 185px !important;
				}

				.react-stockcharts-tooltip-hover {
					pointer-events: all;
					cursor: pointer;
					display: none;
				}
			}

			.order-exchange-component {
				margin-top: -1.5rem;
				.currency-pair--tools {
					width: 100%;
					background-color: $color7;
				}

				.order-form {
					padding-top: 1rem;
					.wrapper:first-child {
						input[type="text"] {
							width: 31rem;
						}
					}

					input[type="text"] {
						width: 100%;
					}
				}

				.order-form-buy,
				.order-form-sell {
					cursor: pointer;
				}

				.order-button {
					display: flex;
					justify-content: space-between;
					margin-top: 7rem;
					button {
						width: calc(50% - 1rem);
						height: 6rem;
						background-color: $color7;
						border: 1px solid $color8;
						color: $color8;
						&:hover {
							background: $color8;
							color: $color7;
							i {
								color: $color7;
							}
						}
						&:active {
							background: $color10;
							border-color: $color10;
						}
						&.red {
							border: 1px solid $color11;
							color: $color11;
							i {
								color: $color11;
							}
							&:hover {
								background: $color11;
								color: $color20;
								i {
									color: $color20;
								}
							}
							&:active {
								border-color: $color13;
								background: $color13;
							}
						}

						i {
							position: initial;
							padding-right: 1rem;
							font-size: 2rem;
							color: $color8;
							margin-right: 0;
						}
					}
				}
				.trading-instrument {
					padding-right: 0;
					select {
						width: 30.8rem;
						background-color: #f5f5f5;
					}
				}

				.tabs {
					position: relative;
					top: -9rem;
					left: -13.5rem;

					&:hover {
						color: #fff;
					}

					.active {
						background: -webkit-gradient(
							linear,
							left top,
							left bottom,
							from(#55423e),
							to(#333742)
						);
					}
				}
			}
		}
	}
}
