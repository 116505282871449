.wrapper-dropdown {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	&.nav-bar-language {
		padding-top: 3rem;
	}
	button {
		margin: 0 !important;
	}
}

.drop-menu {
	position: absolute;
	list-style: none;
	background-color: #0F1827;
	padding: 0;
	z-index: 1000;
	margin: 0;
	box-shadow: 0 0 20px rgba(234, 100, 51, 0.5);
	border: 1px solid #ea6433;

	&::before {
		content: '';
		position: absolute;
		width: 2.666666667rem;
		height: 2.666666667rem;
		clip-path: polygon(0 0, 0% 100%, 100% 0);
		border-left: 1px solid #ea6433;
		border-top: 1px solid #ea6433;
		background-color: #0F1827;
		transform: rotate(45deg);
		z-index: 1001;
	}

	&.tuition {
		top: 10rem;
		left: -4rem;
		width: 15rem;

		&::before {
			top: -1.3rem;
			left: 6rem;
		}

		a {
			display: flex;
			align-items: center;
			height: 7.5rem;
			font-size: 2.333333333rem;

			&:hover {
				color: #e0d9cb;
			}
		}
	}

	&.profile {
		top: 10rem;
		left: -59rem;

		&::before {
			top: -1.3rem;
			left: 61rem;
		}

		li {
			display: flex;
			padding: 4rem;

			i {
				padding: 0 2.666666667rem;
				font-size: 2.666666667rem;
			}

			span {
				white-space: nowrap;
			}
		}
	}

	&.chart {
		top: 6.5rem;
		left: -18.5rem;
		height: 26rem;
		width: 25rem;

		li {
			padding: 3.2rem;

			i {
				padding: 0;
			}
		}

		&::before {
			top: -1rem;
			left: 19.2rem;
		}
	}

	&.language {
		top: auto;
		left: 29rem;
		bottom: -6rem;
		text-align: left;
		transform: translateX(-50%);

		&::before {
			top: auto;
			bottom: 6rem;
			left: -1.4rem;
			transform: rotate(315deg);
		}

		li {
			display: flex;
			flex-direction: column;
			padding: 0;

			&:hover {
				background: none;
			}

			div {
				display: flex;
				flex-direction: row;
				width: 100%;
				height: 7.5rem;
				padding: 1rem 4rem;
				font-size: 3rem;
				box-sizing: border-box;

				&:first-of-type {
					border-top-left-radius: 3.333rem;
					border-top-right-radius: 3.333rem;

				}

				&:last-of-type {
					border-bottom-left-radius: 3.333rem;
					border-bottom-right-radius: 3.333rem;
				}

				img	{
					width: 3.666666667rem;
					height: 2.333333333rem;
				}

				span {
					width: 100%;
					padding-left: 2rem!important;
					margin-left: 0!important;
				}
			}
		}
	}

	&.login-dropdown {
		top: 0;
		bottom: auto;
		left: -18rem;
		transform: translateX(-50%);
		text-align: left;

		&::before {
			top: 4rem;
			right: -1.4rem;
			transform: rotate(135deg);
		}

		li {
			display: flex;
			flex-direction: column;
			padding: 0;

			&:hover {
				background: none;
			}

			div {
				display: flex;
				flex-direction: row;
				width: 100%;
				height: 7.5rem;
				padding: 1rem 4rem;
				font-size: 3rem;
				box-sizing: border-box;

				&:first-of-type {
					border-top-left-radius: 3.333rem;
					border-top-right-radius: 3.333rem;

				}

				&:last-of-type {
					border-bottom-left-radius: 3.333rem;
					border-bottom-right-radius: 3.333rem;
				}

				img	{
					width: 3.666666667rem;
					height: 2.333333333rem;
				}

				span {
					width: 100%;
					padding-left: 2rem!important;
					margin-left: 0!important;
				}
			}
		}
	}
}

.drop-flag img {
	width: 5rem;
}



.tooltip {
	z-index: 100;
	&.hidden {
		display: none;
		opacity: 0 !important;
	}
}

.drop-down {
	z-index: 101 !important;
}
