@import "constants.scss";
@import "theming.scss";
@import "./common-colors/common-colors";

.container {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	height: 100%;
	background: $color7;
}

.nav {
	width: 7.5rem !important;
	background-color: $bg-color-nav;

	@include theme-var(
		(
			background-color: bg-color-nav
		)
	);
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	flex-grow: 1;
	//padding: 2rem 1rem;
	background: #9E9EAC;//#020D1D;
	.dashboard & {

		overflow: hidden;
	}
}

.header {
	height: $header-height;
	background-color: #00124a;

	@include theme-var(
		(
			background-color: bg-color-header
		)
	);
}

.content {
	display: flex;
	align-items: stretch;
	flex-direction: row;
	flex-basis: auto;
	flex-grow: 1;
	padding: 2rem 1rem;
	background-color: #9E9EAC;

	@include theme-var(
		(
			background-color: bg-content
		)
	);
}

.mb-0 {
	margin-bottom: 0 !important;
}

.active-view {
	margin: 0 1rem;
}

.tickets .wrapper {
	padding: 2rem;
}
