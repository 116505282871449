@import '../../../../styles/constants.scss';
@import '../../../../styles/theming.scss';

.date-news-title {
	div {
		line-height: 0;
		font-size: 2rem;
		color: $color14;
		text-align: center;
		position: relative;
		margin-top: 3.3rem;
	}
}

.news-logo {
	display: flex;
	justify-content: center;

	img {
		position: absolute;
		top: 30%;
		width: 80%;
	}
}

a {
	text-decoration: none;
}

.news {
	font-size: 2.3rem;
	//font-family: $font-family;
	font-weight: normal;
	height: 100%;

	.news-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 14rem;
		overflow: hidden;

		&:hover {
			background: fade-out($color33, 0.85);

			.title,
			.description {
				//color: $color20;
			}
		}

		&:nth-child(2n + 1) {
			background-color: $color30;

			&:hover {
				background: fade-out($color33, 0.85);

				.title,
				.description {
					//color: $color20;
				}
			}
		}

		& > div {
			padding: 2rem;
		}

		.time {
			color: $color1;
		}
		.title {
			color: $color15;

			@include theme-var(
				(
					color: news-title-color,
				)
			);
		}
		.description {
			color: $color14;
		}
	}
}
