@import "../../../../../../styles/constants.scss";
@import "../../../../../../styles/theming.scss";

.panel {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	font-size: 12px;
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.16);
	background-color: $panel-bg-color;

	//&_symbols,
	//&_history,
	//&_orders,
	//&_market-trades,
	//&_bank-wire,
	&_billing_transaction,
	&_symbols-expanded,
	&_symbols-expanded-find {
		.panel-body-content {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				height: 100%;
				width: 100%;
				background-size: 100% 15rem;
			}
		}
	}

	&_bank-wire {
		.panel-body-content {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				background-size: 100% 15rem;
			}
		}
	}

	&_billing_transaction {
		.panel-body-content {
			position: relative;
			overflow: hidden;

			&::before {
				top: 15rem;
				content: '';
				position: absolute;
				left: 0;
				height: 100%;
				width: 100%;
				background-size: 100% 15rem;
			}
		}
	}

	&_news {
		.panel-body-content {
			position: relative;
			overflow: hidden;

			&::before {
				top: 6rem;
				content: '';
				position: absolute;
				left: 0;
				height: 100%;
				width: 100%;
				background-size: 100% 21rem;
			}
		}
	}

	&_history_expanded,
	&_orders_expanded {
		.panel-body-content {
			position: relative;
			overflow: hidden;

			&::before {
				content: '';
				position: absolute;
				top: 11rem;
				left: 0;
				height: 100%;
				width: 100%;
				background-size: 100% 15rem;
			}
		}
	}

	&_DOM-expanded {
		.panel-body-content {
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 8.3rem;
				height: 100%;
				width: 100%;
				background-size: 100% 15rem;
				pointer-events: none;

			}

			//&::after {
			//	content: '';
			//	position: absolute;
			//	left: 0;
			//	top: calc(100% + 7rem);
			//	height: 100%;
			//
			//	width: 100%;
			//	background: linear-gradient(to bottom, #F5F6F8 50%, #FFF 50%) repeat top;
			//	background-size: 100% 15rem;
			//	z-index: 0;
			//}
		}
	}

	&_DOM {
		.panel-body-content {
			.table-buy-container .container-table-buy {
				display: flex;
				flex-direction: column-reverse;
				min-height: 100%;
				padding: 0;
			}

			.table-sell-container .container-table-sell {
				min-height: 100%;
				padding: 0;
			}
		}
	}

	&_bank-wire {
		.panel-body-content {
			&::before {
				top: 15rem;
			}
		}
	}

	&_market-trades {
		.panel-body-content {
			position: relative;
			overflow: hidden;

			&::before {
				content: '';
				position: absolute;
				top: 8rem;
				left: 0;
				height: 100%;
				width: 100%;
				background-size: 100% 15rem;
			}
		}
	}

	&_symbols {
		.panel-body-content {
			position: relative;
			overflow: hidden;

			&::before {
				content: '';
				position: absolute;
				top: 12rem;
				left: 0;
				height: 100%;
				width: 100%;
				background-size: 100% 15rem;
				z-index: 0;
			}
		}
	}

	&_symbols-find {
		.container-table-symbols {
			position: relative;
			overflow: hidden;
			min-height: calc(100% - 10.5rem);

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				@extend .supa-pupa-zebra;
				background-size: 100% 15rem;
				z-index: 0;
			}
		}
	}


	&_symbols-expanded {
		.panel-body-content {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				top: 12rem;
				left: 0;
				height: 100%;
				width: 100%;
				@extend .supa-pupa-zebra;
				background-size: 100% 15rem;
				z-index: 0;
			}
		}
	}

	&_symbols-expanded-find {
		.panel-body-content {
			&::before {
				top: 17rem;
				content: '';
				position: absolute;
				left: 0;
				height: 100%;
				width: 100%;
				@extend .supa-pupa-zebra;
				background-size: 100% 15rem;
				z-index: 0;

			}
		}
	}



	.wrapper-dropdown {
		margin-right: 1rem;
	}

	.panel-header {
		display: flex;
		align-items: stretch;
		font-weight: bold;
		background-color: $color69;
		color: $color15;
		justify-content: space-between;
		height: 6.6rem;
		//z-index: 10;

		.panel-title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-grow: 1;
			font-size: 3rem;
			//font-family: $font-family;
			font-weight: 400;
			text-transform: uppercase;

			&.panel-title--center-align {
				text-align: center;
			}
		}

		.settings-panel {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			font-size: 1.6rem;
			line-height: 0;

			.button-menu-chart {
				position: relative !important;

				&:hover .menu-chart-layout,
				.menu-chart-layout:hover {
					display: flex;
				}
			}

			.menu-chart-layout {
				right: -6.7rem;
				top: 7rem;
				border-radius: 0;
				position: absolute;
				background-color: #fff;
				width: 27rem;
				height: auto;
				flex-direction: column;
				z-index: 100 !important;
				border: 0.16rem solid #0c3dfb;
				box-shadow: 0 0 3rem rgba(25, 54, 127, 0.5);
				color: #999999;

				&:before {
					content: "";
					display: block;
					position: absolute;
					top: -4rem;
					left: 41%;
					width: 8rem;
					height: 5rem;
				}

				.icon-chart-container {
					cursor: default;

					i {
						margin-right: 1rem;
						font-size: 3rem;
						cursor: pointer;

						&.active {
							color: #00124A;
						}
					}

					&.active {
						color: #00124A;
					}

					&:hover {
						border-radius: 0;
						color: #0C3DFB;
						background: rgba(12,61,251,0.1);

						& i {
							color: #0C3DFB;
							background: rgba(12,61,251,0.1);
						}
					}
				}

				&::after {
					content: " ";
					position: absolute;
					top: -1.3rem;
					left: 59%;
					-webkit-transform: translateX(-50%) rotate(45deg);
					transform: translateX(-50%) rotate(45deg);
					display: block;
					width: 2rem;
					height: 2rem;
					border: 0.1rem solid #0c3dfb;
					border-bottom: none;
					border-right: none;
					background-color: #fff;
				}
			}

			button {
				width: 4.5rem;
				height: 4.5rem;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $panel-color-menu-icon;
				margin-right: -0.5rem;

				&:focus {
					box-shadow: none;
				}

				&.active {
					box-shadow: none;
					background-color: #0c3dfb;

					i {
						color: #fff;
					}
				}

				@include theme-var(
					(
						color: panel-color-menu-icon
					)
				);

				&:hover {
					color: #fff;

					i {
						color: #0c3dfb;
					}

					@include theme-var(
						(
							background-color: panel-bg-layout-menu-item
						)
					);
				}
			}
		}
		.panel-button {
			margin-right: 2rem;
			margin-left: 0;
			box-shadow: none;

			i {
				font-size: 4rem;
			}

			.icon-expand {
				font-size: 2.6rem;
				color: #999999;
			}
		}
	}
	.panel-body {
		flex-grow: 1;
		position: relative;
		background-color: $color23;
		.panel-body-content {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			//overflow: hidden;
		}
	}
}
.menu-chart-layout {
	top: 5.4rem;
	left: 0;
	transform: translateX(-50%);
	border-radius: 0;
	position: absolute;
	background-color: #0f1827;
	width: 21rem;
	height: auto;
	display: flex;
	flex-direction: column;
	z-index: 100 !important;
	border: 0.16rem solid #0c3dfb;
	box-shadow: 0 0 3rem rgba(25, 54, 127, 0.5);
	color: #999999;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: -3rem;
		left: 50%;
		width: 5rem;
		height: 5rem;
	}

	&::after {
		content: " ";
		position: absolute;
		top: -1.1rem;
		left: 59%;
		-webkit-transform: translateX(-50%) rotate(45deg);
		transform: translateX(-50%) rotate(45deg);
		display: block;
		width: 2rem;
		height: 2rem;
		border: 0.1rem solid #0c3dfb;
		border-bottom: none;
		border-right: none;
		background-color: #0f1827;
	}

	@include theme-var(
		(
			background-color: panel-bg-layout-menu-item
		)
	);

	.icon-chart-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 2rem;
		height: 5rem;
		//font-family: Lato, sans-serif !important;
		font-size: 2.2rem;
		cursor: pointer;
		color: #999999;
		z-index: 10 !important;

		&:hover {
			background-color: rgba($color: #3f4151, $alpha: 0.3);
			&:first-child {
				border-radius: 4rem 4rem 0 0;
			}
			&:last-child {
				border-radius: 0 0 4rem 4rem;
			}
		}

		i {
			color: #999999;
		}
	}
}

.chart-icon {
	font-size: 2.3rem;
	color: #999999;
	font-weight: bold;
}

.purse {
	.panel-header {
		padding-left: 2rem;
	}
}

.button-menu-chart:hover,
.menu-chart-layout:hover {
	.menu-chart-layout {
		display: flex;
	}
}
