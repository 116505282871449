@import "../../styles/constants.scss";

#g-recaptcha {
	justify-content: center !important;
}

.confirm-wrapper {
	height: 100vh !important;
}

.registration {
	background: linear-gradient(to bottom, $color18, $color21);
	background: -moz-linear-gradient(to bottom, $color18, $color21);

	width: 100%;
	border-radius: 0;
	&.content {
		height: auto;
	}


	.g-recaptcha {
		display: flex;
		align-items: center;
		justify-content: center;

		& > div {
			margin-top: 6.5rem;
			& > div {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 13rem !important;
			}
		}

	}

	.select-wrapper {
		margin-bottom: 0;
	}

	.log-bg {
		position: fixed;
		transform: translate(0, -48%);
		top: 50%;
		pointer-events: none;

		&_left {
			left: 0;
			width: 106.666rem;
		}

		&_right {
			right: 0;
			width: 106.666rem;
		}
	}

	.content {
		display: block;
		padding: 0;
		//margin: 8rem 0;
	}

	.wrap {
		padding-top: 8rem;
		text-align: center;

		.lang {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 8.33rem;
			position: fixed;
			top: 1.6rem;
			right: 5rem;
			display: none;

			.translate-menu {
				&:after {
					content: none !important;
				}

				&.position-left {
					top: -1.3rem;
					left: -21rem;
					box-shadow: 0 0 3rem rgba(255,255,255,0.5) !important;

					.translate-menu__list {
						width: 100%;
						box-sizing: border-box;
					}

					&::after {
						content: "";
						display: block;
						position: absolute;
						width: 6rem;
						height: 17rem;
						top: 0;
						left: 95%;
					}

					&::before {
						content: " ";
						position: absolute;
						right: -1rem;
						top: 2.8rem;
						transform: translateY(0) rotate(225deg);
						border: 0.1rem solid $color1;
						width: 2rem;
						height: 2rem;
						border-top: none;
						border-right: none;
						background-color: #00124a;
						z-index: -1;
						left: auto;
					}
				}
			}

			i {
				position: absolute;
				width: 5rem;
				height: 5rem;
				background-size: 100%;
				top: 0;
				left: -1rem;
			}

			span {
				margin-left: 6rem;
			}
		}

		.reg-title {
			font-size: 3rem;
			text-align: center;
			font-weight: 400;
			color: $color15;
			margin: 8rem 0 4rem 0;
		}

		.text-indent-down {
			display: block;
			padding-bottom: 1rem;
			font-size: 2.3rem;
			color: $color-span-login;
			margin-bottom: 4rem;

			a {
				color: $color1;
				&:hover {
					color: $color2;
				}
			}

			i {
				padding-right: 1rem;
				font-size: 2.3rem;
			}
		}
	}

	.top-form {
		display: block;
		position: relative;
		margin: 0 auto 4rem;
		width: 70rem;
		background: transparent;

		.authorisation-error {
			left: 0;
			text-align: left;
			font-size: 2.3rem;
			margin-top: 0.5rem;
			color: #FF4D4D;
			text-transform: none;
		}

		.floatingLabelInput {
			display: flex;
			position: relative;
			flex-direction: column;
			width: 50rem;

			label {
				position: absolute;
				top: -2.1rem;
				left: 0;
				font-size: 2.3rem;
				color: #FF4D4D;
				transition: all 0.2s linear;
				opacity: 0;
				pointer-events: none;
				font-weight: 400;

				&.on {
					opacity: 1;
					color: #999999;
					font-size: 1.4rem;
				}
			}

			input {
				font-size: 2.8rem;
				background-color: transparent;
				color: #ffffff;
				width: 70rem;
				height: 3rem;
				outline: none;
				border-left: none;
				border-right: none;
				border-top: none;
				border-bottom: 0.16rem solid rgba($color: #999999, $alpha: 0.5);
				border-radius: 0 !important;

				::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					color: #999999;
				}
				::-moz-placeholder {
					/* Firefox 19+ */
					color: #999999;
				}
				:-ms-input-placeholder {
					/* IE 10+ */
					color: #999999;
				}
				:-moz-placeholder {
					/* Firefox 18- */
					color: #999999;
				}
				&:focus, &:hover {
					box-shadow: none !important;
				}
				&:focus {
					border-bottom-color: $color1;
					& +.on {
						color: $color1;
					}
				}
			}

			input::placeholder {
				color: #999999;
			}

			input,
			input:matches([type="password"], [type="search"]) {
				-webkit-appearance: textfield;
			}

			input:invalid {
				box-shadow: none;
			}

			input:-moz-submit-invalid {
				box-shadow: none;
			}

			input:-moz-ui-invalid {
				box-shadow: none;
			}

			input:-webkit-autofill {
				caret-color: white;
			}

			input:-webkit-autofill,
			textarea:-webkit-autofill,
			select:-webkit-autofill {
				//-webkit-box-shadow: 0 0 0 9999px rgba($color: #121A29, $alpha: 1) inset !important;
				-webkit-text-fill-color: #fff !important;
				border-color: #3a4772;
			}
		}

		.failed {
			position: relative;
			font-weight: bold;
			display: inline-block;
			margin: 2.3rem 0 2.3rem;
			&.hidden {
				display: none;
			}
			label {
				position: static;
				font-weight: 400;
			}

			.floatingLabelInput {
				width: 70rem;
				display: flex;
				justify-content: flex-start;
				margin: 0 auto;
			}

			&.error {
				label {
					font-size: 2.3rem;
				}

				input {
					border-bottom-color: #FF4D4D !important;
				}
			}
		}

		button {
			margin: 4rem auto;
			@extend .main-button;
		}

		span {
			display: block;
			text-align: center;
			font-size: 2.3rem;
			color: $color-span-login;

			.link-item {
				text-decoration: none;
				color: $color-link-login;

				&:hover {
					color: rgba(25,54,127,0.5);
				}
			}

			.privacy-link {
				color: $color1;

				&:hover {
					color: $color2;
				}
			}
		}

		.floating {
			height: 100%;
		}
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		border-radius: 0;
		font-size: 2.8rem;
		background: #00124a;
		background-image: none;
		color: #cccccc;
		width: 70rem;
		height: 4.1rem;
		outline: none;
		border-left: none;
		border-right: none;
		border-top: none;
		border-bottom: 0.16rem solid rgba($color: #999999, $alpha: 0.5);


	}

	.date-picker--value {
		display: flex;
		justify-content: space-between;

		.select-container {
			width: 30%;
			margin-right: 2.5rem;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.technologies {
		color: $color-link-login;
		text-align: center;
		padding: 0 2rem;
		font-size: 2.3rem;
		display: none;
	}

	.confirm-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		text-align: center;
		font-size: 2rem;
		line-height: 2;
		.confirm-wrapper-holder {
			min-height: 93rem;
			display: flex;
			flex-direction: column;
			a {
				display: flex;
				align-self: center;
				margin: 0;
			}
			.confirmation-text {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;

				a {
					display: inline-block;
				}
			}
		}
		img {
			width: 40rem;
		}

		a {
			margin: 10rem 0 15rem;
			color: $color1;

			&:hover {
				color: $color2;
			}
		}

		h1 {
			font-size: 4rem;
			color: $color15;
		}

		p {
			padding: 0 34%;
			color: #999999;
			font-size: 3rem;
		}
	}

}

.rc-anchor-normal {
	height: 12.3rem;
	width: 85.3rem !important;
}

.floating {
	margin-top: 2rem;
	.failed {
		label {
			position: absolute;
			font-size: 2.3rem;
			top: -3rem;
			color: #999999;
		}
	}
}

.registr-form {
	.rc-dropdown-menu-item {
		i {
			left: 3rem;
		}

		span {
			left: 10rem;
		}
	}
}

.top-form {
	.failed {
		.icon-single-down-pointing-angle {
			top: 1rem;
		}
	}
}

.confirmation-text {
	h1,
	h2 {
		font-size: 4rem !important;
		font-weight: 400;
		text-align: center;
		color: $color15 !important;
	}

	p {
		font-size: 3rem;
		text-align: center;
	}

	a {
		color: #33CCFF;

		&:hover {
			color: #56EBFF;
		}
	}
}

select[name="country_id"] + i {
	display: none;
}


.registration {

	.select-input__menu {
		border-radius: 0 0 3.333rem 3.333rem !important;
	}

	.floatingLabelInput {
		margin-top: 2.1rem !important;
		display: flex;
		position: relative;
		flex-direction: column;

		label {
			position: absolute !important;
			top: 0.4rem !important;
			left: 0 !important;
			transition: all 0.3s !important;
			color: $color14 !important;
			opacity: 1 !important;
			font-size: 2.666rem !important;
			line-height: 1 !important;

			&.on {
				top: -3.6rem !important;
				font-size: 2.333rem !important;
			}
		}

		input {
			font-size: 2.666rem !important;
			background-color: transparent !important;
			color: $color15 !important;
			height: 4.8rem !important;
			padding-left: 0 !important;
			outline: none !important;
			border: 0 !important;
			border-bottom: 1px solid $color5 !important;

			::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				color: $color14 !important;
			}
			::-moz-placeholder {
				/* Firefox 19+ */
				color: $color14 !important;
			}
			:-moz-placeholder {
				/* Firefox 18- */
				color: $color14 !important;
			}

			&:focus {
				border-bottom-color: $color1 !important;
				& +.on {
					color: $color1 !important;
				}
			}
		}


		input,
		input:matches([type="password"], [type="search"]) {
			-webkit-appearance: textfield;
		}

		input:invalid {
			box-shadow: none;
		}

		input:-moz-submit-invalid {
			box-shadow: none;
		}

		input:-moz-ui-invalid {
			box-shadow: none;
		}

		input:-webkit-autofill,
		textarea:-webkit-autofill,
		select:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 9999px transparent inset !important;
			-webkit-text-fill-color: $color15 !important;
			box-shadow: transparent 0 0 0 30px inset;
			caret-color: $color15;
			transition: background-color 5000s ease-in-out 0s;
		}
	}
}

@media (max-width: 700px) {
	.login-img:before,
	.login-img:after {
		display: none !important;
	}
}
