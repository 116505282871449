@import "../../styles/constants.scss";
@import "../../styles/theming.scss";

.ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
	margin-left: 2rem !important;
}

.ReactVirtualized__Table__headerRow {
	width: 285px !important;

	font-weight: 500;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: $table-color;

	font-size: 1.8rem;
	font-family: $font-family;
	text-align: center;

	text-transform: capitalize;

	&:nth-child(1) {
		padding-right: 0 !important;
	}
}

.ReactVirtualized__Table__rowColumn {
	font-weight: 100;
	text-transform: uppercase;
	display: flex;
	justify-content: space-between;
	align-items: right;
	font-size: 1.6rem;
	width: 100%;
	padding-left: 2rem;

	&:nth-child(3) {
		padding-left: 1rem;
	}
}

.ReactVirtualized__Table__headerTruncatedText {
	padding-right: 0;
}



//ReactVirtualized__Grid
//.ReactVirtualized__Table__Grid {
//	bottom: 6.25rem;
//	top: 0;
//	padding-bottom: 2.375rem;
//	outline: none;
//
//	&::-webkit-scrollbar {
//		width: 1rem;
//	}
//
//	&::-webkit-scrollbar-track {
//		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
//	}
//
//	&::-webkit-scrollbar-thumb {
//		background-color: $bg-scrollbar-thumb;
//		border: 1px solid transparent;
//		border-radius: 0.5rem;
//		outline: 1rem solid transparent;
//
//		@include theme-var ((
//			background-color: bg-scrollbar-thumb
//		));
//	}
//}

//.table {
//	//border: 0.1rem solid transparent;
//	color: $table-color;
//
//	font-size: 1.8rem;
//	font-family: $font-family;
//	text-align: center;
//	font-weight: 500;
//
//	.table-header {
//		text-transform: capitalize;
//		// text-align: center; // TODO: Check this rule later
//		font-weight: 500;
//
//		background-color: initial;
//		text-align: center;
//
//		i {
//			cursor: default;
//		}
//	}
//	.table-row {
//		text-align: center;
//		height: 4.17rem !important;
//		padding-right: 0 !important;
//
//		&:nth-child(odd) {
//			background-color: $table-row-bg-color;
//		}
//	}
//
//	& > .table-row:first-child {
//		background-color: initial;
//	}
//}
