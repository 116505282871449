@import "../../../../../styles/constants.scss";
@import "../../../../../styles/theming.scss";

.header {
	.menu {
		display: flex;
		align-self: flex-end;
		align-items: center;
		width: fit-content;

		justify-content: space-around;

		.notifications-available {
			&::before {
				content: "";
				position: absolute;
				background: #00e36a;
				display: block;
				width: 1rem;
				height: 1rem;
				top: 4.3rem;
				right: 5.3rem;
			}
		}

		button.active {
			background-color: $bg-color-focus-menu-button;
			color: #33ccff;
		}

		button {
			position: relative;
			cursor: pointer;
			width: 14.16rem;
			height: 14.16rem;
			text-align: center;
			border-radius: 0;
			font-size: 3.5rem;
			color: #fff;

			&:hover .icon-notifications,
			&:hover .icon-layout-chart,
			&:hover .icon-layout-dashboard,
			&:hover .icon-resize,
			&:hover .icon-full-size {
				color: #33ccff;
			}


			// &:hover,
			// &:active,
			// &:focus {
			// 	transition: all 0.4s;
			// 	background-color: $bg-color-focus-menu-button;
			// 	color: #33ccff;

			// 	@include theme-var(
			// 		(
			// 			background-color: bg-color-focus-menu-button,
			// 			color: color-focus-menu-button
			// 		)
			// 	);
			// }
			// &:focus {
			// 	background-color: $bg-color-focus-menu-button;
			// }
		}

		button {
			&.user-menu {
				&:hover {
					background-color: #33ccff;
					color: #00124a;

					@include theme-var(
						(
							color: color-user-button-settings
						)
					);
				}

				&:active {
					background-color: $bg-color-focus-menu-button;
				}

				&:focus {
					background-color: $bg-color-focus-menu-button;
				}
			}
		}
		div.currency-wrapper {
			position: relative;
		}

		button .tooltiptext,
		 div.currency-wrapper .tooltiptext,
		 div.wrap-balance .tooltiptext {
			 top: 14.16rem;
			 left: 50%;
			 transform: translateX(-50%);
			 visibility: hidden;
			 min-width: 12rem;
			 background-color: #fff;
			 color: #00124a;
			 text-align: center;
			 font-size: 2.3rem;
			 padding: 2rem;
			 position: absolute;
			 z-index: 1;
			 box-shadow: 0 0.5rem 2rem rgba(25, 54, 127, 0.5);
			 border: 0.1rem solid #0c3dfb;

			 @include theme-var(
											 (
															 background-color: bg-color-tooltip,
															 color: color-tooltip
											 )
			 );
		 }
		div.currency-wrapper .tooltiptext {
			top: 15.16rem;
		}

		button:hover .tooltiptext,
		div.currency-wrapper:hover .tooltiptext,
		div.wrap-balance:hover .tooltiptext {
			visibility: visible;
		}

		button .tooltiptext::after,
		div.currency-wrapper .tooltiptext::after,
		div.wrap-balance .tooltiptext::after {
			content: " ";
			position: absolute;
			top: -1.1rem;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			display: block;
			width: 2rem;
			height: 2rem;
			border: 0.1rem solid #0c3dfb;
			border-bottom: none;
			border-right: none;
			background-color: #fff;

			@include theme-var(
				(
					border-bottom-color: border-color-tooltip
				)
			);
		}

		i {
			font-size: 3.5rem;
			font-weight: 600;
			margin-left: -0.3rem;
		}
	}
	.hamburger {
		width: 14.16rem;
		height: 14.16rem;
		// background-color: #fff;
		display: flex;
		align-items: center;
		padding-left: 2.2rem;
		cursor: pointer;

		.hamburger__button {
			width: 6.33rem;
			height: 6.33rem;
			// background: red;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			span {
				width: 100%;
				height: 1.1rem;
				background: rgba($color: #fff, $alpha: 0.2);
				display: block;
				&::after {
					content: "";
					display: block;
					height: 100%;
					width: 18%;
					background-color: #fff;
					transition: width 0.6s ease;
				}
			}
		}
		&.open {
			.hamburger__button {
				span {
					&:after {
						width: 100%;
					}
				}
			}
		}
	}

	.my-profile {
		position: relative;
		width: 30rem;
		height: 14.16rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 3rem;
		cursor: pointer;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 150%;
			height: 250%;
			margin: -59% 0 0 -76%;
			background: linear-gradient(45deg, #0C3DFB, #22CDEE);
			transition: linear-gradient 0.3s;
			transform-origin: 50% 50%;
			animation: spin 3s linear infinite;
		}

		&:hover {
			&::before {
				background: linear-gradient(45deg, #2261ff, #30dbf2);
			}
		}

		i {
			position: relative;
			margin-right: 2rem;
			padding-top: 1rem;
			font-size: 4rem;
			z-index: 1;
		}

		span {
			position: relative;
			z-index: 1;
		}
	}
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
