.fullscreen {
	height: 100%;
}

:not(:root):-webkit-full-screen {
	object-fit: contain;
	position: absolute !important;
	top: 0px !important;
	right: 0px !important;
	bottom: 0px !important;
	left: 0px !important;
	box-sizing: border-box !important;
	min-width: 0px !important;
	max-width: none !important;
	min-height: 0px !important;
	max-height: none !important;
	width: 100% !important;
	height: 100% !important;
	transform: none !important;
	margin: 0px !important;
}

:not(:root):-webkit-full-screen::backdrop {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background: #2c313d !important;
}
