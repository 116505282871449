@import "../../../../../../styles/constants.scss";
@import "../../../../../../styles/theming.scss";

.notifications-wrap {
	position: relative;

	&.open-notifications-menu {
		.icon-bell {
			color: #33ccff;
		}

		.tooltiptext {
			display: none;
		}
	}

	.menu-notification {
		position: absolute;
		top: 14rem;
		right: 0;
		width: 57.5rem;
		height: 60.6rem;
		border-radius: 0;
		background-color: #fff;
		border: 0.1rem solid #0c3dfb;
		box-shadow: 0 0 3rem rgba(25, 54, 127, 0.5);
		z-index: 10000000;

		& ~ .notifications-available .tooltiptext {
			display: none;
		}

		ul span {
			font-size: 2.3rem;
			color: #999999;
		}

		@include theme-var(
				(
					background-color: bg-color-menu-notification
				)
		);

		.menu-notification-title {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 11.6rem;
			font-size: 3rem;
			text-align: center;
			font-weight: 500;
			color: #0C3DFB;
		}

		.notification_title {
			margin-bottom: 1.6rem;
			padding-right: 3.3rem;
			font-size: 2.3rem;
			color: #33CCFF;
			word-break: break-word;
		}

		.notification_body {
			font-size: 2.3rem;
			color: #00213D;
			padding-right: 1.6rem;
			word-break: break-word;
		}

		.dropdown {
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;
			color: $color-menu-notification;

			@include theme-var(
					(
						color: color-menu-notification
					)
			);

			li {
				position: relative;
				padding: 2rem 3rem;
				text-align: left;
				color: #00124a;

				&:hover {
					color: #0C3DFB;
					background: rgba(12,61,251,0.1);
					cursor: pointer;
				}

				&:hover i {
					color: #33CCFF;
				}

				&:hover:after {
					content: none;
				}

				@include theme-var(
						(
							border-bottom-color: border-color-menu-notification,
							color: color-menu-notification
						)
				);

				i {
					position: absolute;
					right: 4rem;
					top: 2rem;
					font-size: 2.3rem;
					color: #999999;

					@include theme-var(
							(
								color: color-icon-close-menu-notification
							)
					);
				}

				span {
					color: $color-tittle-li-menu-notification;
					display: block;
				}

				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					display: block;
					width: 88%;
					height: .2rem;
					background-color: #CCCCCC;
				}
			}

			.btn-notification {
				width: 16.6rem;
				height: 4.1rem;
				font-size: 2rem;
				background-color: #0C3DFB;
				color: #fff;
				margin: 3rem auto;

				&:hover {
					background-color: #2261ff !important;
				}

				&:active {
					background-color: $color-tittle-li-menu-notification !important;
					color: $color-hover-button-menu-notification !important;
				}

				&:focus {
					background-color: $color-tittle-li-menu-notification !important;
					color: $color-hover-button-menu-notification !important;
				}
			}
		}

		.notification__separator {
			display: flex;
			justify-content: center;

			&:after {
				content: "";
				width: 90%;
				height: 0.2rem;
				background-color: #CCCCCC;
			}
		}

		.notifications__empty {
			margin-top: 12rem;
			text-align: center;
			font-size: 2.3rem;
			color: #999999;
		}

		&:after {
			content: " ";
			position: absolute;
			top: -1.3rem;
			left: 88%;
			-webkit-transform: translateX(-50%) rotate(45deg);
			transform: translateX(-50%) rotate(45deg);
			display: block;
			width: 2rem;
			height: 2rem;
			border: 0.1rem solid #0c3dfb;
			border-bottom: none;
			border-right: none;
			background-color: #fff;
		}
	}
}

.header-scene {
	.menu-notification {
		&::after {
			left: 48%;
		}
	}
}

.scrollarea {
	width: 100%;
}

.cursor-pointer {
	cursor: pointer;
}
