@import '../../../../styles/constants.scss';

.currency-pair-modal {
	margin: 1.5rem 4rem;
	width: 23.666rem;
	position: relative;
	z-index: 19;
}

.market-trades {
	position: relative;
	top: -4.5rem;

	i {
		font-size: 1.3rem;
		font-weight: 800;
		position: relative;
		left: -1rem;
	}

	.cell-price-buy {
		color: $color-buy;
		text-align: left;
		padding: 1rem;
		overflow: visible !important;
	}

	.cell-price-sell {
		color: #FF4D4D;
		text-align: left;
		padding: 1rem;
	}
}

.table-row,
.table-row-tooltip {
	i {
		font-size: 1.3rem;
		font-weight: 800;
		position: absolute;
		left: 0;
		top: 3.3rem;
	}

	.cell-price-buy {
		position: relative;
		color: $color8;
		text-align: left;
		padding: 1rem 2.2rem 1rem;
	}

	.trades-cell-price-sell {
		position: relative;
		color: $color11;
		text-align: left;
		padding: 0 2.2rem 0;
		i {
			top: 1rem;
		}
	}

	.trades-cell-price-buy {
		position: relative;
		color: $color8;
		text-align: left;
		padding: 0 2.2rem 0;

		i {
			top: 1rem;
		}
	}

	.cell-price-sell {
		position: relative;
		color: $color54;
		text-align: left;
		padding: 1rem 2.2rem 1rem;
	}

	&.expand {
		i {
			font-size: 1.3rem;
			font-weight: 800;
			position: static;
			margin-right: 1rem;
		}
		td:nth-child(3) {
			text-align: center !important;
		}
	}
}

.market-table {
	tr {
		height: 7.5rem !important;
		padding-right: 0 !important;
		//background-color: $color32;
		color: $color14;

		&:hover {
			background: $color33 !important;
			//color: $color20;
		}

		&:nth-child(2n + 1) {
			background-color: $color30;
		}
	}
}
