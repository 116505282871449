@import "../../styles/constants.scss";
@import "../../styles/theming.scss";
@import "../../styles/common-colors/common-colors";

.billing-image {
	width: 100%;
	position: sticky;
	display: flex;
	height: 100%;
	bottom: 15rem;
	justify-content: flex-end;
	flex-direction: column;
	opacity: 0.4;
}

.light .billing-image {
	opacity: 0.1;
}
.empty-withdraw {
	text-align: center;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 4rem;
	font-size: 3rem;
	color: $color14;
}
.news-logo {
	&.history {
		position: absolute;
		top: 13%;
		width: 80%;
		left: 10%;
	}
}

.table-head {
	&.table-header-transaction {
		.head-transaction {
			&:hover {
				display: none;
			}
		}
	}
}
.DOM-table-sell-expanded,
.DOM-table-buy-expanded {
	.container-sell-expanded,
	.container-buy-expanded {
		&:before {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			top: 0;
			left: 0;
			@extend .supa-pupa-zebra;
		}
	}
}
.panel_symbols,
.panel_billing_transaction {
	.scrollbars-container {
		& > div:first-child:not(.track-vertical) {
			&:before {
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				top: 0;
				left: 0;
				@extend .supa-pupa-zebra;
			}
			table:not(.container-buy-expanded):not(.container-table-expanded) {
				position: relative;
			}
		}
	}
}
//.panel_orders_expanded,
//.search-currency,
//.currency-crypto,
//.panel_symbols-find,
//.DOM-table-sell-expanded,
//.DOM-table-buy-expanded {
//	.scrollbars-container {
//		& > div:first-child:not(.track-vertical) {
//			&:before {
//				content: '';
//				position: absolute;
//				right: 0;
//				bottom: 0;
//				top: 0;
//				left: 0;
//				@extend .supa-pupa-zebra;
//			}
//		}
//		table:nth-child(1) {
//			z-index: 1;
//			tr {
//				background: 0;
//			}
//		}
//	}
//}
//.panel_symbols-find {
//	.scrollbars-container {
//		& > div:first-child:not(.track-vertical) {
//			&:before {
//				top: 10rem;
//			}
//		}
//	}
//}
.billing {
	justify-content: center;
	height: calc(100% - 14.16rem);

	input:-webkit-autofill,
	textarea:-webkit-autofill,
	select:-webkit-autofill {
		box-shadow: 0 0 0 30px $color7 inset !important;
		-webkit-text-fill-color: $color14 !important;
		caret-color: $color14 !important;
		transition: background-color 5000s ease-in-out 0s !important;
	}


	.content {
		padding: 2rem;
	}

	.purse-content .scrollbars-container > div {
		&:before {
			background: repeating-linear-gradient($color30, $color30 7.5rem, transparent 7.5rem, transparent 15rem);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			content: '';
		}
		table {
			color: $color14;
			position: relative;
		}
	}

	.check-billing {
		margin-top: 5rem;
	}
	.transaction-amount {
		text-align: right;
		padding-right: 2.4rem;
	}
	.transaction-history {
		background-color: $color27;

		&:nth-child(2n+1) {
			background-color: $color30;
		}

		&:hover {
			background-color: $color33;
		}
		td:last-child {
			white-space: nowrap;
		}
	}


	.panel-body-content {
		&.purse-content {
			.wrapper-filter {
				position: initial !important;

                input {
                  //font-family: "Lato";
                  font-size: 2.3rem;
                }
				.icon-close {
					right: 4rem !important;
				}
				.icon-close:hover {
					color: $color1;
					cursor: pointer;
				}
			}
		}
		@include theme-var(
			(
				background-color: bg-panel-body-content
			)
		);
	}
	.active-content {
		height: auto;
		border-bottom-left-radius: 2rem;
		border-bottom-right-radius: 2rem;
		margin-top: 3rem;

		.table-header {
			margin-top: 1rem;

			th {
				text-align: left;
				font-weight: 400;
				&:nth-child(1) {
					width: 40%;
					padding-left: 5rem;
				}
				&:nth-child(2) {
					width: 20%;
					padding-left: 0.1rem;
				}
				&:nth-child(3) {
					width: 10%;
				}
			}
		}

		.scrollarea .scrollbar-container.vertical {
			z-index: 22 !important;
			touch-action: none;
		}

		.table-header-transaction {
			th {
				&:nth-child(1) {
					width: 20%;
					padding-left: 2.5rem;
				}
				&:nth-child(2) {
					word-wrap: break-word;
					width: 20%;
					padding: 0;
					padding-left: 1.5rem;
				}
				&:nth-child(3) {
					width: 15%;
					padding-left: 0;
				}
				&:nth-child(5) {
					text-align: right;
					padding-right: 2.4rem;
				}
			}
		}
		.container-table-history {
			position: relative;
			left: 0;
			text-align: left;
			width: 100%;
			padding: 5px;
			border-spacing: 0 1px;
			color: $color-text-transaction;
			@include theme-var(
				(
					color: color-text-transaction
				)
			);

			td {
				white-space: normal;
				text-align: left;
				&:nth-child(1) {
					width: 40%;
					padding-left: 5rem;
				}
				&:nth-child(2) {
					width: 20%;
					padding-left: 0.1rem;
					color: #e0d9cb;
				}
			}
		}
		.transaction-table-history {
			td {
				white-space: normal;
				span.green {
					color: #00b456;
				}
				span.red {
					color: #FF4D4D;
				}

				&:nth-child(1) {
					width: 20%;
					padding-left: 2.4rem;
				}
				&:nth-child(2) {
					word-wrap: break-word;
					width: 20%;
					padding-left: 1.5rem;
				}
				&:nth-child(3) {
					padding-right: 0;
					padding-left: -0.5rem;
					width: 15%;
				}
				&:nth-child(4) {
				}
				&:nth-child(5) {
					text-align: center;
				}
			}
		}
		form {
			&.form-billing {
				padding-left: 20%;
				padding-right: 20%;

				.failed {
					display: flex;
					flex-direction: column;
					.no-money {
						color: $color11;
						font-size: 2.3rem;
						margin-top: -1rem;
						text-transform: none;
						margin-left: 2rem;
					}
					&.error {
						select {
							border: 0.1rem solid $color11;
						}
						input {
							border: 0.1rem solid $color11;
						}
						.floatingLabelInput {
							input {
								border: 0.1rem solid $color11;
								font-size: 2.3rem;
							}
						}
						.authorisation-error {
							color: $color11;
							font-size: 2.3rem;
							margin-top: -1rem;
							text-transform: none;
							margin-left: 2rem;
						}
					}
					select {
						-webkit-appearance: none;
						-moz-appearance: none;
						outline: 0;
						height: 4.1rem;
						box-shadow: none;
						border: 0;
						border-radius: 0;
						background: #f5f5f5;
						background-image: none;
						color: #00124a;
						padding-left: 2rem;
						padding-bottom: 0;
						margin-bottom: 1rem;
						cursor: pointer;
						z-index: 10;
						outline: none;
						font-size: 2.3rem;
						line-height: 4rem;

						@include theme-var(
							(
								background-color: bg-color-input-settings
							)
						);
					}
					input {
						height: 4.1rem;
						margin-bottom: 1rem;
						padding: 0 2rem;
						background-color: #f5f5f5;
						border: none;
						border-radius: 0;
						outline: none;
						color: #00124a;
						font-size: 2.3rem;
						line-height: 4rem;

						@include theme-var(
							(
								background-color: bg-color-input-settings
							)
						);
					}
					label {
						display: block;
						font-size: 2.3rem;
						color: $color65;
						z-index: 10;
						margin-left: 2rem;
						margin-bottom: 1rem;
					}
				}
				.floatingLabelInput {
					display: flex;
					flex-direction: column;
					z-index: 10;
					input {
						height: 4rem;
						margin-bottom: 1rem;
						background-color: $color7;
						border: none;
						border-radius: 0;
						outline: none;
						color: $color14;
						line-height: 4rem;
						font-size: 2.3rem;

						@include theme-var(
							(
								background-color: bg-color-input-settings
							)
						);
					}
					label {
						font-size: 2.3rem;
						color: $color14;
					}
				}
			}
		}
		@include theme-var(
			(
				background-color: bg-active-content
			)
		);
	}
	@keyframes expand{
		0%{
			bottom: 50%;
			opacity: 0;
		}
		100%{
			bottom: 100%;
			opacity: 1;
		}
	}
	.account-deposit {
		margin: 0 2rem;
		color: $color-text-account-deposit;
		.copied {
			position: absolute;
			bottom: 100%;
			left: 50%;
			transform: translateX(-50%);
			text-transform: capitalize;
			animation: expand 3s ease;
		}
		@include theme-var(
			(
				color: color-text-account-deposit
			)
		);
		div {
			font-size: 2.35rem;
			&:first-of-type {
				margin: 2rem 0;
				span {
					color: $color1;
				}
			}
			&:nth-of-type(2) {
				color: $color-personal-address;
			}
			&:nth-of-type(3) {
				height: 4rem;
				background-color: $color7;
				border: 1px solid $color7;;
				padding: 1rem 2rem;
				margin: 2rem 0;
				border-radius: 0;
				color: $color20;
				line-height: 4rem;
				font-size: 2.7rem;
				display: flex;
				justify-content: space-between;
				&:hover {
					border-color: $color1;
				}
				@include theme-var(
					(
						color: color-text-account-deposit
					)
				);
			}
		}
		.copy-button {
			align-self: center;
			min-width: 3rem;
			width: 3rem;
			height: 3rem;
			position: relative;
			&:hover {
				.copy-svg {
					fill: $color1;
				}
			}
			.copy-svg {
				fill: $svg-copy-icon;

				@include theme-var(
					(
						fill: svg-copy-icon
					)
				);
			}
		}
	}
	.tabs-billing {
		@include theme-var(
			(
				background-color: bg-tabs-billing
			)
		);
	}
	.column {
		display: flex;
		width: 30%;


		.panel-header {
			padding-left: 2rem;
			background-color: $color69;
			color: $color15;
		}

		.panel-body {
			background-color: $color27;
		}

		&.purse {
			width: 75%;
			margin-right: 2rem;
			overflow: hidden;
		}
		.confirm.disable {
			cursor: default;
			background-color: $color5;
			color: $color39;

			&:hover {
				 background-color: $color5;
			 }

			&:active:after {
				display: none;
			}
		}
		.confirm {
			margin: 8rem auto;
			@extend .main-button;
		}
	}
	.incorporated {
		color: $color-personal-address;
		font-size: 2.3rem;
		padding: 0 1rem;
	}
}
.check-billing {
	font-size: 2.3rem;
	padding: 0 2rem;
	color: $color14;

	a {
		color: $color1;

		&:hover {
			color: $color2;
		}
	}

	input[type="checkbox"] {
		-webkit-appearance: none;
		position: relative;
		display: inline-block;
		padding: 1.2rem;
		background-color: $color25;
		border: 1px solid $color2;
		margin: 1rem 1rem 0 0;
		top: 0.5rem;
		outline: none;
		line-height: 5rem;
		font-weight: 400;

		&:hover {
			cursor: pointer;
		}

		@include theme-var(
			(
				background-color: bg-color-input-billing
			)
		);
		&:checked {
			background-color: transparent;
			border: 0.16rem solid $color1 !important;
			color: $color2;

			@include theme-var((
				background-color: bg-color-input-billing
			));

			&:after {
                font-family: 'iconfont' !important;
                content: '\e94b';
                position: absolute;
				top: -1.2rem;
				left: 1px;
				color: $color2;
				bottom: 0;
				font-size: 2rem;
			}
		}
	}
}

.not-purse {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
	text-align: center;
	position: absolute;
	top: -7rem;
	left: 0;
	right: 0;
	bottom: 0;
	background: $color27;
	z-index: 99;

	h2 {
		font-size: 3rem;
		color: $color15;
		width: 85rem;
		height: 10rem;
	}

	p {
		font-size: 2rem;
		color: $color14;
	}

	.wrap-logo {
		width: 85rem;
		height: 30rem;

		img {
			width: 100%;
			height: 100%;
		}
	}
}

.percent-calc {
	margin: 20px 0;

	span {
		color: $color1;
	}
}

.purse-content {
	.wrapper-filter {
		input {
			margin: 2rem;
			width: 52rem;
			height: 4.1rem;
			background-color: $color7;
			border-radius: 0;
			color: $color14;
			float: right;
		}
		i {
			top: 3.2rem;
			color: $color65;
		}
		.icon-close {
			right: 7rem;
			&:hover {
			color: $color1;
				cursor: pointer;
			}
		}
	}

	.ban-wire__item {
		color: $color14;
		&:hover {
			background-color: fade-out($color33, 0.85);
			color: $color14;

			i {
				color: $color1;
			}
		}
		i {
			left: 11rem;
			font-size: 2.8rem;
			top: 2.6rem;
			width: 2.8rem;
			color: $color65;
			background-size: cover;
			height: 2.8rem;
		}
	}
}

.tabs-billing {
	button {
		width: fit-content;
		padding: 0.5rem 1rem;
		height: fit-content;
		margin: 0 0.5rem;

		&:nth-child(3n) {
			width: auto;
		}
	}
}

.failed {
	position: relative;
	.icon-single-down-pointing-angle {
		position: absolute;
		right: 2rem;
		top: 5rem;
		z-index: 10;
		font-size: 2rem;
		color: $color14;
	}
}

.head-transaction {
	&:hover {
		background: transparent !important;
	}
}

//.modal-checkbox {
//  position: relative;
//  display: flex;
//  justify-content: center;
//  margin: 1.6rem 0 4rem;
//
//  .error {
//    display: none;
//  }
//  input {
//    position: absolute;
//    clip: rect(0, 0, 0, 0);
//  }
//  label {
//    position: relative;
//    display: block;
//    cursor: pointer;
//    padding-left: 4.6rem;
//    margin-bottom: 2.5rem;
//
//    &::before {
//      content: '';
//      position: absolute;
//      left: 0;
//      top: 0;
//      display: block;
//      width: 2.6rem;
//      height: 2.6rem;
//      border: 0.16rem solid $color5;
//      border-radius: 0.33rem;
//    }
//
//    &::after {
//      font-family: 'dextrade3' !important;
//      content: '\e911';
//      display: none;
//      position: absolute;
//      font-size: 2.1rem;
//      left: 2.5px;
//      top: 2.5px;
//      color: $color1;
//    }
//  }
//  input:checked + label::after {
//    display: flex;
//  }
//  input:checked + label::before {
//    border-color: $color1;
//  }
//}
