@import "../../../../../styles/constants.scss";
@import "../../../../../styles/theming.scss";

.header {
	.wrap-balance {
		display: flex;
		position: relative;
		height: 100%;

		.real {
			font-size: 3rem;
			color: #00124a;
			font-weight: bold;
			min-width: 15rem;
			border: 1px solid transparent;

			background-color: #fff;

			display: flex;
			flex-direction: column;

			padding: 1rem 1.4rem 1rem 5rem;
			text-align: right;
			position: relative;
			justify-content: center;

			&::after {
				content: "";
				position: absolute;
				width: 0.2rem;
				height: 50%;
				right: 0;
				background: #999999;
			}

			//position: relative;

			// &.disabled {
			// 	color: #999999;
			// 	border: 1px solid #555d69;
			// 	border-right: none !important;
			// 	background-color: #232733;

			// 	padding: 1rem 1.4rem 1rem 5rem;

			// 	// &::after {
			// 	// 	display: none;
			// 	// }
			// }

			//@include theme-var((
			//   background-color: bg-color-balance-currency
			//));

			.wrapper-currency-placeholder {
				@include theme-var(
					(
						color: color-balance-currency
					)
				);
			}

			span {
				//color: #999999;
				display: block;
				text-align: right;

				position: relative;
				font-size: 2rem;
			}
		}

		.demo {
			font-size: 3rem;
			font-weight: bold;
			color: #00124a;
			display: flex;
			flex-direction: column;

			border: 1px solid transparent;
			background-color: #fff;

			padding: 1rem 5rem 1rem 1rem;
			min-width: 15rem;
			justify-content: center;

			//z-index: 1;

			// &.disabled {
			// 	color: #999999;
			// 	border: 1px solid #555d69;
			// 	border-left: none;
			// 	background-color: #232733;

			// 	padding: 1rem 5rem 1rem 1rem;
			// }

			.wrapper-currency-placeholder {
				@include theme-var(
					(
						color: color-balance-currency
					)
				);
			}

			span {
				//color: #999999;
				display: block;

				position: relative;
				font-size: 2rem;
			}
		}
	}
	.control {
	}
}
