//Theme
$theme-dark: (
	bg-color-header: #2c313d,
	bg-color-nav: #2c313d,
	bg-content: #020d1d,
	bg-body: #2c313d,
	panel-header-bg-color: #2c313d,
	panel-bg-color: rgba(44, 49, 61, 0.3),
	panel-header-color: #e0d9cb,
	hover-sidebar-color-icon: #e0d9cb,
	panel-bg-layout-menu-item: #020d1d,
	panel-color-menu-icon: #e0d9cb,
	layout-modal-color: #e0d9cb,
	control-btn-color: #ccc,
	control-btn-bg-color-active: linear-gradient(#55423e, #333742),
	control-btn-shadow: 0 0 10px 1px rgba(2, 13, 39, 0.5),
	bg-color-filter: #020d1d,
	color-filter: #cccccc,
	icon-search-filter: #cccccc,
	bg-color-currency-pair: #3f4151,
	color-currency-pair: #e0d9cb,
	bg-color-select: #3f4151,
	color-select: #cccccc,
	bg-color-input-order: #3f4151,
	color-input-order: #e0d9cb,
	border-color-input-order: 1px solid #3f4151,
	color-button-open-order: #e0d9cb,
	color-select-placeholder: #aaa,
	bg-color-select-menu-outer: #020d1d,
	color-select-noresults: #999999,
	color-button-deposit-header: #e0d9cb,
	color-deposit-state: #dddddd,
	bg-color-deposit-field: #2c313d,
	bg-color-select-header: #3f4151,
	color-wrapper-currency-placeholder: #cccccc,
	color-currency-value: #999999,
	color-currency-name: #2c313d,
	bg-color-focus-menu-button: #0c3dfb,
	color-focus-menu-button: #cccccc,
	bg-color-tooltip: #3f4151,
	border-color-tooltip: #3f4151,
	color-tooltip: #cccccc,
	bg-color-user-settings: #3f4151,
	color-user-button-settings: #999999,
	bg-color-balance-currency: #2c313d,
	color-balance-currency: #e0d9cb,
	border-balance-currency: #2c313d,
	color-select-billing: #e0d9cb,
	color-select-arrow: #e0d9cb,
	bg-color-select-billing: #2c313d,
	bg-color-select-checked: #020d1d,
	color-main-info: #e0d9cb,
	opacity-img: 1,
	bg-color-input-billing: #2c313d,
	color-input-billing: #cccccc,
	chat-date-color: #6d768f,
	chat-time-color: #6d768f,
	chat-text-color: #e0d9cb,
	chat-text-color-2: #e0d9cb,
	chat-icon-color: #999999,
	chat-bg-item-support: #232733,
	chat-bg-item-user: #0f1827,
	chat-bg-textarea: #2c313d,
	chat-color-textarea: #e0d9cb,
	color-add-comment: #999999,
	color-smile-icon: #999999,
	bg-color-item-ticket: #041522,
	bg-color-status-ticket: #151e2b,
	bg-color-item-ticket-list: #202935,
	color-title-ticket: #e0d9cb,
	color-subtitle-items: #999999,
	color-info-ticket: #e0d9cb,
	hover-list-bgcolor: rgba(234, 100, 51, 0.2),
	active-list-bgcolor: rgba(234, 100, 51, 0.1),
	hover-triangle-color: #041522,
	orange-title: #0c3dfb,
	color-name-date-settings: #e0d9cb,
	bg-color-input-settings: #2c313d,
	header-color-modal: #2c313d,
	color-tittle-modal: #e0d9cb,
	bg-color-modal: #0f1827,
	bg-input-dom-modal: #3f4151,
	border-color-input-dom-modal: #3f4151,
	color-input-dom-modal: #e0d9cb,
	bg-button-close-dom-modal: #2c313d,
	color-button-close-dom-modal: #cccccc,
	bg-color-child-symbol-modal: #3a404d,
	bg-panel-body-content: #0d1727 !important,
	bg-active-content: #0d1727 !important,
	bg-tabs-billing: #0d1727 !important,
	color-text-transaction: #999999,
	color-text-transaction-date: #e0d9cb,
	color-text-transaction-deposit: #999999,
	color-withdraw: #0c3dfb,
	bg-color-li-transaction: transparent,
	bg-color-li-transaction-2: #2c313d,
	bg-color-export-date: #020d1d,
	color-export-date: #999999,
	color-text-account-deposit: #ffffff,
	svg-copy-icon: #ffffff,
	bg-scrollbar-thumb: #2c313d,
	bg-scroll-bar-chat: rgba(0, 0, 0, 0),
	bg-scrollbar-thumb-chat: rgba(0, 0, 0, 0.3),
	bg-scrollbar-corner: #0f1828,
	bg-custom-amount: #010d1e,
	bg-custom-amount-color: #e0d9cb,
	bg-color-chart-container: rgba(45, 49, 61, 0.4),
	bg-color-chart-button: #3f4151,
	bg-rc-dropdown-menu-item: #020d1d,
	bg-rc-dropdown-menu-item-selected: #020d1d,
	color-rc-dropdown-menu-item: #cccccc,
	bg-rc-dropdown-menu-item-hover: #2c313d,
	reg-date-color: #999999,
	thead-bg-color: #0f1828,
	news-title-color: #dadada,
	bg-color-menu-notification: #020d1d,
	color-menu-notification-tittle: #cccccc,
	border-color-menu-notification: #2c313d,
	color-menu-notification: #cccccc,
	color-icon-close-menu-notification: #cccccc,
	bg-color-select-lang: #020d1d,
	color-select-lang: #999999,
	bg-color-select-lang-hover: #2c313d,
	color-select-lang-hover: #e0d9cb,
	bg-color-profile: #020d1d,
	reg-info-hover-color: #e0d9cb,
	reg-button-hover-color: #ffffff,
	color-separation-span: #363945,
	bg-color-input-modal-faq: #020d1d,
	color-input-modal-faq: #cccccc,
	bg-color-title-ticket: #1b2432s
);
