@import "../../styles/constants.scss";

.header {
	&.header-scene {
		//display: flex;
		//align-items: center;
		//justify-content: center;
		//flex: 0.7 1;
		//margin: 0 auto;

		.logo {
			width: 47rem;
		}

		.controls {
			display: flex;
			justify-content: center;
			flex: 0.7 1;
			margin: 0 auto;

			.header-col {
				.date {
					position: relative;
					width: 30rem;
					height: 100%;
					padding-left: 2rem;

					h3 {
						text-align: center;
						text-transform: capitalize;
						font-size: 3rem;
						color: #fff;
						margin: 0;
						padding: 0;
						position: absolute;
					}

					.day {
						top: 4rem;
						left: 50%;
						transform: translateX(-50%);
						font-size: 3rem;
						white-space: nowrap;
					}

					.time {
						color: #33ccff;
						font-size: 3.5rem;
						top: 6rem;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}

			.header-col:nth-child(1n) {
				margin-left: 14.4rem;
				margin-right: 1.4rem;
			}
		}

		.deposit {
			width: fit-content;
			position: relative;
			text-transform: capitalize;
			font-size: 3rem;
			line-height: 8rem;
			cursor: pointer;
			text-decoration: none;
			background-color: #33ccff;
			color: #fff;
			height: 8rem;
			margin: 0 1rem;
			padding: 0 4rem;
			font-weight: 400;
			font-family: Arial;

			&:hover {
				background-color: rgba(25,54,127,0.5);
			}

			.icon-purse {
				position: relative;
				left: -1rem;
			}
		}

		.menu {
			display: flex;
			align-self: flex-end;
			align-items: center;
			width: fit-content;

			justify-content: space-around;

			.notifications-available {
				&::before {
					content: "";
					position: absolute;
					background: #ea6433 !important;
					display: block;
					width: 1rem;
					height: 1rem;
					left: 3.5rem;
					top: 0.4rem;
				}
			}
		}
	}

	.initials,
	.user-menu,
	.rc-dropdown-open {
		text-transform: uppercase;
	}

	.user-block {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 14.16rem;
		height: 14.16rem;
	}

	.user-menu {
		cursor: pointer;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-color: #999999;
		color: #fff;

		display: flex;
		align-items: center;
		justify-content: center;

		font-size: 5rem;

		// &:hover {
		// 	background-color: #33ccff;
		// 	color: #00124a;
		// }

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

button:focus,
a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	outline: none !important;
}

select:-moz-focusring {
	color: transparent;
}

.header .menu button:hover,
.header .menu button:active,
.header .menu button:focus {
	background-color: transparent;
	transition: none;
	color: #fff;
}


.icons-menu {
	display: flex;
	align-items: center;
	.faq-notif {
		display: flex;
		.wrapper-dropdown {
			&.active {
				& + div {
					display: none;
				}
			}
		}
	}
}
.nav-bar-icons {
	a {
		&.active {
			@extend .main-button-icon;
		}
	}
}

.with-border-bottom {
	position: relative;
	&::after {
		content: '';
		display: block;
		position: absolute;
		height: 1px;
		left: 0;
		right: 0;
		background-color: $color65;
		bottom: 0;
	}
}
