@import "../../../fonts/style.css";
@import "constants.scss";
@import "theming.scss";
@import "variables.scss";
@import "./common-colors/common-colors.scss";

html {
	font-size: $main-font-size;

	@media (max-width: 4000px) {
		font-size: 12px;
	}
	@media (max-width: 3280px) {
		font-size: 10px;
	}
	@media (max-width: 2740px) {
		font-size: 9px;
	}
	@media (max-width: 2460px) {
		font-size: 8px;
	}
	@media (max-width: 2188px) {
		font-size: 7px;
	}
	@media (max-width: 2000px) {
		font-size: 6px;
	}
	@media (max-width: 1850px) {
		font-size: 5px;
	}
	@media (max-width: 1540px) {
		font-size: 4px;
	}
	@media (max-width: 1235px) {
		font-size: 3px;
	}
	@media (max-width: 930px) {
		font-size: 2px;
	}
}

html,
body,
#root {
	height: 100%;
	background-color: $color18;
	min-width: 765px;
	//min-height: 768px;
	overflow-y: hidden;
}

body {
	background-color: $bg-body;
	padding: 0;
	margin: 0;
	color: $color20;
	font-size: 16px;
	font-family: "Lato", Roboto, Arial, sans-serif;
	font-weight: 400;

	@include theme-var(
		(
			background-color: bg-body
		)
	);
}

th {
	line-height: 1;
}

input::-webkit-credentials-auto-fill-button {
	visibility: hidden;
	pointer-events: none;
	position: absolute;
	right: 0;
}

@import "layout.scss";
@import "fullscreen.scss";
@import "button.scss";
@import "filter.scss";

@import "../component/Table/Table.scss";
@import "../component/Panel/Panel.scss";
@import "../scene/Dashboard/components/ChartContainer/components/Panel/Panel.scss";
@import "../component/CurrencyPair/CurrencyPair.scss";
@import "../component/Header/modal-deposit.scss";
@import "../component/TicketsChat/chat.scss";
@import "../component/Session/session-modal.scss";
@import "../scene/Settings/settings.scss";
@import "../scene/Tickets/tickets.scss";
@import "../scene/Dashboard/components/DOM/DOM.scss";
@import "../scene/Dashboard/components/DOM/dom-modal-notification.scss";
@import "../scene/Dashboard/components/Header/style/menu-header.scss";
@import "../scene/Dashboard/components/Header/component/style/modal.scss";
@import "../scene/Dashboard/components/Header/component/style/modal-faq.scss";
@import "../scene/Dashboard/components/Header/component/style/open-order-modal.scss";
@import "../scene/Dashboard/components/Header/component/style/modal-layout.scss";
@import "../scene/Dashboard/components/Header/component/style/modal-notification.scss";
@import "../scene/Dashboard/components/Header/component/style/modal-symbols.scss";
@import "../scene/Dashboard/components/Header/style/header.scss";
@import "../scene/Dashboard/components/Header/style/balances.scss";
@import "../scene/Dashboard/components/Header/style/deposit-header.scss";
@import "../scene/Dashboard/components/Header/style/open-order-header.scss";
@import "../scene/Dashboard/components/Header/style/currency-pair-header.scss";
@import "../scene/Dashboard/components/Header/style/date-header.scss";
@import "../scene/Dashboard/components/OrderForm/order.scss";
@import "../scene/Dashboard/components/Symbols/symbols.scss";
@import "../scene/Dashboard/components/Chart/chart.scss";
@import "../scene/Dashboard/components/Chart/ChartDX.scss";
@import "../scene/Dashboard/components/TradingHistory/trading-history.scss";
@import "../scene/Dashboard/Dashboard.scss";
@import "../scene/Login/login.scss";
@import "../scene/Registration/registration.scss";
@import "../scene/RecoveryForm/recovery.scss";
@import "../component/Header/header.scss";
@import "../scene/Billing/billing.scss";
@import "../scene/Dashboard/components/News/News.scss";
@import "../scene/Dashboard/components/MarketTrades/MarketTrades.scss";
@import "../scene/Dashboard/components/Chart/chart.scss";
@import "../scene/Dashboard/components/Header/component/GlobalNotifications/style/Notifications.scss";
@import "../scene/Listing/listing.scss";
@import "../scene/Chat/chat.scss";
@import "../component/Tooltip/styles.scss";
@import "../../../infrastructure/view/scene/Dashboard/components/ChartContainer/components/Panel/Panel.scss";
