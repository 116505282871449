@import "../../styles/constants.scss";
@import "../../styles/theming.scss";
@import "../../styles/common-colors/common-colors.scss";

.panel {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.16);
	border-radius: 0;
	overflow: hidden;
	.tickets & {
		overflow: visible;
		background: $color23;
	}
	@include theme-var(
		(
			background-color: panel-bg-color
		)
	);
	&__node {
		display: flex;

		&:first-child {
			flex-grow: 50;
		}

		&:last-child {
			justify-content: flex-end;
			flex-grow: 1;
		}
	}

	&_tabs {
		max-width: rem(514px);
	}
	.panel-header {
		display: flex;
		align-items: stretch;
		font-weight: bold;
		height: 4rem;
		cursor: default;

		@include theme-var(
			(
				background-color: panel-header-bg-color,
				color: panel-header-color
			)
		);

		.panel-title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-grow: 1;
			margin-right: 4.16rem;
			font-size: 1.7rem;
			font-family: $font-family;
			text-transform: uppercase;
		}

		.panel-button {
			display: flex;
			margin-right: 1rem;
			margin-left: 1rem;
			box-shadow: none;
			z-index: auto;
			justify-content: center;
			align-items: center;

			&:hover {
				i {
					color: $color1 !important;
				}
			}
		}
	}
	.panel-body {
		flex-grow: 1;
		position: relative;
		.tickets & {
			background: 0;
		}
		.panel-body-content {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
}

.panel-left-icon {
	width: 4rem;
	height: 4rem;
	margin-left: 2rem !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1.3rem;
	pointer-events: none;

	i {
		color: $color1 !important;
		font-size: 3.4rem !important;
	}

}
