@import "theme/dark.scss";
@import "theme/light.scss";

$themes: (
	dark: $theme-dark,
	light: $theme-light
);

@mixin theme-var($rules) {
	@each $theme, $map in $themes {
		.#{$theme} & {
			@each $rule, $value in $rules {
				#{$rule}: map-get($map, $value);
			}
		}
	}
}

//.cls {
//	width: 100px;
//	height: 100px;
//	border: 5px solid #000;
//
//	@include theme-var((
//		background-color: background,
//		color: color
//	));
//}
