@import "../../../../../../styles/constants.scss";
@import "../../../../../../styles/theming.scss";

.faq {
  width: 122rem;
  height: 60rem;

  .tabs-faq {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-currency {
      margin-right: 2rem;



      input[type=text] {
				width: 35rem;
				padding: 0 2rem;
        background-color: $bg-color-input-modal-faq;
        color: $color-input-modal-faq;
        border: $border-height-input-modal solid transparent;
				outline: none;

				@include theme-var ((
					background-color: bg-color-input-modal-faq,
					color: color-input-modal-faq
				));
      }
    }

    .active {
      background: -webkit-gradient(linear, left top, left bottom, from(#55423e), to(#333742));
      box-shadow: 0 0 1.6rem 0.1rem rgba(2, 13, 39, 0.5);
      color: $color-active-tab;
      font-weight: 400;
      border: 0.1rem solid transparent;
    }

    button {
      padding: 0.8rem 3.2rem;
      margin: 1.5rem 1.9rem;

      font-size: $control-btn-font-size;
      text-transform: uppercase;
      font-weight: 400;
      border: 1px solid $control-btn-border-color;
      background-color: $control-btn-bg-color;

      &:active,
      &:focus,
      &:visited {
        background: $control-btn-bg-color-active;
        box-shadow: $control-btn-shadow;
        color: $control-btn-color;

        font-weight: 400;
        border: 1px solid $control-btn-border-color-active;
      }

      &:hover {
        color: $control-btn-color;
      }
    }
  }

  .collapsible {
    overflow-y: scroll;
    height: 40rem;
    padding-left: 8rem;
    padding-top: 4rem;

    &::-webkit-scrollbar {
      width: 1rem;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $bg-scrollbar-thumb;
      border: 1px solid transparent;
			outline: 1rem solid transparent;

			@include theme-var ((
				background-color: bg-scrollbar-thumb
			));
    }

    .Collapsible {
      position: relative;
      color: $color-collapsible-modal-header;
    }

    .Collapsible__trigger.is-open::after {
      transform: rotateZ(0deg);
      font-family: 'juicy_orange';
      content: "\e91a";
    }

    .Collapsible__trigger::after {
      font-family: 'juicy_orange';
      content: "\e911";
      position: absolute;
      left: -5rem;
      top: 1.2rem;
      font-size: 1.6rem;
      display: block;
      transition: transform 300ms;
      transform: rotate(45deg);
      color: $color-collapsible-trigger-modal-header;
    }

    .Collapsible__contentOuter {
      transition: height 50ms linear !important;
    }

    .Collapsible__contentInner {
      p {
        text-align: left;
        padding-left: 7rem;
        font-size: 2.2rem;
        line-height: 1;
        text-transform: uppercase;
        color: $color-collapsible-content-inner-modal-header;
      }
    }
  }

  .tuition {
    margin: 1.5rem;
    text-align: center;
    overflow-y: scroll;
    height: 45rem;

    &::-webkit-scrollbar {
      width: 1rem;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $bg-scrollbar-thumb;
      border: 1px solid transparent;
			outline: 1rem solid transparent;

			@include theme-var ((
				background-color: bg-scrollbar-thumb
			));
    }


    .tuition-item {
      display: inline-block;
      width: 26rem;
      height: 14rem;
      margin: 1rem;
      border: 0.1rem solid $color-border-collapsible-tuition;

      font-size: 1.3rem;
    }
  }
}

.color-red {
	color: $color54;
}
.color-green {
	color: $color56;
}
