@import "../../../../../styles/constants.scss";
@import "../../../../../styles/theming.scss";

.header {
	.control {
		.open-order {
			position: relative;
			width: 33rem;
			height: 14.16rem;
			margin: 0;
			padding-left: 5rem;
			text-transform: capitalize;
			font-size: 3rem;
			background-color: #0c3dfb;
			color: #fff;

			&:hover {
				background-color: #2261ff;
			}

			@include theme-var(
				(
					color: color-button-open-order
				)
			);

			.icon-open-order {
				position: absolute;
				top: 5.2rem;
				left: 4rem;
				font-size: 4rem;
				font-weight: normal;
			}
			
			&:focus {
				background: #0c3dfb;
			}
		}
	}
}
