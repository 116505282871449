$color1: #2B9241;
$color2: #0061A2;
$color3: #D15028;
$color4: #7f6b00;
$color5: #F5F5F5;
$color6: #D1DBFF;
$color7: #F5F5F5;
$color8: #00B456;
$color9: #00E56C;
$color10: #009963;
$color11: #FF4D4D;
$color12: #FF4D4D;
$color13: #B13030;
$color14: #000000;
$color15: #000000;
$color16: #FFFCF8;
$color17: #99999933;
$color18: #0F1827;
$color19: #020d1d4d;
$color20: #ffffff;
$color21: #132342;
$color22: #9999994d;
$color23: #fff;
$color24: #99999933;
$color25: transparent;
$color26: linear-gradient(90deg, #000 0%, rgba(51,51,51,0.8) 100%);
$color27: #fff;
$color28: #123E64;
$color29: #99999932;
$color30: #F8F8F8;
$color31: #0F1827;
$color32: #121A29;
$color33: #2B9241;
$color34: #5C687C;
$color35: #00B45614;
$color36: #FF4D4D14;
$color37: #2327334d;
$color38: #F5F5F5;
$color39: #767989;
$color40: #2327334D;
$color41: #9999994D;
$color42: #0E1522;
$color43: #999;
$color44: #7D829C;
$color45: #8E9EBE;
$color46: #8A92B280;
$color47: #ffffff0d;
$color48: #ffffff;
$color49: #253043;
$color50: #E5E5E5;
$color51: #00124A;
$color52: #fff;
$color53: #171F2D;
$color54: #FF4D4D;
$color55: #FFE52C;
$color56: #00B456;
$color57: #F5F6F8;
$color58: #FF4D4D;
$color59: #3F415126;
$color60: #707070;
$color61: rgba(0, 97, 168, .5);
$color62: #008BE8;
$color63: rgba(138, 150, 188, .3);
$color64: #0061a280;
$color65: #999999;
$color66: #0C3DFB1a;
$color67: #D6A63C;
$color68: rgba(43,146, 65, .2);
$color69: #E5E5E5;
$color70: #0C3DFB20;
$color71: #e3e5ee;

.main-button {
	background: linear-gradient(to bottom, #2B9241, #2B9241);
	color: #fff;
	font-size: 3rem;
	cursor: pointer;
	display: block;
	border: none;
	transition: background 0.3s;
	padding: 2.3rem 3rem;
	border-radius: 0;
	min-width: 23.3rem;
	text-align: center;
	text-decoration: none;
	box-sizing: border-box;
	font-family: 'Lato', 'Roboto', Arial;
	&:hover {
		background: linear-gradient(to top, #0DC962, #0DC962);
		color: #fff;
	}
	&:active {
		background: linear-gradient(to top, #00A54A, #00A54A);
		color: #fff;
	}
	&.disable,
	&.disabled,
	&:disabled {
		background: #919FCB;
		color: #fff;
		cursor: default;
	}
}
.main-button-icon {
	background: linear-gradient(to bottom, #0C3DFB, #0C3DFB);
	color: #fff;
	cursor: pointer;
	display: block;
	border: none;
	transition: background 0.3s;
	text-align: center;
	text-decoration: none;
	box-sizing: border-box;
	padding: 2rem;
	font-size: 4rem;
	&:hover {
		background: linear-gradient(to top, #1D33E0, #1D33E0);
		color: #E1EDFF;
	}
	&:active {
		background: linear-gradient(to top, #172AB9, #172AB9);
		color: #E1EDFF;
	}
	&.disabled,
	&:disabled {
		background: #919FCB;
		color: #fff;
		cursor: default;
	}
}

.secondary-button {
	background: #333333;
	color: #fff;
	font-size: 3rem;
	cursor: pointer;
	display: block;
	border: none;
	transition: background 0.3s;
	padding: 2.3rem 3rem;
	min-width: 23.3rem;
	text-align: center;
	text-decoration: none;
	box-sizing: border-box;
	font-family: 'Lato', 'Roboto', Arial;
	&:hover {
		background: #464646;
		color: #fff;
	}
	&:active {
		background: #333333;
		color: #fff;
	}
	&.disabled,
	&:disabled {
		background: #919FCB;
		color: #fff;
		cursor: default;
	}
}

.main-button-small {
	@extend .main-button;
	padding-top: 0.65rem;
	padding-bottom: 0.65rem;
	font-size: 2.5rem;
}
.secondary-button-small {
	@extend .secondary-button;
	padding-top: 0.65rem;
	padding-bottom: 0.65rem;
	font-size: 2.5rem;
	& + .main-button-small {
		margin-left: 2rem !important;
	}
}

