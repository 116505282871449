@import '../../styles/constants.scss';
@import '../../styles/theming.scss';

.tickets {
	.tickets-image-preview {
		display: flex;
		justify-content: flex-end;
		width: 110rem;
		margin-top: 1rem;
		margin-left: 20rem;
		margin-bottom: 1.5rem;

		&__wrap-list {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			flex-wrap: wrap;
			margin-left: -2rem;
		}

		&__wrap {
			position: relative;
			height: 12.666rem;
			overflow: hidden;
			border-radius: 0;
			width: 17.666rem;
			margin-left: 2rem;
			margin-bottom: 2rem;

			&:hover .tickets__control-btn {
				color: $color20;
				opacity: 1;
			}

			&:hover {
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: $color61;
				}
			}

			.tickets__control-btn {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;

				i {
					position: initial;
					font-size: 4rem;
				}

				&_preview i{
					color: $color14;
					background: 0;
				}
			}
		}


		&__item {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: fill;
		}

		&__counter {
			min-width: 12rem;
			color: $color14;
			font-size: 2.3rem;

			i {
				margin-right: 1rem;
			}

			span {
				margin-right: 2rem;
			}
		}
	}
}


.content {
	height: 100%;

	.wide {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: inherit;
		width: 100%;

		.color-pending,
		.color-wait {
			color: $color55;
		}

		.color-rejected {
			color: $color54;
		}

		.color-processed,
		.color-confirmed {
			color: $color56;
		}

		h4 {
			position: absolute;
			top: 8rem;
			text-align: center;
			font-size: 1.8rem;
			font-weight: 100;
			color: $chat-text-color-2;

			@include theme-var(
				(
					color: chat-text-color-2,
				)
			);
		}

		.message-chat {
			margin-top: 26rem;
			width: 150rem;
			height: 80rem;
			position: relative;
			background: #020D1D;
			flex: 1;

			&__wrap {
				position: absolute;
				top: 5rem;
				bottom: 10rem;
				left: 0;
				right: 0;
				display: flex;
				z-index: 1;
			}

			.scrollbars-container div:first-child {
				overflow-x: hidden !important;
			}

			.scrollarea {
				width: 100%;
				touch-action: none;

				&.scrollbar-container.vertical {
					opacity: 0.7 !important;
					width: 8px;
					height: 100%;
					background-color: transparent !important;
					right: 0;
					top: 0;

					&:hover {
						background-color: transparent !important;
					}
				}
			}

			ul {
				text-decoration: none;
				padding: 0;
				margin: 0;
				display: flex;
				flex-direction: column;

				.ticket-messages {
					position: relative;
					display: flex;
					justify-content: flex-start;
					margin-bottom: 3rem;
					padding: 0 5rem;

					img {
						width: 10rem;
						height: 10rem;
					}

					&__wrapper {
						width: 113.3rem;
						margin-left: 13.5rem;
						height: auto;
						color: $color15;
						background-color: $color7;
						font-size: 2.3rem;
						padding: 3rem;
						box-sizing: border-box;
						position: relative;
						border-top-left-radius: 3rem;
						border-bottom-left-radius: 3rem;
						border-bottom-right-radius: 3rem;
						order: 2;

						p {
							width: 100% !important;
							word-wrap: break-word !important;
							margin: 1rem 0 0 1rem;
						}

						@include theme-var(
							(
								background-color: chat-bg-item-support,
								color: chat-text-color-2,
							)
						);
					}

					&__support-avatar {
						width: 8.33rem;
						height: 8.33rem;
						margin-right: 2.6rem;
						background-color: $color5;
						object-fit: cover;
						order: 1;
					}

					&__client-avatar {
						width: 8.33rem;
						height: 8.33rem;
						margin-left: 2.6rem;
						background-color: $color5;
						object-fit: cover;
						order: 3;
					}

					&__name {
						color: $color1;
					}

					&__date,
					&__time {
						color: $color14;
						padding-left: 2rem;
					}

					.support {
						background-color: $color49;
						margin-left: 0.9rem;
						position: relative;
						border-top-left-radius: 0;
						border-top-right-radius: 3rem;
						border-bottom-left-radius: 3rem;
						border-bottom-right-radius: 3rem;

						&:after {
							content: none;
						}
					}

					.no-avatar {
						display: flex;
						justify-content: center;
						width: 10rem;
						height: 10rem;
						margin-left: 4rem;
						align-items: center;
						background-color: $color5;
						font-size: 3.7rem;
					}
				}
			}
		}

		.messages {
			width: 113.5rem;
			box-sizing: border-box;
			margin-top: 1.2rem;

			.form-group-chat {
				div {
					margin-bottom: 2rem;
				}

				span {
					color: $color14;
					font-size: 2.3rem;
				}

				.add-comment {
					display: block;
				}
			}

			.inline-form {
				.area {
					width: 109.33rem !important;
					height: 20rem !important;
					background-color: $color7;
					padding: 0 2rem 2rem 2rem !important;
					touch-action: none;
					border-radius: 0;
				}
			}

			textarea {
				padding: 3rem;
				position: relative;
				width: 100% !important;
				background-color: $color7;
				color: $color20;
				border: 0.01rem solid transparent;
				outline: none;
				overflow-y: hidden;
				font-family: "Lato", Roboto, Arial, sans-serif;
				font-size: 2.3rem;
				box-sizing: border-box;
				resize: none;

				&::placeholder {
					color: $color14;
				}

				@include theme-var(
					(
						background-color: chat-bg-item-support,
						color: chat-text-color-2,
					)
				);
			}

			.wrap-button {
				width: 113.3rem;
				margin-top: 3rem;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				color: #999999;
				font-size: 3rem;
				margin-bottom: 15px;

				span {
					padding-right: 3rem;
				}

				button {
					text-transform: capitalize;
					@extend .main-button;
				}
			}
		}

		.ticket-chat__avatar {
			background-color: #3f4151;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			font-size: 1.8em;
			width: 10rem;
			height: 10rem;
			overflow: hidden;
		}
	}

	.ticket-message-content {
		display: flex;
		flex-direction: column;

		&__content {
			&--title {
				white-space: pre;
				color: $color14;
			}

			&--info {
				white-space: pre;
			}
		}
	}

	.ticket-message-content-verification {
		&__content {
			color: $filter-input-color;
		}
	}
}

.modal .image-preview-modal {
	&__title {
		padding-left: 2rem;
		font-size: 3rem;
	}

	&__image {
		display: block;
		width: 100%;
		max-height: 90vh;
		object-fit: contain;
	}
}
