@import "../../../../../../styles/constants.scss";
@import "../../../../../../styles/theming.scss";

.modal {
	position: relative;
	top: 50%;
	left: 50%;
	right: auto;
	transform: translate(-50%, -50%);
	width: 133rem;
	background-color: $color23;
	outline: none;
	z-index: 100;
	box-shadow: 0 0 3rem rgba($color2, 0.5);
	border: 1px solid rgba($color2, 0.5);
	border-radius: 0;
	overflow: hidden;
	@include theme-var(
		(
			background-color: bg-color-modal
		)
	);

	.header-2FA {
		padding: 1.666rem 2.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #E3E5EE;
	}
	.content-2FA {
		padding: 8.333rem 11.666rem 6.666rem 11.666rem;
		font-size: 3rem;

		.description {
			margin: 0;
			color: $color14;
		}

		&_button {
			display: block;
			height: 8.333rem;
			margin: 0 auto 4rem auto;
			background-color: $color1;
			text-transform: capitalize;
			font-size: 3rem;
			padding: 0 2rem;

			&:hover {
				background-color: $color2;
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				height: 100%;
			}
		}
	}
	.modal-header {
		display: flex;
		justify-content: space-between;
		text-transform: uppercase;
		align-items: center;
		height: 6.6rem;
		padding: 0 1rem;
		font-weight: 400;
		background-color: $color50;
		color: $color15;
		* {
			font-weight: 400;
		}
		span {
			font-size: 3rem;
			margin-left: 2rem;
			color: $color15;
		}
		button {
			height: 2.6rem;
			width: 6rem;
			box-shadow: none;
			color: $color14;
			font-size: 2.6rem;

			&:hover {
				color: $color15;
				i {
					color: $color1;
				}
			}
		}
	}
	.market {
		display: flex;
		flex-direction: column;
		width: 100rem;
		height: 40rem;
		margin: 2rem;
	}
}

.icon-close:hover {
	color: $color1;
}
