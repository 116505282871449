@import "../../../../styles/constants.scss";
@import "../../../../styles/theming.scss";
.order-center {
	display: flex;
	flex-direction: column;
	padding: 2.33rem 4rem 2.33rem;
}

.trading-instrument {
	display: flex;

	.span-styles {
		position: relative;
		color: $color65;
		text-transform: capitalize;
		font-size: 2.3rem;
		display: block;
		margin-bottom: 0.1rem;
	}

	select {
		width: 21rem;
		height: 3rem;
		margin-left: 2rem;
		padding-left: 1rem;
		font-size: 2rem;
		text-transform: capitalize;
		background-color: #f5f5f5;
		color: #00124a;
		border: 0 !important;
		outline: none;
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		cursor: pointer;

		&:focus,
		&:active {
			border: 0 !important;
			outline: none;
		}

		&::-ms-expand {
			display: none;
		}

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
		}

		@include theme-var(
			(
				background-color: bg-color-select,
				color: color-select
			)
		);
	}

	.select-wrapper {
		width: 31rem;
	}

	.currency-pair--tools {
		margin: 0;
		width: 100%;

		.name-currency {
			font-size: 2.3rem;
			margin-bottom: 0;

			&:hover {
				color: #0C3DFB;
			}
		}

		&:hover {
			border: 1px solid #0C3DFB;
			color: #0C3DFB !important;

			.name-currency {
				color: #0C3DFB;
			}

			svg {
				fill: #0C3DFB !important;
			}

			i {
				color: #0C3DFB;
			}
		}
	}
}

.col.market {
	width: 68.33333rem !important;
}
.order-raw {
	display: flex;
	justify-content: space-between;
	.disable {
		.icon-max,
		.icon-min {
			color: $color14;
			&:hover {
				color: $color14;
			}
		}
	}
	.icon-max {
		color: $color11;
	}
	.icon-min {
		color: $color8;
	}
	.icon-max,
	.icon-min {
		font-size: 2.5rem;
		vertical-align: text-top;
		&:hover {
			color: $color1;
		}
	}
	.select-wrapper {
		min-width: calc(50% - 1rem);
		.select-input__control {
			background: $color5;
		}
		.span-styles {
			color: $color65;
			font-size: 2.6rem;
			margin-left: 1.5rem;
			display: block;
			margin-bottom: 0.1rem;
		}
		.select-wrapper {
			margin-bottom: 0;
			min-width: 100%;
		}
		button {
			margin: 0;
		}
	}
	.order-form & {
		flex-direction: column;
		width: calc(50% - 1rem);
		.wrapper-line {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			color: $color65;
		}
	}

	.sell-wrapper {
		position: relative;

		.sell {
			width: 100%;

			&::placeholder {
				color: rgba($color43, 0.5);
			}

			&.error {
				border: 1px solid #c7475b;
			}
		}
	}
}

.tooltip-sell-limit-error {
	display: none;
	align-items: center;
	justify-content: center;
	bottom: 5.5rem;
	right: -50%;
	padding: 0 2rem;
	border-radius: 0;
	transform: translateX(-50%);
	color: $color14;
	line-height: 3rem;
	width: fit-content;
	min-width: 24.6rem;
	height: 7.1rem;
	background-color: $color7;
	font-size: 2.3rem;
	position: absolute;
	z-index: 1;
	border: 1px solid $color1;
	box-shadow: 0 0.5rem 2rem $color46;

	@include theme-var(
			(
				background-color: bg-color-tooltip,
				color: color-tooltip,
			)
	);

	&:after {
		content: ' ';
		position: absolute;
		top: 6.2rem;
		left: 50%;
		bottom: 100%;
		transform: translateX(-50%) rotate(-135deg);
		display: block;
		width: 2rem;
		height: 2rem;
		border: 0.1rem solid $color1;
		border-bottom: none;
		border-right: none;
		background-color: $color7;

		@include theme-var(
				(
					border-bottom-color: border-color-tooltip,
				)
		);
	}

	&.show-tooltip {
		display: inline-flex;
	}
}

.order-form {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
	.order-form-wrap {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 8.4rem;
		margin-top: 1.5rem;
		cursor: pointer;

		&.disable {
			cursor: default;
			pointer-events: none;

			.wrap-buy {
				span {
					background-color: #BCC5D8;

					&::before {
						background-color: #BCC5D8;

					}

					&::after {
						background-color: #BCC5D8;
					}
					.triangle-top {
						border-bottom: 0.5rem solid #BCC5D8;
					}
				}
			}

			.wrap-sell {
				span {
					background-color: #BCC5D8;

					&::before {
						background-color: #BCC5D8;
					}

					&::after {
						background-color: #BCC5D8;
					}

					.triangle-down {
						border-top: 0.5rem solid #BCC5D8;
					}
				}
			}
		}

		.wrap-data {
			cursor: pointer;
			width: 3rem;
			height: 100%;
		}

		.wrap-buy {
			position: relative;
			height: 2.5rem;
			border: none;
			cursor: pointer;

			span {
				transition: width 0.3s;
				background-color: #5C687C;
				height: 1px;
				border: 0;
				width: 3rem;
				margin-left: 0;

				&::before {
					transition: all 0.2s;
					content: '';
					width: 3rem;
					height: 1px;
					background-color: #5C687C;
					position: absolute;
					top: 0.7rem;
					left: 0;
				}

				&::after {
					transition: all 0.3s;
					content: '';
					position: absolute;
					top: 1.4rem;
					left: 0;
					width: 1.4rem;
					height: 1px;
					background-color: #00B456;

				}

				.triangle-top {
					transition: all 0.3s;
					position: absolute;
					right: 0.1rem;
					top: 1.1rem;
					width: 0;
					height: 0;
					border-left: 0.5rem solid transparent;
					border-right: 0.5rem solid transparent;
					border-bottom: 0.5rem solid #00B456;

				}
			}

			&:hover {
				span {
					width: 1.6rem;
					background-color: $color20;

					&::before {
						width: 1.3rem;
						background-color: $color20;
					}

					&::after {
						width: 1rem;
					}


					.triangle-top {
						transform: scale(1.7, 1.7);
						transform-origin: bottom;
					}
				}
			}
		}

		.buy-one {
			&:hover {
				.tooltip-buy-one {
					display: flex;
					align-items: center;
					justify-content: center;
					top: -10rem;
					left: 1.5rem;
					transform: translateX(-50%);
					visibility: visible;
					line-height: 3rem;

					width: fit-content;
					white-space: nowrap;
					padding: 0 3rem;
					border-radius: 0;
					height: 6rem;
					background-color: $color23;
					color: $color14;
					font-size: 2.3rem;
					position: absolute;
					z-index: 1;
					border: 1px solid $color1;
					box-shadow: 0 0 3rem $color66;
					text-align: center;
					vertical-align: center;

					@include theme-var(
						(
							background-color: bg-color-tooltip,
							color: color-tooltip
						)
					);
				}

				.tooltip-buy-one::after {
					content: " ";
					position: absolute;
					top: 5.2rem;
					left: 50%;
					bottom: 100%;
					transform: translateX(-50%) rotate(-135deg);
					display: block;
					width: 2rem;
					height: 2rem;
					border: 1px solid $color1;
					border-bottom: none;
					border-right: none;
					background-color: $color23;

					@include theme-var(
						(
							border-bottom-color: border-color-tooltip
						)
					);
				}
			}
		}

		.sell-one {
			&:hover {
				.tooltip-sell-one {
					display: flex;
					align-items: center;
					justify-content: center;
					top: -10rem;
					left: 1.5rem;
					transform: translateX(-50%);
					visibility: visible;
					width: fit-content;
					white-space: nowrap;
					padding: 0 3rem;
					border-radius: 0;
					height: 6rem;
					background-color: $color23;
					color: $color14;
					font-size: 2.3rem;
					position: absolute;
					z-index: 1;
					border: 1px solid $color1;
					box-shadow: 0 0 3rem $color66;
					text-align: center;
					vertical-align: center;

					@include theme-var(
						(
							background-color: bg-color-tooltip,
							color: color-tooltip
						)
					);
				}

				.tooltip-sell-one::after {
					content: " ";
					position: absolute;
					top: 5.2rem;
					left: 50%;
					bottom: 100%;
					transform: translateX(-50%) rotate(-135deg);
					display: block;
					width: 2rem;
					height: 2rem;
					border: 0.1rem solid $color1;
					border-bottom: none;
					border-right: none;
					background-color: $color23;

					@include theme-var(
						(
							border-bottom-color: border-color-tooltip
						)
					);
				}
			}
		}

		.buy-two {
			&:hover {
				.tooltip-buy-two {
					display: flex;
					align-items: center;
					justify-content: center;
					top: -10rem;
					right: -3rem;
					visibility: visible;
					line-height: 3rem;
					width: fit-content;
					white-space: nowrap;
					padding: 0 3rem;
					border-radius: 0;
					height: 6rem;
					background-color: $color23;
					color: $color14;
					font-size: 2.3rem;
					position: absolute;
					z-index: 1;
					border: 1px solid $color1;
					box-shadow: 0 0 20px 5px $color66;
					text-align: center;
					vertical-align: center;

					@include theme-var(
						(
							background-color: bg-color-tooltip,
							color: color-tooltip
						)
					);
				}

				.tooltip-buy-two::after {
					content: " ";
					position: absolute;
					top: 5.2rem;
					right: 0;
					margin-right: 2.6rem;
					bottom: 100%;
					transform: translateX(-50%) rotate(-135deg);
					display: block;
					width: 2rem;
					height: 2rem;
					border: 0.1rem solid $color1;
					border-bottom: none;
					border-right: none;
					background-color: $color23;

					@include theme-var(
						(
							border-bottom-color: border-color-tooltip
						)
					);
				}
			}
		}

		.sell-two {
			&:hover {
				.tooltip-sell-two {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					top: -10rem;
					border-radius: 0;
					right: 0;
					margin-right: -4rem;
					visibility: visible;
					color: $color14;
					line-height: 3rem;
					width: fit-content;
					white-space: nowrap;
					padding: 0 3rem;
					height: 6rem;
					background-color: $color23;
					font-size: 2.3rem;
					position: absolute;
					z-index: 1;
					border: 1px solid $color1;
					box-shadow: 0 0 20px 5px $color66;

					@include theme-var(
						(
							background-color: bg-color-tooltip,
							color: color-tooltip
						)
					);
				}

				.tooltip-sell-two::after {
					content: " ";
					position: absolute;
					top: 5.2rem;
					right: 3.5rem;
					bottom: 100%;
					transform: translateX(-50%) rotate(-135deg);
					display: block;
					width: 2rem;
					height: 2rem;
					border: 0.1rem solid $color1;
					border-bottom: none;
					border-right: none;
					background-color: $color23;

					@include theme-var(
						(
							border-bottom-color: border-color-tooltip
						)
					);
				}
			}
		}

		.wrap-sell {
			position: relative;
			height: 2.5rem;
			border: none;
			cursor: pointer;

			span {
				transition: width 0.3s;
				background-color: #FF4D4D;
				width: 1.6rem;
				height: 1px;
				border: 0;
				margin-left: 0;

				.triangle-down {
					transition: all 0.3s;
					position: absolute;
					right: 0.1rem;
					top: 0;
					width: 0;
					height: 0;
					border-left: 0.5rem solid transparent;
					border-right: 0.5rem solid transparent;
					border-top: 0.5rem solid #FF4D4D;

				}

				&::before {
					transition: all 0.3s;
					content: '';
					width: 100%;
					height: 1px;
					background-color: #5C687C;
					position: absolute;
					top: 0.7rem;
					left: 0;
					border: 0;
				}

				&::after {
					transition: all 0.3s;
					content: '';
					position: absolute;
					top: 1.4rem;
					left: 0;
					width: 100%;
					height: 1px;
					background-color: #5C687C;
					border: 0;
				}
			}

			&:hover {
				span {
					width: 0.8rem;
					background-color: #FF4D4D;

					&::before {
						width: 1.3rem;
						background-color: $color20;
					}

					&::after {
						width: 100%;
						background-color: $color20;
					}


					.triangle-down {
						transform: scale(1.6, 1.6);
						transform-origin: top;
					}
				}
			}
		}
	}

	.order-form-buy div i,
	.order-form-sell div i {
		width: 3rem;
		height: 3rem;
	}

	.wrapper {
		&:nth-child(2) {
			margin-left: 2rem;
		}

		.wrapper-line {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.order-form-buy,
			.order-form-sell {
				display: flex;
				align-items: center;
				div {
					margin: 0 1rem;
				}
			}
		}
	}

	span {
		display: block;
		font-size: 2.6rem;
		margin-left: 1.5rem;
	}

	input[type="text"] {
		padding-right: 1.5rem;
		border: 1px solid $color7;
		padding-left: 1.5rem;
		height: 4.16rem;
		font-size: 2.3rem;
		background-color: $color7;
		color: $color15;
		outline: none;
		box-sizing: border-box;
		position: relative;
		z-index: 7;
		&:hover, &:focus {
			border: 1px solid $color1;
		}

		@include theme-var(
			(
				background-color: bg-color-input-order,
				color: color-input-order,
				border: border-color-input-order
			)
		);
	}

	.tooltip-buy-one,
	.tooltip-sell-one,
	.tooltip-sell-two,
	.tooltip-buy-two {
		display: none;
	}

	&-buy {
		padding: 0;
		position: absolute;
		margin-top: -2rem;
		right: 5rem;

		div {
			position: relative;
			width: 2rem;
			height: 2rem;
			padding-left: 1rem;
			left: 3rem;
			bottom: -0.8rem;
			cursor: pointer;

			i {
				width: 2rem;
				height: 2rem;
				position: absolute;
			}
		}
	}

	&-sell {
		padding: 0;
		position: absolute;
		margin-top: -2rem;
		right: 5rem;

		div {
			position: relative;
			width: 2rem;
			height: 2rem;
			padding-left: 1rem;
			left: 3rem;
			bottom: -0.8rem;
			cursor: pointer;

			i {
				width: 2rem;
				height: 2rem;
				position: absolute;
			}
		}
	}
}

.order-button {
	display: flex;
	justify-content: space-between;
	margin-top: 3rem;
	.order-btn {
		width: calc(50% - 1.1rem);
		height: 6rem;
		font-size: 2rem;
		background-color: $color8;
		border: 1px solid $color8;
		color: $color20;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;


		&.disabled {
			color: $color65 !important;
			background-color: transparent !important;
			border-color: $color65 !important;
			cursor: default;
			i {
				color: $color65 !important;
			}
		}

		&.red {
			border: 1px solid $color11;
			background-color: $color11;
			color: $color20;
			i {
				color: $color7;
			}
			&:hover {
				background: #D83131;
				color: $color7;
				i {
					color: $color7;
				}
			}
			&:active {
				border-color: #B41919;
				background: #B41919;
			}
		}

		i {
			position: initial;
			padding-right: 1rem;
			font-size: 2rem;
			color: $color7;
			margin-right: 0;
		}

		&:hover {
			background-color: #00A24E;
			color: $color7;

			i {
				color: $color7;
			}
		}
		&:active {
			background: #008B42;
			border-color: #008B42;
		}
	}
}

.order-button {
	button {
		overflow: hidden;
		&::after {
			content: "";
			width: 1px;
			height: 1px;
			background: radial-gradient(transparent, rgba(255, 51, 51, 0.6));
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transform: scale(1);
			opacity: 1;
			transition: 0s;
		}

		&:focus {
			&::after {
				transform: scale(200);
				opacity: 0;
				transition: all 0.3s linear;
			}
		}
	}

	button.green {
		&::after {
			background: radial-gradient(transparent, rgba(51, 204, 153, 0.6));
		}
	}
}

.order-form-tab {
	font-size: 2.3rem;
	button {
		margin: 3rem 0 2rem 2rem;
		cursor: default;
		color: #999999;
	}
}

.trading-instrument {

	.currency-pair--tools {
		.name-currency {
			&:hover {
				color: #0C3DFB;
			}
		}

		&:hover {
			border: 1px solid #0C3DFB;
			color: #0C3DFB !important;

			.name-currency {
				color: #0C3DFB;
			}

			svg {
			    fill: #0C3DFB !important;
			}

			i {
				color: #0C3DFB;
			}
		}
	}

	.wrapper-symbol {
		margin-bottom: 2rem;
	}

	.select-wrapper {
		position: relative;

		.select-wrapper {
			margin-bottom: 2rem;
		}

		&::after {
			font-size: 2rem;
			top: 2.6rem;
		}

		select {
			font-size: 2.3rem;
			padding-left: 2rem;
		}
	}
}
