@import "../styles/constants.scss";
@import "../styles/theming.scss";

.wrapper-filter {
	position: relative;
	width: 100%;

	i {
		position: absolute;
		font-size: 2rem;
		top: 2.6rem;
		right: 4rem;
		z-index: 2;
		color: $color44;

		@include theme-var(
			(
				color: icon-search-filter
			)
		);
	}

	.filter {
		position: relative;
		width: 96%;
		margin: 1rem;
		padding: 1rem 1.5rem;
		border: 1px solid transparent;
		outline: none;
		z-index: 1;
		background-color: $color7;
		color: $color14;
		font-size: 2rem;
		box-sizing: border-box;
		@include theme-var(
			(
				background-color: bg-color-filter,
				color: color-filter
			)
		);

		&::placeholder {
			color: $color65;

			@include theme-var(
				(
					color: color-filter
				)
			);
		}
		&:hover,
		&:focus {
			border-color: $color1;
		}
	}
}
