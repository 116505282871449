#custom-chart {
	height: auto!important;
	position: absolute;
	top: 8.533rem;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%!important;
	display: flex;
	flex-direction: column;
}

.drawingControlPanel-overlay {
	display: none;
}

.main-chart {
	height: 100%;
	overflow: hidden;
}

.demo-container {
	display: flex;
	background: #e6e6e6;
}

.demo-controls-container {
	flex-grow: 1;
	max-width: 30%;
	margin: 20px;
	background: white;
	text-align: center;
}

.demo-controls-container > h1 {
	text-align: center;
}

.demo-controls-container dl {
	display: flex;
	flex-flow: row wrap;
}
.demo-controls-container dt {
	flex-basis: 60%;
	flex-grow: 4;
	padding: 20px;
	background: #fbfbfb;
	text-align: right;
}
.demo-controls-container dd {
	flex-basis: 10%;
	flex-grow: 1;
	padding: 20px;
	background: white;
}

.demo-chart-container {
	position: relative;
	flex-grow: 10;
	height: 90%;
	margin: 20px;
}

.chart canvas {
	display: block;
	width: 100%;
	height: 0;
}

.chart {
	display: table;
	width: 100%;
	height: 100%;
	background: $color27;
	color: $color14;
}

.chart canvas {
	display: block;
	width: 100%;
	height: 0;
}

.chart--canvasArea {
	//display: table-cell;
	flex: 1;
}

.chart--studiesWrapper {
	display: table-row;
}

.chart--studiesInner {
	display: table-cell;
	column-span: all;
	/* W3C */
	-webkit-column-span: all;
	/* Safari & Chrome */
	-moz-column-span: all;
	/* Firefox */
	-ms-column-span: all;
	/* Internet Explorer */
	-o-column-span: all;
	/* Opera */
}

.chart--studies {
	display: table-row-group;
	width: 100%;
}

.chart--graph,
.chart--axisWrapper-x,
.chart--map {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.chart--map {
	display: table-row;
}

.chart--axisWrapper-x,
.chart--graph {
	display: table-row;
}

.chart--graph-resizable {
	display: flex;
	height: 100%;
	width: 100%;
}

.chart--resizeHandle {
	height: 3px;
	cursor: s-resize;
}

.chartLayout--cell-axis {
	//flex: 1;
}

.chart--axisWrapper-y {
	display: flex;
	vertical-align: top;
	min-width: 14.3rem;
}

.chart--axis-y {
	position: relative;
	width: 100%;
	height: 100%;
	font-size: 9pt;

	&:active {
		cursor: n-resize;
	}
}

.chart--axisWrapper-x {
	height: 20px;
}

.chart--axis-x {
	position: relative;
	display: table-cell;
	padding: 3px 0 0;
	border-top: none;
	border-bottom: none;
	//font-family: sans-serif;
}

.chart--axis-x > .current,
.chart--axis-y > .current {
	border: none;
}

.chart--axis-y > .last {
	color: $color23;
	&--negative {
		background-color: #FF4D4D;
		z-index: 1;
	}

	&--positive {
		background-color: #50d576;
		z-index: 1;
	}
}

.current {
	position: relative;
	color: $color23;
	border-radius: 0 !important;
	background-color: $color1;

	&:after {
		content: '';
		position: absolute;
		left: -12px;
		top: 0;
		border: 6px solid transparent;
		border-right: 6px solid $color1;
	}
}

.chart--axis-x .current {
	&:after {
		display: none;
	}
}

.chart--axis-x > div,
.chart--axis-y > div {
	position: absolute;
	font-size: 9px;
}

.chart--axis.chart--axis-y.chart--axisExpander > div {
	position: relative;
	visibility: hidden;
	line-height: 0;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.chart--axis-y > div {
	margin: -7px 5px 0;
	padding: 1px 3px;
}

.chart--canvasWrapper {
	position: relative;
	width: 100%;
}

.crossTool--line {
	position: absolute;
}

.crossTool--line-horizontal {
	left: 0;
	width: 100%;
	border-top: 1px dotted;
	opacity: 0.5;
}

.crossTool--line-vertical {
	top: 0;
	height: 100%;
	border-left: 1px dotted;
	z-index: 2;
}

.crossTool--label span {
	display: inline-block;
	margin: 5px;
	padding: 3px 10px;
	border: none;
	white-space: nowrap;
	font-size: 10px;
	//font-family: sans-serif;
	//color: $color20;
	background-color: $color38;
	opacity: 1;
}

.crossTool--label-volume {
	position: absolute;
	bottom: 5px;
	left: 0;
}

.crossTool--stickPoint {
	position: absolute;
	display: none;
	margin-top: -3px;
	margin-left: -3px;
	width: 7px;
	height: 7px;
}

.chart--legend {
	position: absolute;
	top: 0;
	z-index: 2;
	display: inline-block;
	margin-left: 10px;
	cursor: pointer;
}

.chart--legend > p {
	overflow: hidden;
	width: 300px;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: normal;
	font-size: 2.3rem;
}

.chart--legend > p:hover {
	width: auto;
}

.chartStudy--infoBar {
	font-size: 2.3rem;
}

.chart--highLowIndicator {
	position: absolute;
	padding: 4px;
	opacity: 0.5;
}

.chart--highLowIndicator-low {
	margin-top: -2em;
}

.chartStudy-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.chartStudy--marker {
	display: inline-block;
	margin: 3px 3px 1px 7px;
	width: 7px;
	height: 7px;
}

.chartMap {
	position: relative;
	display: table-cell;
	color: $color14;
	background: $color7;
}

.chart--map {
	display: block;
	.chartMap {
		display: block;
		&--container {
			display: flex;
			align-items: center;
		}
		&--canvasWrapper {
			margin: 0;
			flex: 1;
		}
		&--button {
			height: 20px;
			width: 20px;
			border: 1px solid $color65;
			box-sizing: border-box;
			top: 50%;
			transform: translateY(-50%);
		}
	}

}

.chartMap--canvasWrapper {
	position: relative;
	margin: 0 25px;
	height: 100%;
	canvas {
		height: 18px !important;
		margin-top: 2rem;
	}
}

.chartMap--labels {
	position: absolute;
	top: -1rem;
	width: 100%;
	height: 100%;
}

.chartMap--labels > div {
	position: absolute;
	height: 100%;
	white-space: nowrap;
	font-size: 2.33333rem;
	&:last-child {
		left: auto !important;
		right: 1rem;
		bottom: 40%;
	}
}

.chartMap--controls {
	position: absolute;
	top: 0;
	display: table;
	width: 100%;
	height: 100%;
}
@-moz-document url-prefix() {
	.chartMap--controls {
		top: 0.8rem;
	}
}

.chartMap--button {
	position: absolute;
	top: 0;
	width: 18px;
	height: 38px;
	border: 1px solid;
	font-weight: bold;
	border-radius: 50%;
}

.chartMap--button-toLeft {
	left: 0;

}

.chartMap--button-toRight {
	right: 0;

}

.chartMap--area {
	display: table-cell;
}

.chartMap--area-hidden {
	width: 10px;
	height: 100%;
	border: 1px solid;
	vertical-align: middle;
	background: #99999940;
}
.chartMap--area-visible {
	//background: $color19;
}

.chartMap--area:first-child {
	text-align: right;
}

.chartMap--knob {
	display: inline-block;
	width: 5px;
	height: 25px;
	border: 1px solid;
	background: white;
	cursor: e-resize;
}

.chartMap--knob-toLeft {
	right: 0;
	margin-right: -4px;

}

.chartMap--knob-toRight {
	left: 0;
	margin-left: -4px;

}

.chartMap--window {
	display: inline-block;
	width: 100%;
	height: 100%;

	&:active {
		cursor: e-resize;
	}
}

.chartMap--container {
	position: relative;
}

.chartMap--area {
	border: none;
	height: 24px;
}

button,
.button {
	cursor: pointer;
}

.chartMap--button {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	width: 18px;
	height: 38px;
	border: 1px solid;
	font-weight: bold;
}

.chartMap--button {
	border: none;
	padding: 0;
	width: 21px;
	height: 21px;
	font-size: 0.7rem;
	line-height: 35px;

	z-index: 10;
}

.chartMap--button-toLeft {
	margin-left: 1.5rem;
}

.chartMap--button,
.chartMap--knob {
	background: $color7;
	color: rgba($color14, .5);
	border: none;
	transition: background 0.3s;

	&:hover {
		background: $color5;
		color: $color14;
	}
}

.chartMap--button-toLeft:before {
	margin-left: 3px;
}

.chartMap--button:before {
	content: '';
	display: block;
	vertical-align: middle;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 4px;
	border-color: rgba($color14, 0.5);
}

.chartMap--button:hover:before {
	border-color: $color14;
}

.chartMap--button-toRight {
	margin-right: 1.5rem;
}

.chartMap--button-toRight:before {
	border-top-color: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent;
}

.chartMap--button-toRight:hover:before {
	border-top-color: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent;
}

.chartMap--button-toLeft:before {
	border-top-color: transparent;
	border-left-color: transparent;
	border-bottom-color: transparent;
}

.chartMap--button-toLeft:hover:before {
	border-top-color: transparent;
	border-left-color: transparent;
	border-bottom-color: transparent;
}

.chartMap--button-toRight:before {
	margin-left: 8px;
}

.chartMap--knob-toLeft {
	margin-right: -2px;
}

.chartMap--knob {
	height: 22px;
	width: 7px;
	border: 1px solid rgba($color65, 0.5);
	text-align: center;
	background: $color7;
	border-radius: 1rem;
	&:hover {
		background: $color5;
		color: $color14;
	}
}

.chartMap--knob-toLeft {
	right: 0;
}

.chartMap--knob {
	display: inline-block;
	cursor: e-resize;
}

.chartMap--knob:before {
	background: rgba($color65, 0.5)
}

.chartMap--knob:hover:before {
	background: $color14;
}

.chartMap--knob:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	margin-top: 4px;
	width: 1px;
	height: 14px;
}
