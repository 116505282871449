@import "../../../../styles/constants.scss";
@import "../../../../styles/theming.scss";

.notification {
  width: 90rem;
  height: 60rem;

  .content-notification {
    display: flex;
    flex-direction: row;
    padding: 5rem 10rem;
    align-items: center;

    .col {
      width: 50%;

      &:last-child {
        button {
          width: 11.5rem;
          height: 5.5rem;
          border-radius: 0;
          background-color: $bg-button-close-dom-modal;
          color: $color-button-close-dom-modal;
          margin-left: 3rem;
          float: right;

          @include theme-var ((
              background-color: bg-button-close-dom-modal,
              color: color-button-close-dom-modal
          ));

          &:last-child {
            background-color: $bg-button-submit-dom-modal;
          }
        }
      }

      span {
        color: $color-text-price;
      }

      input[type=text] {
        width: 18.8rem;
        margin-left: 2.9rem;
        padding: 0 1rem;
        border-radius: $border-radius-input-dom-modal;
        background-color: $bg-input-dom-modal;
        border: 0.5rem solid $border-color-input-dom-modal;
        outline: none;
        color: $color-input-dom-modal;

        @include theme-var ((
            background-color: bg-input-dom-modal,
            border-color: border-color-input-dom-modal,
            color: color-input-dom-modal
        ));
      }

      i {
        float: right;
        color: $color-icon-dom-modal;
      }

      @-moz-document url-prefix() {
        .currency-pair--tools {
          margin-right: 6rem !important;
        }
      }

      .currency-pair--tools {
        float: right;
        margin-right: 6rem;
      }
    }
  }
}
