@import "../../styles/common-colors/common-colors";
@import "../../styles/variables.scss";

.recovery-container {
	.wrapper {
		border-top-left-radius: 0;
		background: linear-gradient(to bottom, $color18, $color21);
		background: -moz-linear-gradient(to bottom, $color18, $color21);
	}
}

.recovery {
	background: linear-gradient(to bottom, $color18, $color21);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 1000;

	#g-recaptcha {
		margin-top: 6.5rem !important;
	}

	.column-wrap form .icon-info {
		position: relative;
		top: 0;
	}

	.log-bg {
		display: block;
		position: fixed;
		transform: translate(0, -48%);
		top: 50%;


		&_left {
			left: 0;
			width: 106.666rem;
		}

		&_right {
			right: 0;
			width: 106.666rem;
		}
	}

	.column-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		min-height: 120rem;
		justify-content: space-between;
		&.send-email {
			.message-wrap {
				flex: 1;
				justify-content: center;
				display: flex;
			}
		}
		.logo-link {
			margin-bottom: 1.5rem;
		}

		form {
			position: relative;
			width: 70rem;
			flex: 1;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;

			h3 {
				margin-top: 0;
				margin-bottom: 5.6rem;
				font-size: 3rem;
				text-align: center;
				font-weight: 100;
				color: $color20;
			}

			.authorisation-error {
				font-size: 2.3rem;
				margin-top: 0.5rem;
				color: $color11;
				text-transform: none;
			}
			.floatingLabelInput {
				display: flex;
				position: relative;
				flex-direction: column;

				label {
					text-transform: capitalize;
					position: absolute;
					top: 0.4rem;
					left: 0;
					transition: all 0.3s;
					color: $color65;
					font-size: 2.666rem;
					line-height: 1;
					font-weight: normal;

					&.on {
						top: -3.6rem;
						font-size: 2.333rem;
					}
				}

				input {
					font-size: 2.666rem;
					background-color: transparent;
					color: $color15;
					height: 3.5rem;
					padding-left: 0;
					outline: none;
					border: 0;
					margin-top: 1.3rem;
					border-bottom: 1px solid $color5;

					::-webkit-input-placeholder {
						/* Chrome/Opera/Safari */
						color: $color14;
					}
					::-moz-placeholder {
						/* Firefox 19+ */
						color: $color14;
					}
					:-moz-placeholder {
						/* Firefox 18- */
						color: $color14;
					}
					&:focus, &:hover {
						box-shadow: none !important;
					}
					&:focus {
						border-bottom-color: $color1;
						& +.on {
							color: $color1;
						}
					}
				}

				input,
				input:matches([type="password"], [type="search"]) {
					-webkit-appearance: textfield;
				}

				input:invalid {
					box-shadow: none;
				}

				input:-moz-submit-invalid {
					box-shadow: none;
				}

				input:-moz-ui-invalid {
					box-shadow: none;
				}

				input:-webkit-autofill {
					caret-color: white;
				}

				input:-webkit-autofill,
				textarea:-webkit-autofill,
				select:-webkit-autofill {
					caret-color: $color15;
					transition: background-color 5000s ease-in-out 0s;
				}
			}

			.text-indent-down {
				margin-bottom: 6rem !important;
			}

			.failed {
				display: inline-block;
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 4rem;

				div {
					display: flex;
					justify-content: flex-start;
				}

				&.error {
					span {
						color: $color11;
						font-size: 2.3rem;
					}

					input {
						border-bottom-color: $color11;
					}
				}
			}

			.text-indent-down {
				display: flex;
				position: relative;
				color: $color20;
				font-size: 2.333rem;
				margin: 0;
				justify-content: center;
				align-items: center;
				flex-direction: row;
				text-align: center;
				i {
					margin-right: 1rem;
				}
			}

			.btn-wrap {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 2.33333333333333333rem;

				.link-item {
					margin: 0 4rem 0 0;
					@extend .secondary-button;
				}

				button {
					@extend .main-button;
					margin: 0;
				}
			}
		}

		.message-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;

			.logo-link {
				position: relative;
				top: -22rem;

				img {
					width: 40rem;
				}
			}

			.recovery-message {
				text-align: center;
				color: $color14;
				font-size: 3rem;
				line-height: 1.55;
			}
		}
	}

	.recover-form {
		.rc-dropdown-menu-item {
			i {
				left: 3rem !important;
			}

			span {
				left: 9rem !important;
			}
		}
	}
}

.lng-recovery {
	position: fixed;
	top: 4rem;
	right: 4rem;
	z-index: 10000;
}

.technologies {
	position: absolute;
	bottom: 3.6rem;
	font-size: 2.3rem;
	color: $color14;
	text-align: center;
	padding: 0;
	margin: 0;
	max-width: 135.833rem;
}
